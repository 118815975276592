import { useState, useEffect, useRef } from "react";
import type { ColumnsType } from "antd/es/table";
import { DownOutlined } from "@ant-design/icons";
import { Skeleton, Table, Dropdown } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "../styles.scss";
import { getSingleCreator } from "../../_redux/Actions";
import { Field, Button } from "components/forms";
import { fetchAllData } from "../../_redux/Actions";
import * as product_actions from "../../../products/_redux/products/Actions";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import unescapeJs from 'unescape-js';


const filterer: any = {
  Brand: [
    { label: "JVN", title: "JVN", key: "brand0" },
    { label: "Bissonance", title: "Bissonance", key: "brand1" },
    { label: "Rose Inc", title: "Rose Inc", key: "brand2" },
  ],
  Country: [
    { label: "All", title: "All", key: "" },
    { label: "United Kingdom", title: "United Kingdom", key: "United Kingdom" },
    { label: "United States", title: "United States", key: "United States" },
  ],

  Socials: [
    { label: "Facebook", title: "Facebook", key: "socials0" },
    { label: "Instagram", title: "Instagram", key: "socials1" },
    { label: "TikTok", title: "TikTok", key: "socials2" },
  ],

  // "Social Platforms": [
  //   { label: "Facebook", title: "Facebook", key: "socials0" },
  //   { label: "Instagram", title: "Instagram", key: "socials1" },
  //   { label: "TikTok", title: "TikTok", key: "socials2" },
  // ],
};

export default function ContentTracking() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [selectedCategory] = useState("All");
  const [, setCrumbs] = useState([{ label: "", to: "/" }]);
  const [q, setQ] = useState("");
  const [date, set_date] = useState<any>(null);
  const [brand, set_brand] = useState<any>(null);
  const [country, set_country] = useState<any>(null);
  const [brands_filter, set_brand_filters] = useState<any>([]);
  const [sidebar, setSidebar] = useState(false);
  const [sidebarDetails, setSidebardetails] = useState({
    Id: "",
    SocialNetwork: "",
    SocialNetworkId: "",
    PublisherId:"",
    UserName: "",
    PostId:"",
    Score:"",
    EarnedMedia: "",
    EstimatedReach: "",
    Reach:"",
    Comments: "",
    Status:"",
    UpdatedBy: "",
    PostType: "",
    PublisherName: "",
    CampaignName: "",
    NetworkPublisherId: "",
    PartnerId: "",
    LogoURL: "",
    StartDate: ""

  });
  const filtererMenu: any = {
    Brand: { items: brands_filter, onClick: (el: any) => set_brand(el.key) },
    Country: {
      items: filterer["Country"],
      onClick: (el: any) => set_country(el.key),
    },
    "Socials": {
      items: filterer["Socials"],
      onClick: (el: any) => console.log("ele clcik", el),
    },
  };

  const { creatorDetails, brands, analytics,posts, creators } = useSelector(
    (state: any) => ({
      analytics: state.analytics.partners,
      creatorDetails: state.verifications.creatorDetails,
      brands: state.products.brands,
      posts: state.analytics.posts,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([
        {
          label:
            " / Profile: " +
            creatorDetails.first_name +
            " " +
            creatorDetails.last_name,
          to: `/creator/${creatorDetails._id}`,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorDetails]);

  useEffect(() => {
    if (creatorId) {
      dispatch(getSingleCreator(creatorId));
    } else {
      // setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId, creators]);

  useEffect(() => {
    if (brands && brands_filter.length === 0) {
      var brands_f = [...brands_filter];
      var bdd: any = {};
      bdd["label"] = "All";
      bdd["title"] = "All";
      bdd["key"] = "";
      brands_f.push(bdd);

      brands.forEach((ddd: any) => {
        var bdd: any = {};
        bdd["label"] = ddd.name;
        bdd["title"] = ddd.name;
        bdd["key"] = ddd._id;
        brands_f.push(bdd);
      });
      set_brand_filters(brands_f);
    }
    // eslint-disable-next-line
  }, [brands, brands_filter]);

  useEffect(() => {
    var q = "";
    if (date) {
      q += `start=${date.startDate}&end=${date.endDate}&`;
    }
    if (brand) {
      q += `brand_id=${brand}&`;
    }
    if (country) {
      if (country === "all") {
        q += ``;
      } else {
        q += `country=${country}&`;
      }
    }

    dispatch(fetchAllData("partners", q));
    dispatch(fetchAllData("posts", null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, brand, country]);

  function getByLabelText(filterer: any) {
    if (filterer === "brand") {
      if (!brand) {
        return "All";
      } else {
        var brrr = brands.filter((obj: any) => {
          return obj._id === brand;
        });
        return brrr[0].name;
      }
    } else if (filterer === "country") {
      return country || "All";
    }
    return selectedCategory;
  }

  const initSearch = () => {
    if (q !== "") {
      dispatch(fetchAllData("partners", `q=${q}`));
    } else {
      dispatch(fetchAllData("partners", ``));
    }
  };


  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('');
    }
    return newStr;
  }

  const toggleSidebar = (content: any) => {
    setSidebar(!sidebar)
    if (content){
      console.log({content})
      setSidebardetails(content)
      console.log({sidebarDetails})

    }
  }

  const renderSidebar = () => {
    return (
      <div className="sidebar-cont">
        <div className="sidebar-header">
          <p className="header-text">Content Details</p>
        <img onClick={toggleSidebar}src={require("assets/images/closeBlack.png")} alt=""  style={{width: '12px', cursor: "pointer"}}/>
        </div>
        <div className="sidebar-detz">
        <li className="sidebar-item"><span className="detz-key">ID:</span><span className="detz-value">{sidebarDetails.Id}</span></li>
        <li className="sidebar-item"><span className="detz-key">Social Network:</span><span className="detz-value">{sidebarDetails.SocialNetwork}</span></li>
        <li className="sidebar-item"><span className="detz-key">Social Network ID:</span><span className="detz-value">{sidebarDetails.SocialNetworkId}</span></li>
        <li className="sidebar-item"><span className="detz-key">Publisher ID:</span><span className="detz-value">{sidebarDetails.PublisherId}</span></li>
        <li className="sidebar-item"><span className="detz-key">User Name:</span><span className="detz-value">{sidebarDetails.UserName}</span></li>
        <li className="sidebar-item"><span className="detz-key">Post ID:</span><span className="detz-value">{sidebarDetails.PostId}</span></li>
        <li className="sidebar-item"><span className="detz-key">Score:</span><span className="detz-value">{sidebarDetails.Score}</span></li>
        <li className="sidebar-item"><span className="detz-key">Earned Media:</span><span className="detz-value">{sidebarDetails.EarnedMedia}</span></li>
        <li className="sidebar-item"><span className="detz-key">Estimated Reach:</span><span className="detz-value">{sidebarDetails.EstimatedReach}</span></li>
        <li className="sidebar-item"><span className="detz-key">Reach:</span><span className="detz-value">{sidebarDetails.Reach}</span></li>
        <li className="sidebar-item"><span className="detz-key">Comments:</span><span className="detz-value">{sidebarDetails.Comments}</span></li>
        <li className="sidebar-item"><span className="detz-key">Status:</span><span className="detz-value">{sidebarDetails.Status}</span></li>
        <li className="sidebar-item"><span className="detz-key">Updated By:</span><span className="detz-value">{sidebarDetails.UpdatedBy}</span></li>
        <li className="sidebar-item"><span className="detz-key">Post Type:</span><span className="detz-value">{sidebarDetails.PostType}</span></li>
        <li className="sidebar-item"><span className="detz-key">Publisher Name:</span><span className="detz-value">{sidebarDetails.PublisherName}</span></li>
        <li className="sidebar-item"><span className="detz-key">Campaign Name:</span><span className="detz-value">{sidebarDetails.CampaignName}</span></li>
        <li className="sidebar-item"><span className="detz-key">Network Publisher ID:</span><span className="detz-value">{sidebarDetails.NetworkPublisherId}</span></li>
        <li className="sidebar-item"><span className="detz-key">Partner ID:</span><span className="detz-value">{sidebarDetails.PartnerId}</span></li>
        <li className="sidebar-item"><span className="detz-key">Logo URL:</span><span className="detz-value">{sidebarDetails.LogoURL}</span></li>
        <li className="sidebar-item"><span className="detz-key">Start Date:</span><span className="detz-value">{sidebarDetails.StartDate}</span></li>
        </div>

      </div>
    )
  }
  return (
    <div className="analytics-container">
      {loading ? (
        <Skeleton paragraph={{ rows: 12 }} />
      ) : (
      <>
      {sidebar ? <div className="sidebar">{renderSidebar()}</div> : ""}
      <>
      <div className="search-picker">
            <div className="search" style={{display: 'flex'}}>
              <Field
                placeholder="Search "
                type="text"
                label="Search"
                onChange={(e) => setQ(e.target.value)}
                onPressEnter={initSearch}
              />
            </div>
            {/* <div className="picker">
              <RangePicker placement="bottomLeft" />
            </div> */}
            <div className="" style={{ width: 250 }}>
              <p className="hclabel">Date range</p>
              <div className="analyselection__cnt">
                <div className="analysection_cnt">
                  <DateRangePicker
                    onApply={(a, b) => {
                      set_date({
                        startDate: b.startDate.format("YYYY-MM-DD"),
                        endDate: b.endDate.format("YYYY-MM-DD"),
                      });
                      // handleSubmit();
                    }}
                    initialSettings={{
                      startDate: "1/1/2023",
                      endDate: new Date(),
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        backgroundColor: "transparent",
                        width: "100%",
                        borderWidth: 0,
                      }}
                    />
                  </DateRangePicker>
                  {/* <img
                      className="vblsc_right"
                      style={{ paddingLeft: 20 }}
                      src={dropdown}
                      alt=""
                    /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="filterer analytics">
            {Object.keys(filterer).map((el, index) => (
              <div className="dorpdown" key={index}>
                <span>{el}</span>
                <Dropdown menu={filtererMenu[el]} trigger={["click"]}>
                  <div className="user">
                    <span className="labele">
                      {getByLabelText(el.toLowerCase())}
                    </span>
                    <DownOutlined color="#370E78" />
                  </div>
                </Dropdown>
              </div>
            ))}
            <div className="btn"><button className="download-btn">Download</button> </div>
          </div>
          <div className="analytics-table">
            <div className="content-tracking">
                {
                  posts && posts.map((ele: any, i: number) => {
                    return (
                        <div key = {i} className="content-item" onClick={() => toggleSidebar(ele)} style={{cursor: 'pointer'}}>
                            <div className="content-image" >
                            <img src={ele.Thumbnail} alt="content" style = {{
                              width: '148.33px',
                              height: '103px',
                              borderRadius: '4px',
                              objectFit: 'cover', // Maintain aspect ratio and crop if necessary
                              objectPosition: 'center', 
                            }} />
                            </div>
                            <div className="content-details">
                                {/* <p className="content-desc">I live for a trend with yearning and running… wait for it… 🥰 #CCCPartner</p> */}
                                {/* <p className="content-desc">{limitStr(unescape(ele.Post), 70)}</p> */}
                                <p className="content-desc">{limitStr(ele.Post.replace(/&amp;/g, '&'), 70)}</p>
                                
                                <>
                                    <li className="detail-item"><span className="detail-key">Type</span><span className="detail-value">Post</span></li>
                                    <li className="detail-item"><span className="detail-key">Likes</span> <span className="detail-value">{ele.Likes}</span></li>
                                    <li className="detail-item"><span className="detail-key">Comments</span><span className="detail-value">{ele.Comments}</span></li>
                                    <li className="detail-item"><span className="detail-key">Views</span><span className="detail-value">{ele?.Views || 0}</span></li>
                                </>
                            </div>
                        </div>
                    )
                  })
                }
            
       
            </div>
          </div>
      </>        
        </> 
      )}
    </div>
  );
}
