import { useState, useEffect } from 'react'
import { Skeleton } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import '../styles.scss';
import { getSingleCreator } from "../../_redux/Actions";
import { TrendStat } from 'components/shared';
import { Chart } from "react-google-charts";

const discoverer = {
  first_name: 'SOPZN',
  last_name: 'COKER',
  location: 'New York City, USA',
  EngagementRate: 22.4,
  nofPosts: 140,
  avgViews: 234,
};

const earningSummary = [
  { label: 'Lifetime earnings', value: '$68,012' },
  { label: 'Cash out balance', value: '$620' }
]

const trends = [
  { label: 'Total # Orders', value: '5.839', rate: '1.2%', pve: true },
  { label: 'Total Revenue', value: '$4.060,893', rate: '2.3%', pve: true },
  { label: 'Total Commissions', value: '$789.093', rate: '4.1%', pve: true },
  { label: 'AOV', value: '$37.80', rate: '1.6%', pve: true },
  { label: 'Avg. return rate', value: '4.6%', rate: '5.3%', nve: true },
  { label: 'Avg. CVR', value: '5.6%', rate: '1.6%', pve: true },
  { label: 'Total Commissions', value: '$789.093', rate: '4.1%', pve: true },
  { label: 'Total Revenue', value: '$4.060,893', rate: '2.3%', pve: true },
]

export const audienceData = [
  ["Products", "Brand Sales", { role: 'style' }],
  ["Biossance", 9900, "color: #6863AB"],
  ["JVN", 6950, "color: #62A9E3"],
  ["Rose ", 5260, "color: #7D9DCA"],
];
export const audienceOptions = {
  title: "",
  chartArea: { width: "75%" },
  legend: "none",
  bar: { groupWidth: "70%" },
  hAxis: {
    title: "",
    minValue: 0,
    textPosition: 'none',
    gridlines: { color: 'transparent' },
  },
  vAxis: {
    title: "",
    textStyle: {
      fontSize: 48
    }
  },
};

export default function CreatorOverview() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const [, setCrumbs] = useState([{ label: '', to: '/' }])

  const { creatorDetails, creators } = useSelector(
    (state: any) => ({
      creatorDetails: state.verifications.creatorDetails,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators

    }),
    shallowEqual
  );


  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([{ label: ' / Profile: ' + creatorDetails.first_name + ' ' + creatorDetails.last_name, to: `/creator/${creatorDetails._id}` }])
    }
  }, [creatorDetails])

  useEffect(() => {
    if (creatorId) {
      dispatch(getSingleCreator(creatorId));
    } else {
      // setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId, creators]);

  useEffect(() => {
    if (creatorDetails && creatorDetails._id) {
      setLoading(false);
    }
  }, [creatorDetails])

  return (
    <div className="analytics-container">
      {loading || !discoverer
        ? <Skeleton />
        :
        <>
          <div className="creator-details">
            <div className="mask-data">
              <img src={require('assets/images/avatar-bg.png')} alt="*" className="avatar" />
              <span className="creator-name">{discoverer.first_name}{" "}{discoverer.last_name}</span>
              <div className="location">
                <span>{discoverer.location}</span>
              </div>
            </div>
          </div>
          <section className="metadata">
            <h2 className="section-title">Earning summary</h2>
            <div className="around">
              {earningSummary.map((suma, index) => (
                <TrendStat {...suma} key={index} />
              ))}
            </div>
          </section>
          <section className="metadata">
            <h2 className="section-title">Sales Funnel Performance</h2>
            <div className="lg-four">
              {trends.map((suma, index) => (
                <TrendStat {...suma} key={index} />
              ))}
            </div>
          </section>

          <section className="metadata">
            <h2 className="section-title">Sales by Brand</h2>
            <div className="charts-grid">
              <Chart
                chartType="BarChart"
                width="100%"
                height={480}
                data={audienceData}
                options={audienceOptions}
              />
            </div>
          </section>
        </>
      }
    </div>
  )
}
