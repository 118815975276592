import { useState, useEffect } from 'react'
import { Radio, } from 'antd';
import { PartnerAnalytics as Pnp } from 'components/Icons';
import { SidebarLayout } from 'components/layout'
import { ProgrammePerformance, PartnersRanking, CreatorOverview, ContentPerformance, ContentTracking } from './meta';
import './styles.css'
import './styles.scss';

export default function PartnerAnalyticsOverview() {
  const [tab, setTab] = useState('programmePerformance');
  const [crumbs, setCrumbs] = useState<any>([{ label: '/ Partner\'s Ranking', to: '/partner-analytics' }]);

  useEffect(() => {
    if (tab === 'programmePerformance') setCrumbs([{ label: '/ Programme Performance', to: '/partner-analytics' }])
    if (tab === 'partnersRanking') setCrumbs([{ label: '/ Partner\'s Ranking', to: '/partner-analytics' }])
    if (tab === 'contentTracking') setCrumbs([{ label: '/ Content Tracking', to: '/content-tracking' }])
  }, [tab]);

  return (
    <SidebarLayout
      headerTitle="Partner Analytics"
      headerIcon={Pnp}
      crumbs={crumbs}
    >
      <div className="analytics-menu">
        <div className="menu-group lg-3">
          <Radio.Group onChange={(e: any) => setTab(e.target.value)} defaultValue={tab}>
            <Radio.Button value="programmePerformance">PROGRAMME PERFORMANCE</Radio.Button>
            <Radio.Button value="partnersRanking">PARTNER'S Ranking</Radio.Button>
            <Radio.Button value="contentTracking">CONTENT TRACKING</Radio.Button>
            {/* <Radio.Button value="creatorOverview">CREATOR OVERVIEW</Radio.Button> */}
            {/* <Radio.Button value="contentPerformance">CONTENT PERFORMANCE</Radio.Button> */}
          </Radio.Group>
        </div>
      </div>
      <section>
        {tab === 'programmePerformance' && <ProgrammePerformance />}
        {tab === 'partnersRanking' && <PartnersRanking />}
        {tab === 'contentTracking' && <ContentTracking />}
        {tab === 'creatorOverview' && <CreatorOverview />}
        {tab === 'contentPerformance' && <ContentPerformance />}
        {/* {tab === 'help' && <Disputes menuItems={menuItems} disputes={disputes} />} */}
      </section>

    </SidebarLayout>
  )
}
