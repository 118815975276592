import { useState, useEffect, useRef } from 'react'
import { Skeleton, Table, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { Check, Cross } from 'components/Icons';
import { SidebarLayout } from 'components/layout'
import { CreatorVerification } from 'components/Icons'
import './styles.scss';
import * as actions from "../_redux/Actions";
import { Field } from 'components/forms';


interface DataType {
  key: React.Key;
  name: string;
  topics: string[];
  stats: string[];
}

const crumbs = [
  { label: '', to: '/' },
]

const creators = [
  {
    key: '1', first_name: 'S()PZN', last_name: 'COKER',
    topics: ['#Beauty', '#VeganProducts', '#Clean', '#Authenticity'],
    stats: { 'Engagement Rate: ': 5.2, 'YT CTR': 3.5, 'Number of Posts': 1.9, 'Average Views': 2.2 }
  },
  {
    key: '2', first_name: 'S()PZN', last_name: 'COKER',
    topics: ['#Beauty', '#VeganProducts', '#Clean', '#Authenticity'],
    stats: { 'Engagement Rate: ': 5.2, 'YT CTR': 3.5, 'Number of Posts': 1.9, 'Average Views': 2.2 }
  },
  {
    key: '3', first_name: 'S()PZN', last_name: 'COKER',
    topics: ['#Beauty', '#VeganProducts', '#Clean', '#Authenticity'],
    stats: { 'Engagement Rate: ': 5.2, 'YT CTR': 3.5, 'Number of Posts': 1.9, 'Average Views': 2.2 }
  },
  {
    key: '4', first_name: 'S()PZN', last_name: 'COKER',
    topics: ['#Beauty', '#VeganProducts', '#Clean', '#Authenticity'],
    stats: { 'Engagement Rate: ': 5.2, 'YT CTR': 3.5, 'Number of Posts': 1.9, 'Average Views': 2.2 }
  },
  {
    key: '5', first_name: 'S()PZN', last_name: 'COKER',
    topics: ['#Beauty', '#VeganProducts', '#Clean', '#Authenticity'],
    stats: { 'Engagement Rate: ': 5.2, 'YT CTR': 3.5, 'Number of Posts': 1.9, 'Average Views': 2.2 }
  },
]

const filterer: any = {
  'Socials': [
    { label: 'Facebook', title: 'Facebook', key: "socials0" },
    { label: 'Instagram', title: 'Instagram', key: "socials1" },
    { label: 'TikTok', title: 'TikTok', key: "socials2" },
  ],
  "Audience Locations": [
    { label: 'Nigeria', title: 'Nigeria', key: "aLocations1" },
    { label: 'United Kingdon', title: 'United Kingdon', key: "aLocations0" },
    { label: 'United States', title: 'United States', key: "aLocations2" },
  ],
  "Followers": [
    { label: '100k-500k', title: '100k-500k', key: "followers0" },
    { label: '500k-1m', title: '500k-1m', key: "followers1" },
    { label: '1m and Above', title: '1m and Above', key: "followers2" },
  ],
  "Hashtag": [
    { label: '#beauty', title: '#beauty', key: "hashtag0" },
    { label: '#Lifestyle', title: '#Lifestyle', key: "hashtag1" },
    { label: '#Dancing', title: '#Dancing', key: "hashtag2" },
  ],
  "Location": [
    { label: 'Nigeria', title: 'Nigeria', key: "loction1" },
    { label: 'United Kingdon', title: 'United Kingdon', key: "loction0" },
    { label: 'United States', title: 'United States', key: "loction2" },
  ],
}

const filtererMenu: any = {
  'Socials': { items: filterer['Socials'], onClick: (el: any) => console.log("ele clcik", el) },
  "Audience Locations": { items: filterer['Audience Locations'], onClick: (el: any) => console.log("ele clcik", el) },
  "Followers": { items: filterer['Followers'], onClick: (el: any) => console.log("ele clcik", el) },
  "Hashtag": { items: filterer['Hashtag'], onClick: (el: any) => console.log("ele clcik", el) },
  "Location": { items: filterer['Location'], onClick: (el: any) => console.log("ele clcik", el) },
}

export default function DiscoveryList() {
  const dispatch = useDispatch<any>();
  const [kCreator, setKCreator] = useState([]);
  const [selectedCategory] = useState('All')
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const keyedCreators: any = [];

  const { listLoading } = useSelector(
    (state: any) => ({
      // creators: state.verifications.creators,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading

    }),
    shallowEqual
  );

  useEffect(() => {
    if (creators && creators.length > 0) {
      creators.map((creator: any) => {
        keyedCreators.push({ ...creator, key: creator._id })
        return true
      });
      setKCreator(keyedCreators);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creators])



  function send_status(st: any, act: string) {
    var stats: any = {};
    stats['user_id'] = st;
    stats['status'] = act;
    dispatch(actions.editDetails(stats, '', 'toggle_activation'))
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Full name',
      dataIndex: 'name',
      width: '32%',
      sorter: true,
      render: (text: string, full: any) => {
        return (
          <Link to="/discovery/12" className="flex items-center">
            <img src={require('assets/images/user.png')} width={24} height={24} alt="#" className="mr-2" />
            <span className="table-namey"> {full.first_name} {full.last_name} </span>
          </Link>
        )
      },
    },
    {
      title: 'Topics(#)',
      dataIndex: 'topics',
      width: '33%',
      sorter: true,
      render: (topics: string[], full: any) => {
        return (
          <div className="topics">
            {topics.map((topic: string, index: number) => (
              <span key={index} className="topic">{topic.toUpperCase()}</span>
            ))}
          </div>
        )
      },
    },
    {
      title: 'Stats',
      dataIndex: 'stats',
      width: '35%',
      sorter: true,
      render: (stats: any, full: any) => {
        console.log(Object.keys(stats).map((el) => console.log(stats[el])));
        return (
          <div className="stat-val">
            {Object.keys(stats).map((ele: string, index: number) => (
              <div key={index} className="stat-flex">
                <span key={index} className="label">{ele}</span>
                <span key={index} className="value">{stats[ele]}</span>
              </div>
            ))}
          </div>
        )
      },
    },
  ];

  useEffect(() => {
    dispatch(actions.fetchAllData('creators', `?type=creator`));
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <SidebarLayout
      headerTitle="creator verification"
      headerIcon={CreatorVerification}
      crumbs={crumbs}
    >
      <div className="discovery-container">
        <div className="search-content">
          <Field placeholder="e.g. John" type="text" label="Search" onChange={() => console.log('change')} />
          <button className="btn btn-img">
            <img src={require('assets/images/close.png')} alt="" width={10} height={10} />
            <span>Clear Filter</span>
          </button>
        </div>
        <div className="filterer">
          {Object.keys(filterer).map((el, index) => (
            <div className="dorpdown" key={index}>
              <span>{el}</span>
              <Dropdown menu={filtererMenu[el]} trigger={['click']}>
                <div className="user">
                  <span className="labele">{selectedCategory}</span>
                  <DownOutlined color="#370E78" />
                </div>
              </Dropdown>
            </div>
          ))}
        </div>
        {kCreator.length === 0 && listLoading &&
          <Skeleton loading={listLoading} active paragraph={{ rows: 10 }} />
        }
        {windowSize?.current[0] >= 768 &&
          <>
            {kCreator && kCreator.length > 0 &&
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={kCreator}
              />
            }
          </>
        }
        {windowSize?.current[0] < 768 &&
          <div className="mobile-list-view">
            {kCreator && kCreator.length > 0 &&
              kCreator.map((creator: any, index) => (
                <Link to={`/creator/${creator._id}`} className="mobile-l-item" key={index}>
                  <div className="label-value">
                    <span className="label">FULL name</span>
                    <span className="value name">{`${creator.first_name} ${creator.last_name}`}</span>
                  </div>
                  <div className="label-value">
                    <span className="label">email address</span>
                    <span className="value">{creator.email}</span>
                  </div>
                  <div className="label-value">
                    <span className="label">status</span>
                    <span className="value">
                      <div className={`vrf-status ${creator.verification_status}`}>
                        <span>&#8226;{creator.verification_status}</span>
                      </div>
                    </span>
                  </div>
                  <div className="actione">
                    {creator.verification_status === 'pending'
                      ?
                      <div className="flex flex-end">
                        <button onClick={() => send_status(creator._id, 'approve')} className="action-btn check">
                          <Check />
                        </button>
                        <button onClick={() => send_status(creator._id, 'reject')} className="action-btn cross">
                          <Cross />
                        </button>
                      </div>
                      : ''}
                  </div>
                </Link>
              ))
            }
          </div>
        }
      </div>
    </SidebarLayout>
  )
}
