import { useState, useEffect, useRef } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Table, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Field } from 'components/forms';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from '../../_redux/Actions';
import moment from 'moment'
import { Empty } from 'components/shared';

export default function Bookings(props: any) {
  const dispatch = useDispatch<any>();
  const { menuItems } = props;
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [selectedCategory, setCategory] = useState('All')
  const [filteredContent, setFilteredContent] = useState<any>([]);
  const [q, setQ] = useState('');

  const { bookings } = useSelector(
    (state: any) => ({
      bookings: state.community.bookings,
    }),
    shallowEqual
  );
  const handleMenuClick = (el: any) => {
    setCategory(el?.item?.props?.title)
    filterDataByWeek(bookings, el?.key);
  }


  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };
  // useEffect(() => {
  // }, [])

  useEffect(() => {
    setFilteredContent(bookings);
    if (bookings && bookings.length > 0) {
      const contList = [...bookings];
      setFilteredContent(contList);
    }
  }, [bookings])

  const columns: ColumnsType<any> = [
    {
      title: 'Topic',
      dataIndex: 'topic',
      render: (text: string, full: any) => {
        return (<span className="table-namey">
          {full?.data?.service_name}
        </span>
        )
      },
    },
    {
      title: 'topic & advisor',
      dataIndex: 'topic' || 'advisor',
      render: (text: string, full: any) => {
        return <div className="tr-main-sub">
          <span className="main">{full?.data?.customer_name}</span>
          <span className='sub'>{full?.data?.staff_name}</span>
        </div>
      },
    },
    {
      title: 'date & time',
      dataIndex: 'datetime',
      render: (text: string, full: any) => {
        return `${moment(full?.data?.start_time).format('YYYY. MM. DD HH:mm A')} (${full?.data?.time_zone})`
      },
    },
    {
      title: 'link',
      dataIndex: 'link',
      render: (text: string, full: any) => {
        return <a href={full?.data?.summary_url} rel="noreferrer" target="_blank" className="joinlink">Join</a>
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };


  useEffect(() => {
    dispatch(actions.fetchAllData('bookings'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function filterDataByWeek(data: any, key = '2') {
    if (key === '1') {
      dispatch(actions.fetchAllData('bookings'));
      return;
    }
    const today = new Date();

    const thisWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const nextWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    var date_to_measure = new Date();
    if (key === '2') {
      date_to_measure = thisWeekStartDate;
    } else {
      date_to_measure = nextWeekStartDate;
    }

    // Filter out data that is not in next week
    const newData = data.filter((item: any) => {
      const itemDate = new Date(item.data.start_time);
      return itemDate >= date_to_measure && itemDate < new Date(date_to_measure.getFullYear(), date_to_measure.getMonth(), date_to_measure.getDate() + 7);
    });


    // console.log(newData);
    // return newData;
    // dispatch(setEvent(newData));
    setFilteredContent(newData);
    // dispatch(actions.setBook(newData));
  }

  const filterList = (val: string) => {
    const contList = [...bookings];
    const newList: any = [];
    if (bookings && bookings.length > 0) {
      contList.map((ele: any) => {
        if (ele?.data?.customer_name) {
          if (ele?.data?.customer_name.toLowerCase()?.includes(val.toLowerCase())
            || ele?.data?.workspace_name.toLowerCase()?.includes(val.toLowerCase())
            || ele?.data?.service_name.toLowerCase()?.includes(val.toLowerCase())) {
            newList.push(ele);
          }
        }
        return true;
      })
    }
    // console.log(newList)
    setFilteredContent(newList);
    // dispatch(actions.setBook(newList));
  }
  const initSearch = () => {
    if (q !== '') {
      filterList(q);
    } else {
      dispatch(actions.fetchAllData('bookings'));
    }
  }

  return (
    <>
      <div className="header-section">
        <h2 className="section-title">Bookings</h2>
        {/* <button className="btn btn-img" onClick={() => navigate('/community/booking/new')}>
          <img src={require('assets/images/upload-plus.png')} alt="" />
          <span>Add bookings</span>
        </button> */}
      </div>
      <div className="search-content">
        <Field placeholder="e.g. First Sessions " type="text" label="Search" onChange={(e) => setQ(e.target.value)} onPressEnter={initSearch} showCount />

        <div className="dorpdown">
          <span>Filter: Date</span>
          <Dropdown menu={menuProps} trigger={['click']}>
            <div className="user">
              <span className="labele">{selectedCategory}</span>
              <DownOutlined color="#370E78" />
            </div>
          </Dropdown>
        </div>
      </div>
      {bookings && bookings.length > 0
        ? <>
          {windowSize?.current[0] >= 768 &&
            <>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={filteredContent}
                rowKey="_id"
              />
            </>
          }
          {windowSize?.current[0] < 768 &&
            <div className="mobile-list-view">
              {bookings && bookings.length > 0 &&
                bookings.map((booking: any, index: number) => (
                  <Link to={`/community/booking/${booking._id}`} className="mobile-l-item" key={index}>
                    <div className="label-value">
                      <span className="label">FULL name</span>
                      <span className="value name">{`${booking?.data?.customer_name}`}</span>
                    </div>
                    <div className="label-value">
                      <span className="label">Topic</span>
                      <span className="value">{booking?.data?.service_name}</span>
                    </div>
                    <div className="label-value">
                      <span className="label">Advisor</span>
                      <span className="value">{booking?.data?.staff_name}</span>
                    </div>
                    <div className="label-value">
                      <span className="label">Date & time</span>
                      <span className="value">{`${moment(booking?.data?.start_time).format('YYYY. MM. DD HH:mm A')} (${booking?.data?.time_zone})`}</span>
                    </div>
                    <div className="label-value">
                      <span className="label">Link</span>
                      <span className="value">{booking?.data?.link}</span>
                    </div>
                  </Link>
                ))
              }
            </div>
          }
        </>
        : <Empty />
      }
    </>
  )
}
