import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import SidebarLayout from "components/layout/SidebarLayout";
import { ContentHub as CHub } from "components/Icons";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import { getSingleContent } from "../_redux/Actions";
import { Skeleton } from "antd";
import "./styles.scss";
import { Modal } from 'antd';
import Vimeo from '@u-wave/react-vimeo';




export default function ContentDetails() {
  const dispatch: any = useDispatch();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  let stateData = location.state;
  const [play, set_play] = useState(false);


  const [crumbs, setCrumbs] = useState([
    { label: 'getting you started', to: '/content' },
    { label: '', to: `/content/post/12` },
  ])

  const [modalIsOpen, setIsOpen] = useState(false);
  const [video] = useState(null);

  const { contentDetails } = useSelector(
    (state: any) => ({
      contentDetails: state.contenthub.contentDetails,
      contentList: state.contenthub.contentList,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSingleContent(id));
  }, [])

  useEffect(() => {
    setCrumbs([
      { label: 'getting you started', to: '/content' },
      { label: contentDetails?.title || 'N/A', to: `/content/${contentDetails?._id}` },
    ])
  }, [contentDetails]);

  useEffect(() => {
    if (contentDetails && contentDetails._id) {
      setCrumbs([
        { label: 'getting you started', to: '/content' },
        { label: contentDetails.title || 'N/A', to: `/content/post/${contentDetails._id}` },
      ])

    }
  }, [contentDetails])

  return (
    <SidebarLayout
      headerTitle="Content hub"
      headerIcon={CHub}
      crumbs={crumbs}
    >
      <div className="content-hub-container">
        {stateData && stateData.title ? <>
          <div className="header-section">
            <h2 className="section-title">{stateData.title}</h2>
          </div>
          <div className="banner">
            {
              !play ?
                <img src={stateData.thumbnail} alt="" className="large" />
                :
                <div className="large"
                  style={{}}>
                  <Vimeo
                    video={stateData.video_url}
                    width={940}
                    height={580}
                    autoplay={true}
                  />
                </div>
            }

            {
              stateData.video_url && !play &&
              <img src={require('assets/images/play-btn.png')} onClick={() => set_play(true)} alt="" className="play" />
            }
          </div>
          <div className="description" dangerouslySetInnerHTML={{ __html: stateData.body }} />

        </>
          : <Skeleton paragraph={{ rows: 10 }} />
        }
      </div>




      <Modal
        centered
        open={modalIsOpen}
        onOk={() => {
          setIsOpen(false);
        }}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={null}
        title={null}
      >
        {video &&
          <div className="video"
            style={{
              height: 400,
              width: 400,
              // marginRight: 20
            }}>
            <Vimeo
              video={video}
              width={520}
              height={400}
              autoplay={true}
            />
          </div>}
      </Modal>
    </SidebarLayout>
  );
}
