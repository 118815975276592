
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import SidebarLayout from "components/layout/SidebarLayout";
import { ContentHub as CHub } from "components/Icons";
import { Button, Field } from "components/forms";
import "./styles.scss";
import { Empty } from "components/shared";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getContentsAll, deleteContent } from '../_redux/Actions';
import { Popconfirm, Skeleton } from 'antd';
import { toast } from 'react-toastify';
import { Modal } from 'antd';
// import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player/vimeo'
import ReactMarkdown from 'react-markdown';


const limitStr = (str: string, length: number) => {
  if (str) {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('') + '...'
    }
    return newStr;
  } else {
    return str;
  }
}

const crumbs = [
  { label: ' / getting you started', to: '/content' },
];

const ContentHub = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [, setLoading] = useState<boolean>(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [video, set_video_url] = useState(null);
  const [filteredContent, setFilteredContent] = useState<any>([]);
  const [q, setQ] = useState('');
  const [isVideo, set_Video] = useState(true);

  const { contentList } = useSelector(
    (state: any) => ({
      contentList: state.contenthub.contentList,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getContentsAll());
  }, []);

  useEffect(() => {
    if (contentList && contentList.length > 0) {
      const contList = [...contentList];
      if (contentList && contentList.length > 0) {
        setFilteredContent(contList);
      }
    }
  }, [contentList])

  const confirm = (id: React.MouseEvent<HTMLElement> | any) => {
    setLoading(true);
    dispatch(deleteContent(id)).then((data: any) => {
      if (data && data.success) {
        toast.success('Deleted successfully');
      }
      dispatch(getContentsAll());
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('An error occured. Please retry later.');
    })
  };

  const cancel = (e: React.MouseEvent<HTMLElement> | any) => {
    console.log(e);
  };

  //  const filterList = (val: string) => {
  // const contList = [...contentList];
  // const newList: any = [];
  // if (contentList && contentList.length > 0) {
  //   contList.map((ele: any) => {
  //     if (ele.title.includes(val)) newList.push(ele);
  //   })
  // }
  // setFilteredContent(newList)
  // dispatch(getContentsAll('search', `?q=${val}`));
  //}

  const initSearch = () => {
    if (q !== '') {
      dispatch(getContentsAll('search', `?q=${q}`));
    } else {
      dispatch(getContentsAll());
    }
  }


  function removeMarkdownAndHTML(text: string) {
    // Remove HTML tags
    const withoutHTML = text.replace(/<[^>]+>/g, '');

    // Remove Markdown headings
    const withoutHeadings = withoutHTML.replace(/^#+\s+(.*)$/gm, '$1');

    // Remove Markdown formatting
    const withoutMarkdown = withoutHeadings.replace(/([*_~`]|~~)(.*?)\1/g, '$2');

    return withoutMarkdown;
  }
  

  return (
    <SidebarLayout
      headerTitle="Content hub"
      headerIcon={CHub}
      crumbs={crumbs}
    >
      <div className="content-hub-container">
        <div className="header-section">
          <h2 className="section-title">Getting you started</h2>
          <button className="btn btn-img" onClick={() => navigate('/content/new')}>
            <img src={require('assets/images/upload-plus.png')} alt="" />
            <span>ADD Content</span>
          </button>
        </div>
        <div className="search-content">
          <Field placeholder="How to discover products " type="text" label="Search" onChange={(e) => setQ(e.target.value)} showCount onPressEnter={initSearch} />
        </div>
        {!filteredContent && <Skeleton paragraph={{ rows: 10 }} />}
        {filteredContent && filteredContent.length > 0
          ? <div className="content-list">
            {filteredContent.map((item: any, index: number) => (
              <div className="content-list-item" key={index}>
                <div className="actions">
                  <button onClick={() => navigate(`/content/edit/${item._id}`)}>
                    <img src={require('assets/images/edit.png')} alt="" />
                  </button>
                  <Popconfirm
                    title="Delete this post"
                    description={`Are you sure to delete "${item.title}"?`}
                    onConfirm={(b: any) => confirm(item._id)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button>
                      <img src={require('assets/images/trash.png')} alt="" />
                    </button>
                  </Popconfirm>
                </div>
                <div className="feature-image">
                  {/* <ProgressiveImage
                    imgSrc={item.thumbnail}
                    previewSrc={require('assets/images/holder.png')}
                    width="100%"
                    height="75%"
                    style={{ objectFit: 'cover', objectPosition: 'center', height: '230px' }}
                  /> */}
                  <img src={item.thumbnail} style={{ objectFit: 'cover', objectPosition: 'center', height: '230px', width: '100%', }} alt="" />
                  <div className="overlay-feature"></div>
                  {item.video_url &&
                    <img src={require('assets/images/play-btn.png')} onClick={() => {
                      set_Video(true)
                      set_video_url(item.video_url)
                      setIsOpen(true)
                    }} alt="" className="play" />}
                </div>
                <h3 className="title">{item.title}</h3>
                <div className="sub-title" dangerouslySetInnerHTML={{ __html: removeMarkdownAndHTML(limitStr(item.body, 80)) }} />
                {/* <ReactMarkdown className="sub-title">{limitStr(item.body, 80)}</ReactMarkdown> */}
          
                <Button label="Watch or read" variant="primary"
                  onClick={() => navigate(`/content/${item._id}`)}
                />
              </div>
            ))}
          </div>
          : <Empty />
        }
      </div>


      <Modal
        centered
        open={modalIsOpen}
        onOk={() => {
          set_Video(false);
          setTimeout(() => {
            setIsOpen(false)
          }, 100);
        }}
        onCancel={() => {
          set_Video(false);
          setTimeout(() => {
            setIsOpen(false)
          }, 100);
        }}
        footer={null}
        title={null}
      >
        {video &&
          <div className="video"
            style={{
              height: 400,
              width: 400,
            }}>
            <ReactPlayer
              url={video}
              playing={isVideo}
              width={520}
              height={400}
              controls={true}
              autoplay={true}
            />
          </div>}
      </Modal>
    </SidebarLayout>
  );
}

export default ContentHub;
