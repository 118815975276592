/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { RootState } from '../../setup'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CreatorDetails, VerificationList } from 'app/modules/verifications/pages';
import { BrandEdit, ProductEdit, ProductsCatalogue, BrandPreview, ProductPreview, ProductDetails, BrandDetails, ProductReviews, CreatorPack } from 'app/modules/products/pages'
import { ContentHub, ContentEdit, ContentDetails, ContentPreview } from 'app/modules/contenthub'
import { CommunityHub, DisputeDetails, EventEdit, AttendeesList } from 'app/modules/communityhub'
import { DiscoveryList, DicoverCreator } from 'app/modules/discovery/pages'
import { OnboardProducts } from 'app/modules/onboard-products/pages/products'
import { PartnerAnalyticsOverview } from 'app/modules/partneranalytics/pages'
import { CreatorAnalyticsDashboard, CreatorManagementOverview } from 'app/modules/creatormanagement/pages'
import { Payouts } from 'app/modules/payouts/pages'
import Profile from 'app/modules/profile/pages/Profile'

const App_Routes: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  var isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual);
  // console.log(isAuthorized)


  return (
    <Routes>


      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        // <Route>
        //   <AuthPage />
        // </Route>
        // <>
        // <Route path="/" element={<Navigate to="/auth/login" />}/>

        <Route path='/login' element={<AuthPage />} />

      ) : (
        /*Otherwise redirect to root page (`/`)*/

        // <Navigate 
        // // from='/auth'
        // to='/' />
        <Route path="/login" element={<Navigate to="/" />} />

      )}

      {/* <Route path='/error' element={<>} /> */}
      <Route path='/logout' element={<Logout />} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        // <Navigate to='/auth/login' />
        <>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="*"
            element={<Navigate to="/login" />}
          />
        </>
        // <Route path='/auth' element={<AuthPage/>} />

      ) : (
        <>
          <Route path='/' element={<PrivateRoutes />} />
          <Route path='/creator' element={<VerificationList />} />
          <Route path='/creator/:id' element={<CreatorDetails />} />
          <Route path='/products' element={<ProductsCatalogue />} />
          <Route path='/product/:id' element={<ProductDetails />} />
          <Route path='product/preview' element={<ProductPreview />} />
          <Route path='/product/:id/reviews' element={<ProductReviews />} />
          <Route path='/product/:id/creator-pack' element={<CreatorPack />} />
          <Route path='/product/edit/:brandId/:productId' element={<ProductEdit />} />
          <Route path='/product/:brandId/add' element={<ProductEdit />} />
          <Route path='/brand/:id' element={<BrandDetails />} />
          <Route path='/brand/:id/edit' element={<BrandDetails />} />
          <Route path='/brand/edit' element={<BrandEdit />} />
          <Route path='/brand/new' element={<BrandEdit />} />
          <Route path='/brand/preview' element={<BrandPreview />} />
          <Route path='/brand/edit/:id' element={<BrandEdit />} />
          <Route path='/content/:id' element={<ContentDetails />} />
          <Route path='/content' element={<ContentHub />} />
          <Route path='/content/preview' element={<ContentPreview />} />
          <Route path='/content/edit' element={<ContentEdit />} />
          <Route path='/content/edit/:id' element={<ContentEdit />} />
          <Route path='/content/new' element={<ContentEdit />} />
          <Route path='/content/post/:id' element={<ContentDetails />} />
          <Route path='/community/' element={<CommunityHub />} />
          <Route path="/community/sessions" element={<CommunityHub />} />
          <Route path="/community/events" element={<CommunityHub />} />
          <Route path="/community/help" element={<CommunityHub />} />
          <Route path='/community/event/edit' element={<EventEdit />} />
          <Route path='/community/event/edit/:id' element={<EventEdit />} />
          <Route path='/community/dispute/:id' element={<DisputeDetails />} />
          <Route path='/community/event/:id/attendees' element={<AttendeesList />} />
          <Route path='/discovery' element={<DiscoveryList />} />
          <Route path='/discovery/:id' element={<DicoverCreator />} />
          <Route path='/onboard-products' element={<OnboardProducts />} />
          <Route path='/partner-analytics' element={<PartnerAnalyticsOverview />} />
          <Route path='/payouts' element={<Payouts />} />
          <Route path='/profile' element={<Profile />} />

          {/* NOTE: these routes are for user management tab, look at the implementation before editing  */}
          <Route path='/:base/creator/:id' element={<CreatorManagementOverview />} />
          <Route path='/:base/creator/:id/dashboard' element={<CreatorAnalyticsDashboard />} />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </>
      )}
    </Routes>
  )
}

export { App_Routes }
