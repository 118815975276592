import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  actionsLoading: false,
  listLoading: false,
  error: false,
  creators: null,
  creatorDetails: null,
  summary: null,
  partners: null,
  sum_analytics: null,
  channel_performance: null,
  content_performance: null,
  transactions: null,
  posts: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const AnalyticsSlice = createSlice({
  name: "analytics",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reset: (state, action) => {
      state = initialdatasState;
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    sumAnalytics: (state, action) => {
      state.listLoading = false;
      state.sum_analytics = action.payload;
      state.error = null;
    },
    contentPerformance: (state, action) => {
      state.listLoading = false;
      state.content_performance = action.payload;
      state.error = null;
    },
    channelPerformance: (state, action) => {
      state.listLoading = false;
      state.channel_performance = action.payload;
      state.error = null;
    },
    summaryList: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.summary = action.payload;
    },
    transactionsList: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.transactions = action.payload;
    },
    partnerList: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.partners = action.payload;
    },
    DatasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { id, verification_status } = action.payload;
      state.creators = state.creators.map(entity => {
        if (id === entity._id) {
          entity.verification_status = verification_status;
        }
        return entity;
      });
    },
    StatusDatasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { id, status } = action.payload;
      state.transactions = state.transactions.map(entity => {
        if (id === entity?.transaction_id) {
          entity.status = status;
        }
        return entity;
      });
    },
    creatorDetails: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.creatorDetails = action.payload;
    },
    contentPosts: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.posts = action.payload;
    },
  }
}); 
