import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../../app/modules/auth";
import { VerificationSlice } from "app/modules/verifications/_redux/verifications/Slice";
import { ProductsSlice } from "app/modules/products/_redux/products/Slice";
import { ContentHubSlice } from "app/modules/contenthub/_redux/Slice";
import { CommunitytHubSlice } from "app/modules/communityhub/_redux/Slice";
import { DiscoverySlice } from "app/modules/discovery/_redux/Slice";
import { CreatorMgtSlice } from "app/modules/creatormanagement/_redux/Slice";
import { AnalyticsSlice } from "app/modules/partneranalytics/_redux/Slice"

export const rootReducer = combineReducers({
  auth: auth.reducer,
  verifications: VerificationSlice.reducer,
  products: ProductsSlice.reducer,
  contenthub: ContentHubSlice.reducer,
  community: CommunitytHubSlice.reducer,
  discovery: DiscoverySlice.reducer,
  creatormgt: CreatorMgtSlice.reducer,
  analytics: AnalyticsSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([auth.saga()]);
}
