import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'


export function findDatas(type, queryParams) {
  if (type === 'brands') {
    return axios.get(`${URL}api/brand/all`);
  } else if (type === 'categories') {
    return axios.get(`${URL}api/category/all${queryParams || '?'}`);
  } else if (type === 'products') {
    return axios.get(`${URL}api/product/all${queryParams}`);
  } else if (type === 'tracking') {
    return axios.get(`${URL}api/product/tracking_code${queryParams}`);
  } else if (type === 'product') {
    return axios.get(`${URL}api/product${queryParams}`);
  } else if (type === 'commission') {
    return axios.get(`${URL}api/commission_scheme/all${queryParams}`);
  } else if (type === 'starter') {
    return axios.get(`${URL}api/product/selected_products`);
  }
}

export function fetchBrandDetails(id) {
  return axios.get(`${URL}api/brand?brand_id=${id}`);
}

export function fetchProductDetails(id) {
  return axios.get(`${URL}api/product?product_id=${id}`);
}

export function fetctProductExt(q) {
  return axios.get(`${URL}api/product/details${q}`);
}

export function addBrand(payload) {
  return axios.post(`${URL}api/brand`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function addFile(payload) {
  return axios.post(`${URL}api/file`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function addProduct(payload) {
  return axios.post(`${URL}api/product`, payload);
}

export function addData(payload, type) {
  if (type === 'commission') {
    return axios.post(`${URL}api/commission_scheme`, payload);
  }
  return axios.put(`${URL}api/product/selected_products`, payload);
}

export function editData(payload, query, type) {
  return axios.put(`${URL}api/commission_scheme${query}`, payload);
}


export function editDetails(payload, query) {
  return axios.put(`${URL}api/product${query}`, payload);
}

export function editProduct(payload, productId) {
  return axios.put(`${URL}api/product?product_id=${productId}`, payload);
}

export function editBrand(payload, brandId) {
  return axios.put(`${URL}api/brand?brand_id=${brandId}`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getProductFilesAll(queries) {
  return axios.get(`${URL}api/file/all${queries}`);
}

export function deleteAllMedia(queries) {
  return axios.delete(`${URL}api/file${queries}`);
}

export function deleteSingleBrand(id) {
  return axios.delete(`${URL}api/brand?brand_id=${id}`);
}

export function deleteSingleP(id) {
  return axios.delete(`${URL}api/product?product_id=${id}`);
}
