import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'


export function findDatas(type, queryParams) {
  if (type === 'brands') {
    return axios.get(`${URL}api/brand/all`);
  } else if (type === 'categories') {
    return axios.get(`${URL}api/category/all${queryParams || '?'}`);
  } else if (type === 'products') {
    return axios.get(`${URL}api/product/all${queryParams}`);
  } else if (type === 'tracking') {
    return axios.get(`${URL}api/product/tracking_code${queryParams}`);
  } else if (type === 'product') {
    return axios.get(`${URL}api/product${queryParams}`);
  }
}

export function fetchContentDetails(id) {
  return axios.get(`${URL}api/content/?content_id=${id}`);
}

export function fetchContentAll(type, q) {
  if (type === 'content') {
    return axios.get(`${URL}api/content/all`);
  } else {
    return axios.get(`${URL}api/content/search${q}`);
  }
}

export function addPost(payload) {
  return axios.post(`${URL}api/content`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function addFile(payload) {
  return axios.post(`${URL}api/file`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function editDetails(payload, query) {
  return axios.put(`${URL}api/post${query}`, payload);
}

export function editContent(payload, id) {
  return axios.put(`${URL}api/content?content_id=${id}`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function deleteSingleContent(id) {
  return axios.delete(`${URL}api/content/?content_id=${id}`);
}
