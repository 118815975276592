export default function Info({ creatorDetails }: any) {
  return (
    <div>
      <div className="data-pairs">
        <div className="label-value">
          <span className="label">FIRST NAME</span>
          <span className="value">{creatorDetails.first_name}</span>
        </div>
        <div className="label-value">
          <span className="label">LAST NAME</span>
          <span className="value">{creatorDetails.last_name}</span>
        </div>
      </div>
      <div className="data-pairs">
        <div className="label-value">
          <span className="label">Email Address</span>
          <span className="value">{creatorDetails.email}</span>
        </div>
        <div className="label-value">
          <span className="label">Country</span>
          <span className="value">{creatorDetails.country?.toUpperCase()}</span>
        </div>
      </div>
    </div>
  )
}
