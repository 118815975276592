import Logo from 'components/Logo';

export default function AuthContainer(props: any) {
  const { children, title, subTitle } = props;
  return (
    <div className="auth-container">
      <div className="inner">
        <div className="logo">
          <Logo />
        </div>
        <div className="children">
          <p className="intro">
            Empower you the creator, to <span>monetise</span> your content,
            while effectively inspiring consumers to{" "}
            <span>make good, without compromise.</span>{" "}
          </p>
          <h2 className="title">{title || ''}</h2>
          <p className="sub-title">{subTitle || ''}</p>
          {children}
        </div>
      </div>
    </div>
  )
}
