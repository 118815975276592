import React from 'react'
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

interface CrumbProps {
  crumbs: any[];
  icon: any;
}

export default function Crumbs(props: CrumbProps) {
  const { icon, crumbs } = props;
  return (
    <div className="crumbs-container">
      <div className="icon">{icon}</div>
      <Breadcrumb>
        {crumbs && crumbs.length > 0 && crumbs.map((crumb, index) => (
          <Breadcrumb.Item key={index}>
            {crumb.to
              ? <Link to={crumb.to}>{crumb.label}</Link>
              : <span>{crumb.label}</span>
            }
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  )
}
