import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'summary') {
    return axios.get(`${URL}api/analytics/sum${queryParams}`);
  } else if (type === 'filter') {
    return axios.get(`${URL}api/campaign/filter${queryParams}`);
  } else if (type === 'partners') {
    return axios.get(`${URL}api/analytics/top_partners?${queryParams}`);
  } else if (type === 'content_performance') {
    return axios.get(`${URL}api/analytics/content_performance?${queryParams}`);
  } else if (type === 'channel_performance') {
    return axios.get(`${URL}api/analytics/channel_performance?${queryParams}`);
  } else if (type === 'data_by_brands') {
    return axios.get(`${URL}api/analytics/data_by_brands?${queryParams}`);
  } else if (type === 'transactions') {
    return axios.get(`${URL}api/transaction/all?${queryParams}`);
  } else if (type === 'sum_analytics' || type === 'sum_analytics_compare') {
    return axios.get(`${URL}api/analytics/sum?${queryParams}`);
  } else if (type === 'content_performance') {
    return axios.get(`${URL}api/analytics/content_performance?${queryParams}`);
  } else if (type === 'channel_performance') {
    return axios.get(`${URL}api/analytics/channel_performance?${queryParams}`);
  } else if (type === 'posts') {
    return axios.get(`${URL}api/analytics/posts?${queryParams}`);
  }
  
}

export function editDetails(body, id, type) {
  if (type === 'toggle_activation') {
    return axios.put(`${URL}auth/user/toggle_activate`, body);
  } else if (type === 'links') {
    return axios.post(`${URL}api/campaign/links`, body);
  } else if (type === 'trans') {
    return axios.put(`${URL}api/transaction${id}`, body);
  }
}

export function fetchCreatorDetails(id) {
  return axios.get(`${URL}auth/user/single?user_id=${id}`);
}
