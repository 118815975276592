import { useState, useEffect } from 'react'
import { Skeleton } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { SidebarLayout } from 'components/layout'
import { CreatorVerification } from 'components/Icons'
import { Chart } from "react-google-charts";
import { getSingleCreator } from "../_redux/Actions";
import './styles.scss';


export const ageData = [
  ["label", "Age", { role: 'style' }],
  ["16+", 11, "color: #62A9E3"],
  ["20+", 5, "color: #62A9E3"],
  ["30+", 2, "color: #825F96"],
  ["50+", 9, "color: #825F96"],
];

export const audienceData = [
  ["Products", "2010 Population", { role: 'style' }],
  ["Clean Beauty", 8008, "color: #825F96"],
  ["Eyeliner", 3792, "color: #370E78"],
  ["Eyebrows", 6950, "color: #62A9E3"],
  ["Lashes", 9900, "color: #6863AB"],
  ["Makeup", 5260, "color: #7D9DCA"],
];

export const genderData = [
  ["Gender", "Amount", { role: 'style' }],
  ["Male", 4, "color: #6863AB"],
  ["Female", 7, "color: #370E78"],
  ["Others", 6, "color: #62A9E3"],
];

export const demographicData = [
  ["Countries", "population", { role: 'style' }],
  ["🇺🇸", 6, "color: #62A9E3"],
  ['🇧🇷', 4, "color: #6863AB"],
  ["🇮🇹", 7, "color: #370E78"],
  ["🇨🇦", 4, "color: #6863AB"],
  ["🇳🇬", 6, "color: #62A9E3"],
  ["🇬🇧", 6, "color: #62A9E3"],
];

export const demographicOptions = {
  title: "Audience Demographic",
  chartArea: { width: "95%" },
  // legend: "none",
  // legend: { textStyle: { fontSize: 24 } },
  legend: {
    textStyle: {
      bold: true,
      color: '#000',
      fontSize: 40
    }
  },
  bar: { groupWidth: "90%" },
  hAxis: {
    title: "",
    minValue: 0,
    // textStyle: {
    //   bold: true,
    //   color: '#000',
    //   fontSize: 40
    // }
  },
  vAxis: {
    gridlines: { color: 'transparent' },
    title: "",
    textPosition: 'none',
    // textStyle: {
    //   bold: true,
    //   color: '#000',
    //   fontSize: 40
    // }
  },
};

export const genderOptions = {
  title: "Gender",
  chartArea: { width: "95%" },
  legend: "none",
  bar: { groupWidth: "90%" },
  hAxis: {
    title: "",
    minValue: 0,
  },
  vAxis: {
    gridlines: { color: 'transparent' },
    title: "",
    textPosition: 'none',
  },
};

export const audienceOptions = {
  title: "Key Audience Interests",
  chartArea: { width: "75%" },
  legend: "none",
  bar: { groupWidth: "80%" },
  hAxis: {
    title: "",
    minValue: 0,
    textPosition: 'none',
    gridlines: { color: 'transparent' },
  },
  vAxis: {
    title: "",
    textStyle: {
      fontSize: 48
    }
  },
};

export const ageOptions = {
  title: "Age",
  pieHole: 0.65,
  pieSliceTextStyle: { color: 'transparent', },
  legend: "none",
  pieSliceText: "label",
  is3D: false,
};

const discoverer = {
  first_name: 'SOPZN',
  last_name: 'COKER',
  location: 'New York City, USA',
  EngagementRate: 22.4,
  nofPosts: 140,
  avgViews: 234,
};


export default function DicoverCreator() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const [crumbs, setCrumbs] = useState([{ label: '', to: '/' }])

  const { creatorDetails, creators } = useSelector(
    (state: any) => ({
      creatorDetails: state.verifications.creatorDetails,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators

    }),
    shallowEqual
  );


  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([{ label: ' / Profile: ' + creatorDetails.first_name + ' ' + creatorDetails.last_name, to: `/creator/${creatorDetails._id}` }])
    }
  }, [creatorDetails])

  useEffect(() => {
    if (creatorId) {
      dispatch(getSingleCreator(creatorId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId, creators]);

  useEffect(() => {
    if (creatorDetails && creatorDetails._id) {
      setLoading(false);
    }
  }, [creatorDetails])

  return (
    <SidebarLayout
      headerTitle="creator verification"
      headerIcon={CreatorVerification}
      crumbs={crumbs}
    >
      {loading || !discoverer
        ? <Skeleton />
        :
        <>
          <div className="creator-details">
            <div className="mask-data">
              <img src={require('assets/images/avatar-bg.png')} alt="*" className="avatar" />
              <span className="creator-name">{discoverer.first_name}{" "}{discoverer.last_name}</span>
              <div className="location">
                <span>{discoverer.location}</span>
              </div>
            </div>
          </div>
          <div className="engagements-meta">
            <div className="meta">
              <div className="label">Engagement Rate</div>
              <div className="value">{discoverer.EngagementRate}%</div>
            </div>
            <div className="meta">
              <div className="label">Number of posts</div>
              <div className="value">{discoverer.nofPosts}</div>
            </div>
            <div className="meta">
              <div className="label">Average views</div>
              <div className="value">{discoverer.avgViews}</div>
            </div>
          </div>
          <div className="charts-grid">
            <div className="chart-x">
              <Chart
                chartType="PieChart"
                width="100%"
                height={400}
                data={ageData}
                options={ageOptions}
                legendToggle={false}
              />
            </div>
            <div className="chart-x">
              <Chart
                chartType="PieChart"
                width="100%"
                height={400}
                data={ageData}
                options={ageOptions}
                legendToggle={false}
              />
            </div>
          </div>
          <div className="charts-grid">
            <div className="chart-x">
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={genderData}
                options={genderOptions}
              />
            </div>
            <div className="chart-x">
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={demographicData}
                options={demographicOptions}
              />
            </div>
          </div>
          <div className="charts-grid">
            <Chart
              chartType="BarChart"
              width="100%"
              height={480}
              data={audienceData}
              options={audienceOptions}
            />
          </div>
        </>
      }
    </SidebarLayout>
  )
}
