import React from 'react';
import { Layout } from 'antd';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './styles.scss';
import TopHeader from './TopHeader';
import Crumbs from './Crumbs';

const { Content } = Layout;



const SidebarLayout = (props: any) => {
  const { headerIcon, headerTitle, children, crumbs } = props;
  const Icc = headerIcon;
  return (
    <Layout>
      <Sidebar />
      <Layout className="layout-container">
        <TopHeader Icon={headerIcon} title={headerTitle} />
        <Content className="content-container">
          <Crumbs crumbs={crumbs && crumbs} icon={<Icc />} />
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout >
  );
};

export default SidebarLayout;