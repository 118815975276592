import { useState, useEffect } from 'react'
import { Skeleton } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import '../styles.scss';
import { getSingleCreator } from "../../_redux/Actions";
import searchicon from "assets/svg/searchicon.svg";
import arrowred from "assets/svg/arrowred.svg";
import arrowgreen from "assets/svg/arrowgreen.svg";
import arrowpurple from "assets/svg/arrowpurple.svg";
import arrowgray from "assets/svg/arrowgray.svg";
import checkbox from "assets/svg/checkgray.svg";

const contentperformancelist = [
  {
    id: 1,
    brand: "ROSE INC",
    pr: "TintedSerum",
    orders: 5,
    tsold: 170,
    returns: 10000,
    tc: 100,
    cl: 469,
    ctr: 3,
    cvr: 3,
  },
  {
    id: 4,
    brand: "JVN",
    pr: "BlackFriday/22",
    orders: 10,
    tsold: 170,
    returns: 10000,
    tc: 100,
    cl: 469,
    ctr: 3,
    cvr: 3,
  },
  {
    id: 1,
    brand: "BIOSSANCE",
    pr: "TintedSerum",
    orders: 6,
    tsold: 170,
    returns: 10000,
    tc: 100,
    cl: 469,
    ctr: 3,
    cvr: 3,
  },
];
const channelperformancelist = [
  {
    id: 1,
    channel: "INSTAGRAM",
    totalsold: 135000,
    reach: 10000,
    cvr: 12,
    returns: 4,
    order: 47,
  },
  {
    id: 2,
    channel: "TIKTOK",
    totalsold: 135,
    reach: 36445,
    cvr: 12,
    returns: 4,
    order: 30,
  },

  {
    id: 3,
    channel: "YOUTUBE",
    totalsold: 121,
    reach: 6000,
    cvr: 10,
    returns: 3,
    order: 44,
  },

  {
    id: 4,
    channel: "BLOG",
    totalsold: 87,
    reach: 1500,
    cvr: 2,
    returns: 1,
    order: 23,
  },
];

export default function ContentPerformance() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const [, setCrumbs] = useState([{ label: '', to: '/' }])

  const { creatorDetails, creators } = useSelector(
    (state: any) => ({
      creatorDetails: state.verifications.creatorDetails,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators

    }),
    shallowEqual
  );


  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([{ label: ' / Profile: ' + creatorDetails.first_name + ' ' + creatorDetails.last_name, to: `/creator/${creatorDetails._id}` }])
    }
  }, [creatorDetails])

  useEffect(() => {
    if (creatorId) {
      dispatch(getSingleCreator(creatorId));
    } else {
      // setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId, creators]);

  useEffect(() => {
    if (creatorDetails && creatorDetails._id) {
      setLoading(false);
    }
  }, [creatorDetails])

  return (
    <div className="analytics-container">
      {loading
        ? <Skeleton />
        :
        <>
          <div className="ppbodytop">
            <div className="ppbodytopleft">
              <label className="ppbodytopleftsearch">Search</label>
              <div className="ppbodytopsearch">
                <img src={searchicon} alt="" />
                <input
                  type="text"
                  placeholder="e.g. John"
                  className="ppbodytopinput"
                />
                <label className="charactercount">0/20</label>
              </div>
            </div>

            <div className="ppbodytopright">
              <label className="ppbodytopleftsearch">Filter: By date</label>

              <div className="ppbodytopdate">
                {/* <DatePicker /> */}
              </div>
            </div>
          </div>
          <div className="analytics-topone">
            <div className="analytics-toponeitem">
              <p className="tponetitle">Earning Summary</p>
              <div className="tponebuttom">
                <div className="tponebuttom-item">
                  <label className="tponebuttomtitle">Lifetime earnings</label>
                  <label className="tponebuttomsen">$68,012</label>
                </div>
                <div className="tponebuttom-item">
                  <label className="tponebuttomtitle">Cash out balance</label>
                  <label className="tponebuttomsen">$620</label>
                </div>
              </div>
            </div>
            <div className="analytics-toponeitem">
              <label className="tponetitle">Partnership growth</label>
              <div className="tponebuttom">
                <div className="tponebuttom-item">
                  <label className="tponebuttomtitle">Partnership Level</label>
                  <label className="attiamount">Tier 2</label>
                </div>
                <div className="tponebuttom-item">
                  <label className="tponebuttomtitle">Units sold</label>
                  <label className="tponebuttomsen">100</label>
                </div>
              </div>
            </div>
          </div>
          <div className="analytics-toptotals">
            <div className="analytics-toptotalsitem">
              <label className="attititle">Total sales</label>
              <label className="tponebuttomsen">$789.093</label>
              <div className="attirating">
                <img src={arrowgreen} alt="" />
                <label className="attirate atgrn">1.6%</label>
              </div>
            </div>
            <div className="analytics-toptotalsitem">
              <label className="attititle">Total commissions</label>
              <label className="tponebuttomsen">$4.060,893</label>
              <div className="attirating">
                <img src={arrowgreen} alt="" />
                <label className="attirate atgrn">4.1%</label>
              </div>
            </div>
            <div className="analytics-toptotalsitem">
              <label className="attititle">Total # orders</label>
              <label className="attiamount">5.839</label>
              <div className="attirating">
                <img src={arrowred} alt="" />
                <label className="attirate atrd">2.3%</label>
              </div>
            </div>
            <div className="analytics-toptotalsitem">
              <label className="attititle">Number of clicks</label>
              <label className="attiamount">$345.391</label>
              <div className="attirating">
                <img src={arrowgreen} alt="" />
                <label className="attirate atgrn">1.6%</label>
              </div>
            </div>
            <div className="analytics-toptotalsitem">
              <label className="attititle">Avg. return rate</label>
              <label className="attiamount">4.6%</label>
              <div className="attirating">
                <img src={arrowred} alt="" />
                <label className="attirate atrd">5.3%</label>
              </div>
            </div>
          </div>
          <div className="analyticscp">
            <p className="analyticscptitle">Content Performance</p>
            <div className="ctpover">
              <div className="ctpeformance_header cpheader">
                <div className="ctp">
                  <div className="ctpp">
                    <img src={checkbox} alt="" />
                    <label className="ctppname">Brand</label>
                  </div>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname">PRODUCT REFERENCE</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname">AFFILIATE LINK/CODE</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname">NO OF ORDERS</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname">TOTAL SOLD($)</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname"># RETURNS</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname">TOTAL COMMISSION($)</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname"># CLICKS</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname">CTR</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ctp">
                  <label className="ctppname">CVR</label>
                  <div className="ctpfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
              </div>
              {contentperformancelist.map((cplist, key) => (
                <div className="ctpeformance_header" key={key}>
                  <div className="ctp">
                    <div className="ctpp">
                      <img src={checkbox} alt="" />
                      <label className="ctppname">{cplist.brand}</label>
                    </div>
                  </div>
                  <div className="ctp">
                    <label className="ctppname">{cplist.pr}</label>
                  </div>
                  <div className="ctp">
                    <label className="ctppname aflink">CLICK HERE</label>
                  </div>
                  <div className="ctp">
                    <label className="ctppname">{cplist.orders}</label>
                  </div>
                  <div className="ctp">
                    <label className="ctppname">${cplist.tsold}</label>
                  </div>
                  <div className="ctp">
                    <label className="ctppname">{cplist.returns}</label>
                  </div>
                  <div className="ctp">
                    <label className="ctppname">${cplist.tc}</label>
                  </div>
                  <div className="ctp">
                    <label className="ctppname">{cplist.cl}</label> 
                  </div>
                  <div className="ctp">
                    <label className="ctppname">{cplist.ctr}%</label>
                  </div>
                  <div className="ctp">
                    <label className="ctppname">{cplist.cvr}%</label>
                  </div>
                </div>
              ))}
            </div>

            <button className="analytic_btn">LOAD MORE</button>
          </div>
          <div className="analyticscp">
            <p className="analyticscptitle">Channel Performance</p>
            <div className="ctpover">
              <div className="analyticscpheader">
                <div className="ansphref">
                  <div className="ansphbrands">
                    <img src={checkbox} alt="" />
                    <label className="ansphbrandsname">CHANNEL</label>
                  </div>
                  <div className="ansphfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ansphref">
                  <label className="ansphbrandsname">TOTAL SOLD</label>
                  <div className="ansphfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ansphref">
                  <label className="ansphbrandsname">ORDERS</label>
                  <div className="ansphfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ansphref">
                  <label className="ansphbrandsname">REACH</label>
                  <div className="ansphfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ansphref">
                  <label className="ansphbrandsname">CVR</label>
                  <div className="ansphfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
                <div className="ansphref">
                  <label className="ansphbrandsname">#RETURNs</label>
                  <div className="ansphfilter">
                    <img src={arrowpurple} alt="" />
                    <img src={arrowgray} alt="" />
                  </div>
                </div>
              </div>
              {channelperformancelist.map((cplist, key) => (
                <div
                  className={
                    key === channelperformancelist.length - 0
                      ? "analyticitemchange"
                      : "analyticitem"
                  }
                  key={key}
                >
                  <div className="ansphref">
                    <div className="ansphbrands">
                      <img src={checkbox} alt="" className="naasa" />
                      <label className="ansphbrandsname">{cplist.channel}</label>
                    </div>
                  </div>
                  <div className="analyticbrands">
                    <div className="cptotalprog">
                      <div
                        className="cptotalprogna"
                        style={{ width: cplist.totalsold }}
                      >
                        <label>${cplist.totalsold}</label>
                      </div>
                    </div>
                  </div>
                  <div className="analyticproduct">
                    <div className="">
                      <label className="productretitle" key={key}>
                        {cplist.order}
                      </label>
                    </div>
                  </div>
                  <div className="analyticproduct">
                    <div className="">
                      <label className="productretitle" key={key}>
                        {cplist.reach}
                      </label>
                    </div>
                  </div>
                  <div className="analyticproduct">
                    <div className="">
                      <label className="productretitle" key={key}>
                        {cplist.cvr}%
                      </label>
                    </div>
                  </div>
                  <div className="analyticproduct">
                    <div className="">
                      <label className="productretitle" key={key}>
                        {cplist.returns}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button className="analytic_btn">LOAD MORE</button>
          </div>
        </>
      }
    </div>
  )
}
