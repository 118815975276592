import * as requestFromServer from "./Crud";
import { CommunitytHubSlice, callTypes } from "./Slice";

const { actions } = CommunitytHubSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'bookings') {
        dispatch(actions.booksList(res.success));
        return;
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const uploadFile = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addFile(payload)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getEventsAll = (type = 'event', q = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchEventAll(type, q)
    .then(response => {
      let res = response.data;
      const sucres = [...res.success];
      if (type === 'attendees') {
        dispatch(actions.attend(res.success));
        return;
      }
      if (type === 'disputes' || type === 'search_disputes') {
        dispatch(actions.disputes(res.success));
        return;
      }
      if (sucres.length > 0) {
        dispatch(actions.eventsAll(sucres.reverse()));
      } else {
        dispatch(actions.eventsAll(sucres));
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const resetEventsAll = () => dispatch => {
  dispatch(actions.resetEvents());
}


export const createEvent = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addEvent(payload)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Cannot create a product at the moment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearEventDetails = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.clearEventData());
}

export const setEvent = (data) => dispatch => {
  dispatch(actions.eventsAll(data));
}

export const setBook = (data) => dispatch => {
  dispatch(actions.booksList(data));
}

export const getSingleEvent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchEventDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.eventDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch event";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const editDispute = (data, q) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editDispute(data, q)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch event";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const editSingleContent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchEventDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.eventDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch event";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const editSingleEvent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editEvent(id)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable toupdate event";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// editEvent

export const getSingleDispute = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchEventDetails2(id)
    .then(response => {
      let res = response.data;
      // dispatch(actions.brandDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch dispute";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const reset = () => dispatch => {
  dispatch(actions.reset());
};

export const deleteEvent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSingleEvent(id)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to delete brand";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};