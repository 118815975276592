import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLayout from "components/layout/SidebarLayout";
import { ContentHub as CHub } from "components/Icons";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import { getSingleContent } from "../_redux/Actions";
import { Skeleton } from "antd";
import "./styles.scss";
import { Modal } from 'antd';
import { Button } from "components/forms";
import Vimeo from '@u-wave/react-vimeo';
import { ProgressiveImage } from "components/shared";
import ReactMarkdown from 'react-markdown';

export default function ContentDetails() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const params = useParams();
  const { id } = params;
  const [play, set_play] = useState(false);

  const limitStr = (str: string, length: number) => {
    const spl = str.split("");
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join("") + "...";
    }
    return newStr;
  };

  const [crumbs, setCrumbs] = useState([
    { label: 'getting you started', to: '/content' },
    { label: '', to: `/content/post/12` },
  ])

  const [modalIsOpen, setIsOpen] = useState(false);
  const [video, set_video] = useState(null);

  const { contentDetails, contentList } = useSelector(
    (state: any) => ({
      contentDetails: state.contenthub.contentDetails,
      contentList: state.contenthub.contentList,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSingleContent(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setCrumbs([
      { label: 'getting you started', to: '/content' },
      { label: contentDetails?.title || 'N/A', to: `/content/${contentDetails?._id}` },
    ])
  }, [contentDetails]);

  useEffect(() => {
    if (contentDetails && contentDetails._id) {
      setCrumbs([
        { label: 'getting you started', to: '/content' },
        { label: contentDetails.title || 'N/A', to: `/content/post/${contentDetails._id}` },
      ])

    }
  }, [contentDetails])



  function removeMarkdownAndHTML(text: string) {
    // Remove HTML tags
    const withoutHTML = text.replace(/<[^>]+>/g, '');
  
  
    return withoutHTML;
  }

  return (
    <SidebarLayout
      headerTitle="Content hub"
      headerIcon={CHub}
      crumbs={crumbs}
    >
      <div className="content-hub-container">
        {contentDetails && contentDetails._id ? <>
          <div className="header-section">
            <h2 className="section-title">{contentDetails.title}</h2>
            <button className="btn btn-img" onClick={() => navigate(`/content/edit/${contentDetails._id}`)}>
              <img src={require('assets/images/upload-plus.png')} alt="" />
              <span>Edit Content</span>
            </button>
          </div>
          <div className="banner">
            {/* <img src={contentDetails.thumbnail} alt="" className="large" />
            <img src={require('assets/images/play-btn.png')} alt="" className="play" /> */}

            {
              !play ?
                <img src={contentDetails.thumbnail} alt="" className="large" />
                :
                <div className="large"
                  style={{
                  }}>
                  <Vimeo
                    video={contentDetails.video_url}
                    width={940}
                    height={580}
                    autoplay={true}
                  />
                </div>
            }

            {
              contentDetails.video_url && !play &&
              <img src={require('assets/images/play-btn.png')} onClick={() => set_play(true)} alt="" className="play" />
            }
          </div>
          {/* <div className="description" dangerouslySetInnerHTML={{ __html: contentDetails.body }} /> */}
          
          <div className="prose lg:prose-xl">
            <ReactMarkdown className="prose">{removeMarkdownAndHTML(contentDetails.body)}</ReactMarkdown>
          </div>

          <div className="header-section" style={{ marginTop: 50 }}>
            <h2 className="section-title">Want to read more?</h2>
          </div>
          <div className="content-list">
            {contentList && contentList.length > 0
              ? contentList.filter((opt: any) => opt._id !== id).map((el: any, index: number) => (
                <div className="content-list-item" key={index}>
                  <div className="feature-image">
                    <ProgressiveImage
                      imgSrc={el.thumbnail}
                      previewSrc={require('assets/images/holder.png')}
                      width="100%"
                      height="75%"
                      style={{ objectFit: 'cover', objectPosition: 'center', height: '230px' }}
                    />
                    <div className="overlay-feature"></div>
                    {/* <img src={require('assets/images/play-btn.png')} alt="" className="play" /> */}
                    {el.video_url &&
                      <img src={require('assets/images/play-btn.png')} onClick={() => {
                        set_video(el.video_url)
                        setIsOpen(true)
                      }} alt="" className="play" />}
                  </div>
                  <h3 className="title">{el.title}</h3>
                  <ReactMarkdown className="sub-title">{limitStr(el.body, 80)}</ReactMarkdown>
                  {/* <div className="sub-title" dangerouslySetInnerHTML={{ __html: limitStr(el.body, 80) }} /> */} 
                  <Button
                    // href={`/content/post/${el._id}`
                    label="Watch or read" variant="primary"
                    onClick={() => window.location.href = `/content/${el._id}`}
                  />
                </div>
              ))
              : ''
            }
          </div>
        </>
          : <Skeleton paragraph={{ rows: 10 }} />
        }
      </div>




      <Modal
        centered
        open={modalIsOpen}
        onOk={() => {
          setIsOpen(false);
        }}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={null}
        title={null}
      >
        {video &&
          <div className="video"
            style={{
              height: 400,
              width: 400,
              // marginRight: 20
            }}>
            <Vimeo
              video={video}
              width={520}
              height={400}
              autoplay={true}
            />
          </div>}
      </Modal>
    </SidebarLayout>
  );
}
