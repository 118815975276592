import { Navigate, Route, Routes } from 'react-router-dom'
import Login from "./auth/Login";

export function AuthPage() {

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<p>Path not resolved</p>} />
      {/* <Route path="/login" element={<Login />} />  */}
      {/* <Route path='/auth/login' element={<Login/>} /> */}
      {/* <Navigate from='/auth' exact={true} to='/auth/login' /> */}
      {/* <Navigate to='/auth/login' /> */}
      <Route path="/" element={<Navigate to="/auth/login" />} />

    </Routes>
  )
}


