import * as requestFromServer from "./Crud";
import { ProductsSlice, callTypes } from "./Slice";
import { toast } from 'react-toastify';

const { actions } = ProductsSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'brands') {
        dispatch(actions.brandsList(res.success));
        return;
      } else if (type === 'categories') {
        dispatch(actions.catList(res.success));
        return;
      } else if (type === 'products') {
        dispatch(actions.productList(res.success));
      } else if (type === 'commission') {
        dispatch(actions.comList(res.success));
      } else if (type === 'starter') {
        dispatch(actions.starterList(res.success));
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const addSingleProduct = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addProduct(payload)
    .then(response => {
      if (response.data.success) {
        let res = response.data;
        return res;
      } else {
        toast.error('Some error occured');
      }
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addData = (data, type = 'commission') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addData(data, type)
    .then(response => {
      if (response.data.success) {
        let res = response.data;
        if (type === 'selected_products') {
          toast.info('Success! Added creator\'s product starter pack!')
          dispatch(actions.starterList(res.success));
        }
        return res;
      } else {
        toast.error('Some error occured');
      }
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateData = (data, query, type = 'commission') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editData(data, query, type)
    .then(response => {
      if (response.data.success) {
        let res = response.data;
        console.log(res);
        return res;
      } else {
        toast.error('Some error occured');
      }
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBrand = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addBrand(payload)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const uploadFile = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addFile(payload)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProductMediaAll = (queries) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductFilesAll(queries)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch files";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const deleteProductMediaAll = (queries) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAllMedia(queries)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch files";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProduct = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addProduct(payload)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Cannot create a product at the moment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const editBrandDetails = (payload, brandId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editBrand(payload, brandId)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const editDetails = (payload, brandId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editDetails(payload, brandId)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const editSingleProduct = (payload, productId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editProduct(payload, productId)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSingleBrand = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchBrandDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.brandDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSingleProduct = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchProductDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.productDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getProductInfoExt = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetctProductExt(id)
    .then(response => {
      console.log('RESS: ', response);
      let res = response.data;
      if (res.success) {
        dispatch(actions.productDetailsExt(res.success));
      } else {
        toast.error(res.error)
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const reset = () => dispatch => {
  dispatch(actions.reset());
};

export const clearProductDetails = () => dispatch => {
  dispatch(actions.clearProductDetails());
};

export const deleteBrand = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSingleBrand(id)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProduct = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSingleP(id)
    .then(response => {
      let res = response.data;
      // toast.success(`Successfully deleted product!`);
      // dispatch(actions.DatasStatusUpdated({ id: res.success._id, verification_status: res.success.verification_status }));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};