import { useState, useEffect } from 'react'
import { Steps, Dropdown, message, Popconfirm, Radio, Popover } from 'antd';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SidebarLayout } from 'components/layout'
import { ProductCatalogue } from 'components/Icons'
import Slider from "react-slick";
import { toast } from 'react-toastify';
import { Button, Field } from 'components/forms';
import { getSingleBrand, deleteProduct, editBrandDetails, fetchAllData, reset, addData, updateData } from '../_redux/products/Actions';
import bannerBg from "assets/images/banner-placeholder.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';
import { Empty } from 'components/shared';


var items: any = [];

const NextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={require('assets/images/next-icon.png')} alt="" />
    </div>
  );
}

const PrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={require('assets/images/prev-icon.png')} alt="" />
    </div>
  );
}

export default function BrandDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const brandId: any = params?.id;
  const dispatch = useDispatch<any>();
  const [selectedCategory, setCategory] = useState('All');
  const [pumpfake, setPumpfake] = useState(2);

  const [commissionSteps, set_commissionSteps] = useState<any>([
    {
      title: 'Start off base commission ',
    },
    {
      title: 'After first 30 units sold',
    },
    {
      title: 'After £100k in sales/month',
    },
    {
      title: 'After £200k in sales/month',
    },
  ])

  const [t1_data, set_t1_data] = useState<any>(null)
  const [t2_data, set_t2_data] = useState<any>(null)
  const [t3_data, set_t3_data] = useState<any>(null)
  const [t4_data, set_t4_data] = useState<any>(null)

  const [discount, set_discount] = useState();

  const [desc1, set_desc1] = useState()
  const [commission1, set_commission1] = useState()
  const [average1, set_average1] = useState()
  const [mode1, set_mode1] = useState('unit_sold')
  const [duration1, set_duration1] = useState('per_day')

  const [, set_desc2] = useState()
  const [commission2, set_commission2] = useState()
  const [average2, set_average2] = useState()
  const [mode2, set_mode2] = useState('unit_sold')
  const [duration2, set_duration2] = useState('per_day')

  const [, set_desc3] = useState()
  const [commission3, set_commission3] = useState()
  const [average3, set_average3] = useState()
  const [mode3, set_mode3] = useState('unit_sold')
  const [duration3, set_duration3] = useState('per_day')

  const [, set_desc4] = useState()
  const [commission4, set_commission4] = useState()
  const [average4, set_average4] = useState()
  const [mode4, set_mode4] = useState('unit_sold')
  const [duration4, set_duration4] = useState('per_day')

  const [, setLoading] = useState<boolean>(true);
  const [, contextHolder] = message.useMessage();
  const [bannerBgImage, setbannerBgImage] = useState<any>(bannerBg);
  const [vg_bg, setbannerVg] = useState<any>(null);

  
  const [parsedDesc, setParsedDesc] = useState<any>('');
  const [commisionEdit, setCommisionEdit] = useState(false);
  const [, set_commissions] = useState([]);
  const [available_categories, set_available] = useState<any>([]);
  const [sliderKey, setSliderKey] = useState(Date.now());


  const { categories, brandDetails, products, commission } = useSelector(
    (state: any) => ({
      actionsLoading: state.products.actionsLoading,
      categories: state.products.categories,
      products: state.products.products,
      listLoading: state.products.listLoading,
      commission: state.products.commission,
      brandDetails: state.products.brandDetails,
    }),
    shallowEqual
  );

  const productSettings = {
    dots: false,
    infinite: false,
    key: sliderKey,
    count: products?.length,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const crumbs = [
    { label: 'brands', to: '/products' },
    { label: `brands: ${brandDetails?.name}`, to: `/brand/${brandId}` },
  ];

  useEffect(() => {
    if (brandDetails) {
      set_discount(brandDetails?.discount || '0')
      if (brandDetails?.video) {
        setbannerVg(`${brandDetails.video}`)
        setbannerBgImage(null)
      } else if (brandDetails?.logo) {
        setbannerBgImage(`${brandDetails.banner || brandDetails.logo}`)
        setbannerVg(null)
      }
      if (brandDetails?.description) {
        const parser = JSON.parse(brandDetails.description);
        setParsedDesc(parser)
      }
    }
  }, [brandDetails]);

  useEffect(() => {
    dispatch(reset());
    if (brandId) {
      dispatch(getSingleBrand(brandId));
      dispatch(fetchAllData('products', `?brand_id=${brandId}&all=true`));
      dispatch(fetchAllData('commission', `?brand_id=${brandId}&all=true`));
      dispatch(fetchAllData('categories', `?brand_id=${brandId}&all=true`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDesc(data: any) {
    var dur = data?.duration === 'per_month' ? 'per month' : data?.duration === 'per_day' ? 'per day' : data?.duration;
    if (data?.mode === 'unit_sold') {
      return `Avg. of ${data?.average} units sold/${dur}`;
    }
    return `Avg. of £${data?.average} in ${data?.mode}/${dur}`;
  }

  useEffect(() => {
    if (commission && commission.length > 0) {

      var t1 = commission.find((x: any) => x.name === 't1');
      var t2 = commission.find((x: any) => x.name === 't2');
      var t3 = commission.find((x: any) => x.name === 't3');
      var t4 = commission.find((x: any) => x.name === 't4');

      var dd = [
        {
          title: t1?.description || 'Start off base commission'
        },
        {
          title: getDesc(t2),
        },
        {
          title: getDesc(t3)
        },
        {
          title: getDesc(t4)
        },
      ]
      set_commissionSteps(dd);

      set_t1_data(t1);
      set_t2_data(t2);
      set_t3_data(t3);
      set_t4_data(t4);

      // console.log(t1);
      set_commission1(t1?.commission);
      set_desc1(t1?.description);
      set_mode1(t1?.mode);
      set_average1(t1?.average);
      set_duration1(t1?.duration);

      set_commission2(t2?.commission); set_mode2(t2?.mode); set_duration2(t2?.duration); set_desc2(t2?.description); set_average2(t2?.average);
      set_commission3(t3?.commission); set_mode3(t3?.mode); set_duration3(t3?.duration); set_desc3(t3?.description); set_average3(t3?.average);
      set_commission4(t4?.commission); set_mode4(t4?.mode); set_duration4(t4?.duration); set_desc4(t4?.description); set_average4(t4?.average);

    } else {
      set_t1_data(null);
      set_t2_data(null);
      set_t3_data(null);
      set_t4_data(null);

    }
  }, [commission])

  useEffect(() => {
    if (products && products.length > 0) {
      var a: any = []
      products.forEach((element: any) => {
        a.push(...element.categories)
      });
      let uniqueItems = [...new Set(a)];
      set_available(uniqueItems)
    } else {
      set_available([])
    }
  }, [products]);

  useEffect(() => {
    if (commission) {
      set_commissions(commission)
    }
  }, [commission])

  useEffect(() => {
    var d: any = []

    var dd: any = {};
    dd['label'] = 'All';
    dd['title'] = 'All';
    dd['key'] = '';
    d.push(dd);

    categories && categories.forEach((element: any, index: number) => {
      var dd: any = {};
      dd['label'] = element.name;
      dd['title'] = element.name;
      dd['key'] = element._id;
      d.push(dd);
    });
    items = d;

  }, [categories])

  function tracking(id: any) {
    dispatch(fetchAllData('tracking', `?product_id=${id}`)).then((res: any) => {
      if (res.success) {
        var cp = `${res.success}`;
        toast.info(`Tracking link ${cp} has been copied to your clipboard!`);
        navigator.clipboard.writeText(cp);
      } else {
        toast.error(res.error);
      }
    })
  }

  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('');
    }
    return newStr;
  }

  const handleMenuClick = (el: any) => {
    setCategory(el?.item?.props?.title)
    if (el.key) {
      dispatch(fetchAllData('products', `?brand_id=${brandId}&category=${el.key}&all=true`));
    } else {
      dispatch(fetchAllData('products', `?brand_id=${brandId}&all=true`));
    }
    setSliderKey(Date.now());
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const confirm = (el: React.MouseEvent<HTMLElement> | any) => {
    setLoading(true);
    dispatch(deleteProduct(el)).then((data: any) => {
      toast.success('Deleted successfully');
      dispatch(fetchAllData('products', `?brand_id=${brandId}&all=true`));
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('An error occured. Please retry later.');
    })
  };

  const cancel = (e: React.MouseEvent<HTMLElement> | any) => {
    console.log(e);
  };

  function sendCom() {

    if (!brandDetails.discount || brandDetails.discount !== discount) {
      var br: any = {};
      br['discount'] = discount;
      dispatch(editBrandDetails(br, brandId)).then((data: any) => {
        dispatch(getSingleBrand(brandId));
        if (data.success) {
          toast.success('Succesfully editted brand discount');
        }
      }).catch((error: any) => {
        toast.error('Error occured editted discount');
      })
    }



    const t1 = {
      name: 't1',
      description: desc1,
      brand_id: brandId,
      commission: commission1 || 0,
      average: average1 || 0,
      mode: mode1,
      duration: duration1
    }

    const t2 = {
      name: 't2',
      description: 'Avg of £3K in sales/month',
      brand_id: brandId,
      commission: commission2 || 0,
      average: average2 || 0,
      mode: mode2,
      duration: duration2
    }

    const t3 = {
      name: 't3',
      description: 'Avg of £6K in sales/month',
      brand_id: brandId,
      commission: commission3 || 0,
      average: average3 || 0,
      mode: mode3,
      duration: duration3
    }

    const t4 = {
      name: 't4',
      description: 'Avg of £9K in sales/month',
      brand_id: brandId,
      commission: commission4 || 0,
      average: average4 || 0,
      mode: mode4,
      duration: duration4
    }

    // console.log(t1);
    if (t1_data && t2_data) {
      dispatch(updateData(t1, `?commission_id=${t1_data._id}`));
      dispatch(updateData(t2, `?commission_id=${t2_data._id}`));
      dispatch(updateData(t3, `?commission_id=${t3_data._id}`));
      dispatch(updateData(t4, `?commission_id=${t4_data._id}`)).then((data: any) => {
        // console.log(data);
        setCommisionEdit(false)
        dispatch(fetchAllData('commission', `?brand_id=${brandId}&all=true`));
        toast.info('Success! Commission scheme updated!')
      }).catch((error: any) => {
        setCommisionEdit(false)
        toast.error('Error occured while saving data');
      });
    } else {
      dispatch(addData(t1));
      dispatch(addData(t2));
      dispatch(addData(t3));
      dispatch(addData(t4)).then((data: any) => {
        // console.log(data);
        setCommisionEdit(false)
        dispatch(fetchAllData('commission', `?brand_id=${brandId}&all=true`));
        toast.info('Success! Commission scheme added')
      }).catch((error: any) => {
        setCommisionEdit(false)
        toast.error('Error occured while saving data');
      });
    }


  }

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      {contextHolder}
      <div className="brand-details-container">
        {/* <>{console.log({bannerBgImage})}</> */}
        <div className="banner"  
        // style={{ backgroundImage: `url(${bannerBgImage})` }}
        // style = {{
        //   display: 'flex'
        // }}
        >
       


          {bannerBgImage && !vg_bg && (
            <div
              className="banner-image"
              style={{ backgroundImage: `url(${bannerBgImage})`,
              // backgroundColor: 'red'
            
            }}
            ></div>
          )}
          {vg_bg && (
            <video className="banner-video" autoPlay loop muted>
              <source src={vg_bg} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}

<button className="btn btn-img banner-editable" onClick={() => navigate(`/brand/edit/${brandId}`)}>
            <img src={require('assets/images/edit-white.png')} alt="" />
            <span>EDIT BRAND</span>
          </button>

          
        </div>


        {brandDetails &&
          <section className="brand-info">
            <h2 className="section-title">{brandDetails?.header_description || brandDetails.name}</h2>
            <div className="description" dangerouslySetInnerHTML={{ __html: parsedDesc }} />
          </section>
        }
        <section className="commission">
          <h2 className="section-title">Commission scheme across all portfolio</h2>
          {!commisionEdit &&
            <button className="btn btn-img commission-editable" onClick={() => setCommisionEdit(true)}>
              <img src={require('assets/images/edit-black.png')} alt="" />
              <span>edit commission scheme</span>
            </button>
          }
          {commisionEdit
            ? <div className="edit-process">

              <Field required={true} type="number" value={discount} label="Brand Discount" bottomLabel
                onChange={(e: any) => set_discount(e.target.value)} placeholder="0%"
              />
              <div className="per-sale">

                <div className="sales">
                  <Field required={true} type="number" value={commission1} label="Commission per sale" bottomLabel
                    onChange={(e: any) => set_commission1(e.target.value)} placeholder="0%"
                  />
                  <div className="term">t1</div>
                  <div className="each-set">
                    <Field required={true} value={desc1} label="T1 Description" bottomLabel
                      onChange={(e: any) => set_desc1(e.target.value)}
                    />
                    {/* <div className="averages">
                      <Radio.Group value={mode1} onChange={(e: any) => set_mode1(e.target.value)}>
                        <Radio.Button value="unit_sold">units sold</Radio.Button>
                        <Radio.Button value="sales">sales</Radio.Button>
                      </Radio.Group>
                      <Radio.Group value={duration1} onChange={(e: any) => set_duration1(e.target.value)}>
                        <Radio.Button value="per_day">per day</Radio.Button>
                        <Radio.Button value="per_month">per month</Radio.Button>
                      </Radio.Group>
                    </div> */}
                  </div>
                </div>

                <div className="sales">
                  <Field required={true} type="number" value={commission2} label="Commission per sale" bottomLabel
                    onChange={(e: any) => set_commission2(e.target.value)} placeholder="0%"
                  />
                  <div className="term">t2</div>
                  <div className="each-set">
                    <Field required={true} type="number" value={average2} label="Average of" bottomLabel
                      onChange={(e: any) => set_average2(e.target.value)}
                    />
                    <div className="averages">
                      <Radio.Group value={mode2} onChange={(e: any) => set_mode2(e.target.value)}>
                        <Radio.Button value="unit_sold">units sold</Radio.Button>
                        <Radio.Button value="sales">sales</Radio.Button>
                      </Radio.Group>
                      <Radio.Group value={duration2} onChange={(e: any) => set_duration2(e.target.value)}>
                        <Radio.Button value="per_day">per day</Radio.Button>
                        <Radio.Button value="per_month">per month</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </div>

                <div className="sales">
                  <Field required={true} type="number" value={commission3} label="Commission per sale" bottomLabel
                    onChange={(e: any) => set_commission3(e.target.value)} placeholder="0%"
                  />
                  <div className="term">t3</div>
                  <div className="each-set">
                    <Field required={true} type="number" value={average3} label="Average of" bottomLabel
                      onChange={(e: any) => set_average3(e.target.value)}
                    />
                    <div className="averages">
                      <Radio.Group value={mode3} onChange={(e: any) => set_mode3(e.target.value)}>
                        <Radio.Button value="unit_sold">units sold</Radio.Button>
                        <Radio.Button value="sales">sales</Radio.Button>
                      </Radio.Group>
                      <Radio.Group value={duration3} onChange={(e: any) => set_duration3(e.target.value)}>
                        <Radio.Button value="per_day">per day</Radio.Button>
                        <Radio.Button value="per_month">per month</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </div>

                <div className="sales">
                  <Field required={true} type="number" value={commission4} label="Commission per sale" bottomLabel
                    onChange={(e: any) => set_commission4(e.target.value)} placeholder="0%"
                  />
                  <div className="term">t4</div>
                  <div className="each-set">
                    <Field required={true} type="number" value={average4} label="Average of" bottomLabel
                      onChange={(e: any) => set_average4(e.target.value)}
                    />
                    <div className="averages">
                      <Radio.Group value={mode4} onChange={(e: any) => set_mode4(e.target.value)}>
                        <Radio.Button value="unit_sold">units sold</Radio.Button>
                        <Radio.Button value="sales">sales</Radio.Button>
                      </Radio.Group>
                      <Radio.Group value={duration4} onChange={(e: any) => set_duration4(e.target.value)}>
                        <Radio.Button value="per_day">per day</Radio.Button>
                        <Radio.Button value="per_month">per month</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </div>


                <div className="actions">
                  <Button onClick={() => sendCom()} variant="primary" label={t1_data ? "UPDATE CHANGES" : "SAVE CHANGES"} />
                </div>
              </div>
            </div>
            : <div className="process">
              <div className="steppers">
                <div className="each">
                  <span className="rate">{t1_data ? `${t1_data?.commission}%` : '0%'}</span>
                  <span>Commission</span>
                </div>
                <div className="each">
                  <span className="rate">{t2_data ? `${t2_data?.commission}%` : '0%'}</span>
                  <span>Commission</span>
                </div>
                <div className="each">
                  <span className="rate">{t3_data ? `${t3_data?.commission}%` : '0%'}</span>
                  <span>Commission</span>
                </div>
                <div className="each">
                  <span className="rate">{t4_data ? `${t4_data?.commission}%` : '0%'}</span>
                  <span>Commission</span>
                </div>
                <div className="each">
                  <span className="rate">
                    {`${brandDetails?.discount || 0}%`}
                  </span>
                  <span>Promo Discount</span>
                  <span className="extra-info">*Promo Discount applies to all tiers</span>
                </div>
              </div>
              <Steps
                labelPlacement="vertical"
                items={commissionSteps}
              />
              <p className="note">*Commission Scheme Across all Portfolio</p>
            </div>
          }
        </section>
        <section className="brand-listings">
          <div className="title-section">
            <h2 className="ttle">Products</h2>
            <div className="dorpdown">
              <span>FILTER: PRODUCTS</span>
              <Dropdown menu={menuProps} trigger={['click']}>
                <div className="user">
                  <span className="labele">{selectedCategory}</span>
                  <DownOutlined color="#370E78" />
                </div>
              </Dropdown>
            </div>
          </div>
          <div className="flex items-center justify-center my-4">
            <button className="btn btn-img banner-editable" onClick={() => navigate(`/product/${brandId}/add`)}>
              <img src={require('assets/images/upload-plus.png')} alt="" />
              <span>Add Product</span>
            </button>
            {/* <button className="btn btn-img banner-editable ml-4" onClick={() => navigate(`/product/${brandId}/creator-pack`)}>
              <img src={require('assets/images/edit-black.png')} alt="" />
              <span>Edit Creator's Pack</span>
            </button> */}
          </div>
          <div className="product-listing">
            {available_categories && available_categories.length > 0
              ?
              <>
                {available_categories.map((catt: any, i: string) => {
                  return (
                    <div>
                      <h2 className="section-sub-title">{categories && categories.length > 0 ? categories.find((x: any) => x._id === catt)?.name || '' : ''}</h2>
                      {products && products.length > 0
                        ? <Slider {...productSettings} className="products-slider">
                          {
                            products && products.map((ele: any, index: number) => (
                              ele.categories.includes(catt) &&
                              <div className="product-item" key={index}>
                                <img src={require('assets/images/edit-icon.png')} className="topps edit" alt=""
                                  onClick={() => navigate(`/product/edit/${brandId}/${ele._id}`)} />
                                <Popconfirm
                                  title="Delete this product"
                                  description={`Are you sure to delete "${ele.name}"?`}
                                  onConfirm={(b) => confirm(ele._id)}
                                  onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <img src={require('assets/images/delete-icon.png')} className="topps delete" alt="" />
                                </Popconfirm>
                                <img src={ele.thumbnail} alt="*" />
                                <div className="info">
                                  <Link to={`/product/${ele._id}`} className="name">
                                    {limitStr(ele.name, 25)}
                                    <Popover
                                      content={<div className="pop-sub" dangerouslySetInnerHTML={{ __html: ele.description }} />}
                                      title={ele?.name} trigger="click">
                                      {' '}<InfoCircleOutlined />
                                    </Popover>
                                  </Link>
                                  <div className="description" dangerouslySetInnerHTML={{ __html: limitStr(ele.description, 66) }} />
                                  <span className="price">Price to Consumer: <span>{`£${ele.price}`}</span></span>
                                  {/* <span className="earn">Earn: {`£${ele.commission || 'N/A'}`}</span> */}
                                </div>
                                <div className="actions">
                                  <Button variant="primary" label="GENERATE CODE" />
                                  <Button variant="primary" onClick={() => {
                                    tracking(ele._id)
                                  }} label="get tracking link" />
                                  {/* eslint-disable-next-line */}
                                  <a target="_blank" href={ele?.orderUrl || 'javascript:;'} rel="noreferrer">
                                    <Button variant="primary"
                                      label="order" />
                                  </a>
                                  <Button
                                    variant="primary"
                                    label="explore products and tutorials"
                                    onClick={() =>
                                      navigate(`/product/${ele._id}`)
                                    }
                                  />
                                  {/* exploreUrl */}
                                </div>
                              </div>
                            ))
                          }
                        </Slider>
                        : <Empty />
                      }
                    </div>
                  )
                })
                }
              </>
              : <Empty />
            }
          </div>
        </section>
      </div>
    </SidebarLayout>
  )
}
