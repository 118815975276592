import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
// import { RouteComponentProps } from '@reach/router';
// import {I18nProvider} from '../_metronic/i18n/i18nProvider'
// import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {App_Routes} from './routing/Routes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={null}>
        <BrowserRouter basename={basename}>
              {/* <AuthInit> */}
              <App_Routes />
              {/* </AuthInit> */}

              <ToastContainer 
                progressClassName="toastProgress"
                bodyClassName="toastBody"
              />
        </BrowserRouter>
    </Suspense>
  )
}

export {App}
