import { useState, useEffect } from "react";
import { Dropdown, Checkbox, Radio, Input, Tooltip, Button as Btn } from "antd";
import {
  DownOutlined,
  CheckOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Field, Button } from "components/forms";
import { SidebarLayout } from "components/layout";
import { ProductCatalogue, EditField } from "components/Icons";
import {
  editSingleProduct,
  fetchAllData,
  uploadFile,
  addSingleProduct,
  getSingleProduct,
  getSingleBrand,
  getProductInfoExt,
  fetchProductMediaAll,
  deleteProductMediaAll,
} from "../_redux/products/Actions";
import { toast } from "react-toastify";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.scss";

// var items: any = [
//   {
//     label: 'All',
//     key: '1',
//     title: 'All'
//   },
//   {
//     label: 'Make up',
//     key: '2',
//     title: 'Make up'
//   },
//   {
//     label: 'Skincare',
//     key: '3',
//     title: 'Skincare'
//   },
// ];

export default function ProductEdit() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const productId: any = params?.productId;
  const brandId: any = params?.brandId;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setCategory] = useState("");
  const [consumerPrice, setConsumerPrice] = useState("");
  const [commission, setCommission] = useState("");
  const [, setVideoTitle] = useState("");
  const [trackingLink] = useState("");
  const [imagesBlob, setImagesBlob] = useState<any>([]);
  const [extId, setExtId] = useState("");
  const [extImages, setExtImages] = useState<any>([]);
  const [orderUrl, setOrderUrl] = useState("");
  const [exploreUrl, setExploreUrl] = useState("");
  const [productVideos, setProductVideos] = useState([]);
  const [tutsLink, setTutsLink] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [pumpFake, setPumpfake] = useState(1);
  const [tutsMedia] = useState<any>([]);
  const [productImages, setProductImages] = useState<any>([]);
  const [productExtImages, setProductExtImages] = useState<any>([]);
  const [productExtImagesBlob, setProductExtImagesBlob] = useState<any>([]);
  const [piUrl, setPiUrl] = useState<any>([]);
  const [fielders, setFielders] = useState([0]);
  const [toBeEdited, setToEdited] = useState(false);
  const [, setLoading] = useState<boolean>(true);
  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty()
  );
  const [items, setItems] = useState<any>([]);
  const [selected, setSelected] = useState<number>(0);

  const [crumbs, setCrumbs] = useState<any>([
    { label: "brands", to: "/products" },
    { label: "Add product", to: `/product/add/` },
  ]);

  const { categories, brandDetails, productDetails, productDetailsExt } =
    useSelector(
      (state: any) => ({
        actionsLoading: state.products.actionsLoading,
        categories: state.products.categories,
        brandDetails: state.products.brandDetails,
        productDetails: state.products.productDetails,
        productDetailsExt: state.products.productDetailsExt,
      }),
      shallowEqual
    );

  useEffect(() => {
    setExtImages([]);
    setEditorState(EditorState.createEmpty());
    setName("");
    setCategory("");
    setCommission("");
    setVideoTitle("");
    setConsumerPrice("");
    setProductUrl("");
    setOrderUrl("");
    setExploreUrl("");
    setProductVideos([]);
    setProductImages([]);
    dispatch(fetchAllData("categories", `?brand_id=${brandId}`));
    if (productId) {
      dispatch(getSingleProduct(productId));
    }
    if (brandId && !brandDetails) {
      dispatch(getSingleBrand(brandId));
    }
    if (location?.pathname.includes("edit") && productId) {
      setToEdited(true);
    } else {
      setToEdited(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productDetailsExt) {
      if (productDetailsExt.body_html) {
        const dbText: any = convertFromHTML(productDetailsExt?.body_html || "");
        const editorText = EditorState.createWithContent(dbText);
        setEditorState(editorText);
      }
      if (productDetailsExt.title) setName(productDetailsExt.title);
      if (productDetailsExt.new_order_url)
        setOrderUrl(productDetailsExt.new_order_url);
      if (productDetailsExt.new_order_url)
        setExploreUrl(productDetailsExt.new_order_url);
      if (productDetailsExt?.variants && productDetailsExt?.variants[0]) {
        if (brandDetails && brandDetails.name.toLowerCase() === "rose inc") {
          setConsumerPrice(
            Number(
              Math.round(productDetailsExt.variants[0].price / 1.2)
            ).toString()
          );
        } else {
          setConsumerPrice(productDetailsExt.variants[0].price);
        }
      }
      if (productDetailsExt.images) {
        setExtImages(productDetailsExt.images);
        convertUrlsToBlob(productDetailsExt.images);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetailsExt]);

  const getBlobFromUrl = async (url: string) => {
    const fileName = "myFile.jpg";
    let filey = null;
    await fetch(url).then(async (response) => {
      const blob = await response.blob();
      filey = new File([blob], fileName, {});
      return filey;
    });
    return filey;
  };

  const convertUrlsToBlob = (arr: any) => {
    const blobs: any = [];
    arr.map(async (el: any, index: number) => {
      const imgBlob = await getBlobFromUrl(el.src);
      blobs.push({ imageBlob: imgBlob, index: index });
      return true;
    });
    setImagesBlob(blobs);
  };

  const convertExtToBlob = (arr: any) => {
    const blobs: any = [];
    arr.map(async (el: any, index: number) => {
      const imgBlob = await getBlobFromUrl(el.src);
      blobs.push({ imageBlob: imgBlob, index: index });
      return true;
    });
    setProductExtImagesBlob(blobs);
  };

  useEffect(() => {
    var d: any = [];
    categories &&
      categories.forEach((element: any) => {
        var dd: any = {};
        dd["label"] = element.name;
        dd["title"] = element.name;
        dd["key"] = element._id;
        d.push(dd);
      });
    setItems(d);
    if (categories && brandDetails) {
      dispatch(fetchAllData("product", `?product_id=${brandDetails._id}`)).then(
        (dddd: any) => {
          if (dddd.success) {
            var cat = categories.find(
              (x: any) => x._id === dddd.success.categories[0]
            ).name;
            setName(dddd.success.name);
            setDescription(dddd.success.description);
            setCategory(cat);
            setConsumerPrice(dddd.success.price);
            setProductUrl(dddd.success.productUrl);
            setOrderUrl(dddd.success.orderUrl);
            setExploreUrl(dddd.success.exploreUrl);
            setCommission(dddd.success.commission);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    setCrumbs([
      { label: "brands", to: "/products" },
      { label: brandDetails?.name, to: `/brand/${brandDetails?._id}` },
      { label: "Add product", to: `/product/add/` },
    ]);
  }, [brandDetails]);

  useEffect(() => {
    if (productId) {
      if (productDetails?.name) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }

    if (productDetails && categories && productId && toBeEdited) {
      var cat = categories.find(
        (x: any) => x._id === productDetails.categories[0]
      ).name;

      setName(productDetails.name);
      const dbText: any = convertFromHTML(productDetails?.description || "");
      const editorText = EditorState.createWithContent(dbText);
      setEditorState(editorText);
      setDescription(productDetails.description);
      setCategory(cat);
      setOrderUrl(productDetails.orderUrl);
      setProductUrl(productDetails.productUrl);
      setExploreUrl(productDetails.exploreUrl);
      setCommission(productDetails.commission);
      setConsumerPrice(productDetails.price);
      setProductVideos(JSON.parse(productDetails?.product_videos || "[]"));

      dispatch(
        fetchProductMediaAll(`?type_id=${productDetails._id}&file_type=image`)
      ).then((res: any) => {
        convertExtToBlob(res.success);
        setProductExtImages(res.success);
        setPumpfake(pumpFake + 1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails, categories, productId, toBeEdited]);

  useEffect(() => {
    renderFields();
    setPumpfake(pumpFake + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fielders]);

  const fetchExternalProductDetails = () => {
    if (extId) {
      dispatch(getProductInfoExt(`?product_id=${extId}&brand_id=${brandId}`))
        .then(() => {})
        .catch((error: any) => {
          toast.error("Error occured when fetching product information");
          console.log("ERROR: ", error);
        });
    } else {
      toast.warn("Please enter a valid product Id");
    }
  };

  function deeeeeelet(file_id: string, ind: number) {
    dispatch(deleteProductMediaAll(`?file_id=${file_id}`)).then((res: any) => {
      if (res.success) {
        removeProdExtImg(ind); //
      }
    });
  }

  const handleProductImageChange = (e: any) => {
    if (e.target.files) {
      const eroes = productImages;
      const rls = piUrl;
      eroes.push(e.target.files[0]);
      setProductImages(eroes);
      setPumpfake(pumpFake + 1);
      const iurl = URL.createObjectURL(e.target.files[0]);
      rls.push(iurl);
      setPiUrl(rls);
      setPumpfake(pumpFake + 1);
    }
  };

  const renderFields = () => {
    return fielders.map((_: any, index: number) => (
      <div key={index}>
        <label htmlFor="brandLogo" className="upload-file smaller">
          <div className="check-hero">
            <Checkbox>hero image</Checkbox>
          </div>
          <div className="flex items-center justify-between">
            {productImages[index] && productImages[index]?.name ? (
              <>
                <span style={{ color: "#000", fontSize: "16px" }}>
                  {productImages[index].name}
                </span>
              </>
            ) : (
              <>
                <img src={require("assets/images/upload-plus.png")} alt="" />
                <span>UPLOAD FILE</span>
              </>
            )}
          </div>
          <div className="empty"></div>
        </label>
        <input
          id="brandLogo"
          type="file"
          accept="image/*"
          onChange={handleProductImageChange}
        />
      </div>
    ));
  };

  const handleMenuClick = (el: any) => {
    setCategory(el?.item?.props?.title);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const addNewField = () => {
    const cFields = fielders;
    cFields.push(fielders.length);
    setPumpfake(pumpFake + 1);
    setFielders(cFields);
  };

  const uploadImageHandler = (image: any, id: any, description?: string) => {
    const payload = new FormData();
    payload.append("file", image, image.name);
    payload.append("description", description || "");
    payload.append("type_id", id);
    payload.append("file_type", image?.type.split("/")[0]);
    dispatch(uploadFile(payload))
      .then((data: any) => {})
      .catch((error: any) => {
        console.log("error: ", error);
        toast.error("Error occured while saving screenshot");
      });
  };

  const uploadBlobHandler = (image: any, id: any, description?: string) => {
    const payload = new FormData();
    payload.append("file", image.imageBlob);
    payload.append("description", description || "");
    payload.append("type_id", id);
    payload.append("file_type", "image");
    dispatch(uploadFile(payload))
      .then((data: any) => {})
      .catch((error: any) => {
        console.log("error: ", error);
        toast.error("Error occured while saving screenshot");
      });
  };

  const addNewProduct = () => {
    let rawText: any = convertToHTML(editorState.getCurrentContent());
    if (
      !name ||
      !rawText ||
      !consumerPrice ||
      !orderUrl ||
      (!productImages && !imagesBlob)
    ) {
      return toast.error("All fields are compulsory");
    }
    if (selectedCategory) {
      var cat = categories.find((x: any) => x.name === selectedCategory)._id;
    } else {
      return toast.warn("Please select the product category to continue");
    }
    const payload = new FormData();
    payload.append("name", name);
    payload.append("description", rawText);
    payload.append("price", consumerPrice);
    payload.append(
      "variants",
      JSON.stringify(productDetailsExt?.variants || null)
    );
    payload.append("exploreUrl", exploreUrl);
    // payload.append('trackingLink', trackingLink);
    payload.append("orderUrl", orderUrl);
    payload.append("product_videos", JSON.stringify(productVideos));
    payload.append("brand_id", brandDetails?._id || brandId);
    payload.append("categories", cat);
    if (productImages && productImages.length > 0) {
      payload.append("thumbnail", productImages[selected || 0], productImages[selected || 0]?.name);
    } else if (imagesBlob && imagesBlob.length > 0) {
      payload.append("thumbnail", imagesBlob[selected || 0]["imageBlob"]);
    }
    // console.log(payload); return;
    dispatch(addSingleProduct(payload))
      .then((data: any) => {
        const productData = data?.success;
        if (productData?._id) {
          if (productImages && productImages.length > 0) {
            productImages.map((image: any, index: number) => {
              if (index === 0) {
                uploadImageHandler(image, productData._id, "hero");
              }
              uploadImageHandler(image, productData._id);
              return true;
            });
          }
          if (imagesBlob && imagesBlob.length > 0) {
            imagesBlob.map((image: any, index: number) => {
              if (index === 0) {
                uploadBlobHandler(image, productData._id);
              }
              uploadBlobHandler(image, productData._id);
              return true;
            });
          }
          if (tutsMedia && tutsMedia.length > 0) {
            tutsMedia.map((image: any) => {
              uploadImageHandler(image, productData._id);
              return true;
            });
          }
        }
        toast.success("Success");
        navigate(-1);
      })
      .catch((error: any) => {
        console.log("error: ", error);
        toast.error("Error occured while saving screenshot");
      });
  };

  const updateProduct = () => {
    let cat = "";
    let rawText: any = convertToHTML(editorState.getCurrentContent());
    if (!name || !description || !consumerPrice) {
      return toast.error("Please revalide the fields");
    }
    if (categories && selectedCategory) {
      cat = categories.find((x: any) => x.name === selectedCategory)._id;
    }
    const payload = new FormData();
    if (name) payload.append("name", name);
    if (description) payload.append("description", rawText || description);
    if (consumerPrice) payload.append("price", consumerPrice);
    if (commission) payload.append("commission", commission);
    if (exploreUrl) payload.append("exploreUrl", exploreUrl);
    if (trackingLink) payload.append("trackingLink", trackingLink);
    if (orderUrl) payload.append("orderUrl", orderUrl);
    if (productUrl) payload.append("productUrl", productUrl);
    if (productVideos)
      payload.append("product_videos", JSON.stringify(productVideos));
    if (cat) payload.append("categories", cat);
    if (productImages && productImages.length > 0) {
      payload.append("thumbnail", productImages[0], productImages[0]?.name);
    } else if (imagesBlob && imagesBlob.length > 0) {
      payload.append("thumbnail", imagesBlob[0]);
    }
    dispatch(editSingleProduct(payload, productId))
      .then((data: any) => {
        const productData = data?.success;
        console.log("productDataproductData", productData);
        if (productData?._id) {
          if (productImages && productImages.length > 0) {
            productImages.map((image: any, index: number) => {
              if (index === 0) {
                uploadImageHandler(image, productData._id, "hero");
              }
              uploadImageHandler(image, productData._id);
              return true;
            });
          }
          if (imagesBlob && imagesBlob.length > 0) {
            imagesBlob.map((image: any, index: number) => {
              if (index === 0) {
                uploadImageHandler(image?.imageBlob, productData._id);
              }
              uploadImageHandler(image?.imageBlob, productData._id);
              return false;
            });
            return true;
          }

          // if (productExtImagesBlob && productExtImagesBlob.length > 0) {
          //   productExtImagesBlob.map((image: any, index: number) => {
          //     if (index === 0) {
          //       uploadImageHandler(image?.imageBlob, productData._id);
          //     }
          //     uploadImageHandler(image?.imageBlob, productData._id);
          //   });
          // }

          if (tutsMedia && tutsMedia.length > 0) {
            tutsMedia.map((image: any) => {
              uploadImageHandler(image, productData._id);
              return true;
            });
          }
        }
        toast.success("Success");
        navigate(-1);
      })
      .catch((error: any) => {
        console.log("error: ", error);
        toast.error("Error occured while saving screenshot");
      });
  };

  const removeExtImg = (index: any) => {
    const imagesBlobC = [...imagesBlob];
    const extImagesC = [...extImages];
    imagesBlobC.splice(index, 1);
    extImagesC.splice(index, 1);
    setImagesBlob(imagesBlobC);
    setExtImages(extImagesC);
  };

  const removeProdExtImg = (index: any) => {
    const extBlobs = [...productExtImagesBlob];
    const extImgs = [...productExtImages];
    extBlobs.splice(index, 1);
    extImgs.splice(index, 1);
    setProductExtImagesBlob(extBlobs);
    setProductExtImages(extImgs);
  };

  const removeFileImg = (index: any) => {
    const imgs = [...piUrl];
    const prodCopy = [...productImages];
    const fieldersCopy = [...fielders];
    imgs.splice(index, 1);
    prodCopy.splice(index, 1);
    fielders.splice(index, 1);
    setPiUrl(imgs);
    setProductImages(prodCopy);
    setFielders(fieldersCopy);
  };

  const addVideoToUrls: any = () => {
    const urls: any = [...productVideos];
    urls.push(tutsLink);
    setProductVideos(urls);
    setTutsLink("");
    return false;
  };

  const removeLink = (index: number) => {
    const urls = [...productVideos];
    urls.splice(index, 1);
    setProductVideos(urls);
  };

  const preparePreview = () => {
    let rawText: any = convertToHTML(editorState.getCurrentContent());
    if (!name || !rawText || !consumerPrice || !orderUrl) {
      return toast.error("All fields are compulsory");
    }
    const payload: any = {
      brandDetails: brandDetails,
    };
    let cat = "";
    if (categories && selectedCategory) {
      cat = categories.find((x: any) => x.name === selectedCategory)._id;
    }
    if (name) payload["name"] = name;
    if (rawText) payload["description"] = rawText;
    if (consumerPrice) payload["price"] = consumerPrice;
    if (commission) payload["commission"] = commission;
    if (exploreUrl) payload["exploreUrl"] = exploreUrl;
    if (trackingLink) payload["trackingLink"] = trackingLink;
    if (orderUrl) payload["orderUrl"] = orderUrl;
    if (productUrl) payload["productUrl"] = productUrl;
    if (productVideos)
      payload["product_videos"] = JSON.stringify(productVideos);
    if (cat) payload["categories"] = cat;
    if (productImages && productImages.length > 0) payload['thumbnail'] = URL.createObjectURL(productImages[0]);
    if (extImages && extImages.length > 0) {
      const imgholder = [];
      extImages.map((img: any) => {
        imgholder.push(img.src);
        return true;
      });
      if (productImages && productImages.length > 0)
        imgholder.push(URL.createObjectURL(productImages[0]));
      payload["extImages"] = imgholder;
    }

    navigate("/product/preview", { state: payload });
  };

  const onRadioChange = (i: any) => {
    setSelected((prev) => (i === prev ? null : i));
    console.log(selected);
  };

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      <div className="products-container">
        <div className="product-form">
          <div className="title-section">
            <h2 className="ttle">
              {productDetails && productDetails._id && productId
                ? "Edit"
                : "Add"}{" "}
              product
            </h2>
            <Btn type="text" className="flex dorpdown" onClick={preparePreview}>
              <img
                src={require("assets/images/eye-see.png")}
                alt=""
                className="preview-eye"
              />
              SEE PREVIEW
            </Btn>
          </div>
          {!toBeEdited && (
            <div className="in-field">
              <span className="labelIcon">Product Id*</span>
              <Input.Group compact>
                <Input
                  style={{ width: "100%" }}
                  value={extId}
                  onChange={(e: any) => setExtId(e.target.value)}
                />
                <Tooltip title="Fetch product info">
                  <Btn onClick={fetchExternalProductDetails}>
                    <CheckOutlined style={{ color: "#FFF" }} />
                  </Btn>
                </Tooltip>
              </Input.Group>
            </div>
          )}
          <Field
            type="text"
            value={name}
            LabelIcon={EditField}
            onChange={(e: any) => setName(e.target.value)}
            label="Product name"
          />
          <div className="item">
            <label htmlFor="" className="copy-me">
              Product description
            </label>
            <Editor
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              toolbarClassName="toolbarClassName"
              editorStyle={{ minHeight: 200, padding: "10px" }}
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
          <div className="dorpdown">
            <span>Product Category</span>
            <Dropdown menu={menuProps} trigger={["click"]}>
              <div className="user">
                <span className="labele">{selectedCategory}</span>
                <DownOutlined color="#370E78" />
              </div>
            </Dropdown>
          </div>
          <Field
            type="number"
            required={true}
            value={consumerPrice}
            onChange={(e: any) => setConsumerPrice(e.target.value)}
            label="Product price to consumer"
          />
          <Field
            type="text"
            required={true}
            value={orderUrl}
            LabelIcon={EditField}
            onChange={(e: any) => setOrderUrl(e.target.value)}
            label="order url"
          />
          <Field
            type="text"
            required={true}
            value={exploreUrl}
            LabelIcon={EditField}
            onChange={(e: any) => setExploreUrl(e.target.value)}
            label="product explore url"
          />
          <div className="adding-up">
            <div className="item upload">
              <label htmlFor="" className="copy-me">
                product images (520p x 520p)
              </label>
              {renderFields()}
            </div>
            <Button
              label={
                <img
                  src={require("assets/images/plus.png")}
                  className="add-input-img"
                  alt=""
                />
              }
              onClick={addNewField}
              variant="secondary"
            />
            {piUrl && piUrl.length > 0 && (
              <div className="ext-images">
                {piUrl.map((img: any, index: number) => (
                  <div className="ext-img" key={index}>
                    <Btn onClick={() => removeFileImg(index)}>
                      <DeleteOutlined style={{ color: "#FFF" }} />
                    </Btn>
                    <img src={img} alt={"file"} />
                  </div>
                ))}
              </div>
            )}
            {productExtImages && productExtImages.length > 0 && (
              <div className="ext-images">
                {productExtImages.map((ele: any, index: number) => (
                  <div className="ext-img" key={index}>
                    <Btn
                      // onClick={() => removeProdExtImg(index)}

                      onClick={() => {
                        deeeeeelet(ele._id, index);
                        // removeExtImg(index)
                      }}
                    >
                      <DeleteOutlined style={{ color: "#FFF" }} />
                    </Btn>
                    <img src={ele.url} alt={ele.description || ""} />
                  </div>
                ))}
              </div>
            )}
            {extImages && extImages.length > 0 && (
              <div className="ext-images">
                {extImages.map((extIg: any, index: number) => (
                  <div className="ext-img" key={index}>
                    <Btn
                      onClick={() => {
                        // deeeeeelet(extIg._id, index)
                        removeExtImg(index);
                      }}
                    >
                      <DeleteOutlined style={{ color: "#FFF" }} />
                    </Btn>
                    <input
                      className="radio"
                      type="checkbox"
                      checked={index === selected}
                      onChange={() => onRadioChange(index)}
                    />

                    <img src={extIg.src} alt={extIg.alt || ""} />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mb-6"></div>
          <div className="in-field">
            <span className="labelIcon">Tutorial Videos (10mb MAX)</span>
            <Input.Group compact>
              <Input
                style={{ width: "100%" }}
                value={tutsLink}
                onChange={(e: any) => setTutsLink(e.target.value)}
              />
              <Tooltip title="Add Video URL">
                <Btn onClick={addVideoToUrls}>
                  <span className="ok-btn">OK</span>
                </Btn>
              </Tooltip>
            </Input.Group>
          </div>
          <div className="videos-list">
            {productVideos.map((url, index) => (
              <div className="vid-vid" key={index}>
                <span>{url}</span>
                <button onClick={() => removeLink(index)}>
                  <CloseOutlined />
                </button>
              </div>
            ))}
          </div>
          <div className="actions">
            <Button
              onClick={toBeEdited ? updateProduct : addNewProduct}
              variant="primary"
              label="SAVE CHANGES"
            />
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
}
