import { useState } from 'react'
import { Link } from 'react-router-dom';
import { Checkbox, Alert } from 'antd';
import AuthContainer from 'components/layout/AuthContainer'
import { Field, Button } from 'components/forms';
import * as auth from '../../redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { login } from '../../redux/AuthCRUD'

export default function Login() {
  const prop = {
    intro: 'Empower you the creator,  to monetise your content,  while effectively inspiring consumers to  make good, without compromise.',
    title: 'Login',
    subTitle: 'To access your account, please enter your email address and password provided by the Clean Creator Community team.'
  }

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [email, set_email] = useState('')
  const [password, set_password] = useState('')

  function subm() {
    // console.log('sdcsdcsadc'); return;
    setLoading(true);
    setStatus('');
    login({ email: email, password: password, type: 'admin' })
      .then((response) => {
        setLoading(false);
        var data = response.data;
        if (data.error) {
          setStatus(data.error);
        } else {
          dispatch(auth.actions.login(data.user_token));
        }
      })
      .catch((error) => {
        var message = error.response.data ? error.response.data.message : 'Network error occured!';
        setLoading(false);
        setStatus(message ? message : 'Network error occured!');
      });
  }

  return (
    <AuthContainer {...prop}>
      <form onSubmit={(e: any) => {
        e.PreventDefault();
        subm();
      }} className="auth-form">
        {status &&
          <Alert
            description={`Error! ${status}`}
            type="error"
            closable
            onClose={() => setStatus('')}
          />}<br></br>
        <Field type="email"
          // required = {true} 
          onChange={(e: any) => set_email(e.target.value)}
          label="Email address" placeholder="e.g. johndoe@email.com" />
        <Field type="password"
          onChange={(e: any) => set_password(e.target.value)}
          // required = {true} 
          label="Password" placeholder="" />
        <div className="flex items-center justify-between meta">
          <Checkbox>REMEMBER ME</Checkbox>
          <div className="forgot">
            <Link to="">Forgot password</Link>
          </div>
        </div>
        <div className="actions">
          <Button
            loading={loading}
            onClick={() => subm()} variant="primary" label="Login" />
          {/* <span className="or">OR</span>
          <Space direction="vertical" size="middle" className="w-100">
            <Button variant="secondary" Icon={Google} label="Sign In with Google" />
            <Button variant="secondary" Icon={Facebook} label="Sign In with Facebook" />
            <Button variant="secondary" Icon={Apple} label="Sign In with Apple" />
            <Button variant="plain" label="Don't have an account? Apply Now" />
          </Space> */}
        </div>
      </form>
    </AuthContainer>
  )
}
