import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SidebarLayout } from 'components/layout'
import { ProductCatalogue } from 'components/Icons'
import { toast } from 'react-toastify';
import { getSingleBrand, fetchAllData, reset } from '../_redux/products/Actions';
import bannerBg from "assets/images/banner-placeholder.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';
import { Button } from 'components/forms';


export default function CreatorPack() {
  const params = useParams();
  const brandId: any = params?.id;
  const dispatch = useDispatch<any>();
  const [bannerBgImage, setbannerBgImage] = useState<any>(bannerBg);
  const [, setParsedDesc] = useState<any>('');
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const { categories, products, brandDetails } = useSelector(
    (state: any) => ({
      actionsLoading: state.products.actionsLoading,
      products: state.products.products,
      brandDetails: state.products.brandDetails,
      categories: state.products.categories,
    }),
    shallowEqual
  );

  const crumbs = [
    { label: 'brands', to: '/products' },
    { label: `brands: ${brandDetails?.name}`, to: `/brand/${brandId}` },
  ];

  useEffect(() => {
    if (brandDetails) {
      if (brandDetails?.logo) {
        setbannerBgImage(`${brandDetails.banner || brandDetails.logo}`)
      }
      if (brandDetails?.description) {
        const parser = JSON.parse(brandDetails.description);
        setParsedDesc(parser)
      }
    }
  }, [brandDetails]);

  useEffect(() => {
    if (products && products.length > 0) {
      var a: any = []
      products.forEach((element: any) => {
        a.push(...element.categories)
      });
    } else {
    }
  }, [products]);

  useEffect(() => {
    dispatch(reset());
    if (brandId) {
      dispatch(getSingleBrand(brandId));
      dispatch(fetchAllData('products', `?brand_id=${brandId}&all=true`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var d: any = []
    var dd: any = {};
    dd['label'] = 'All';
    dd['title'] = 'All';
    dd['key'] = '';
    d.push(dd);

    categories && categories.forEach((element: any, index: number) => {
      var dd: any = {};
      dd['label'] = element.name;
      dd['title'] = element.name;
      dd['key'] = element._id;
      d.push(dd);
    });

  }, [categories])

  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('') + '...'
    }
    return newStr;
  }

  const updateList = (id: string) => {
    const selectsClone = [...selectedItems];
    if (selectsClone.includes(id)) {
      selectsClone.map((ele: any, index: number) => {
        if (ele === id) {
          selectsClone.splice(index, 1);
          setSelectedItems(selectsClone);
        }
        return true
      })
    } else {
      if (selectsClone.length >= 6) {
        toast.warn('Cannot select more than six item');
      } else {
        selectsClone.push(id);
        setSelectedItems(selectsClone);
      }
    }
  }

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      <div className="brand-details-container">
        <div className="banner" style={{ backgroundImage: `url(${bannerBgImage})` }}>
          <h1 className="brand-name">{brandDetails && brandDetails.name}</h1>
        </div>
        <div className="products-picks">
          {products && products.map((ele: any, index: number) => (
            <div className="check-product">
              {selectedItems.includes(ele._id) ? <img src={require('assets/images/checked.png')} className="checked-img" alt="" /> : ''}
              <label htmlFor={`${ele.name}-${index}`}>
                <img className="icon" src={ele.thumbnail} alt="" />
                <Link to={`/product/${ele._id}`} className="tt">{limitStr(ele.name, 22)}</Link>
                <div className="subtt" dangerouslySetInnerHTML={{ __html: limitStr(ele.description, 60) }} />
              </label>
              {selectedItems.includes(ele._id)
                ? <Button variant='plain' label="Remove" onClick={() => updateList(ele._id)} />
                : <Button variant='secondary' label="Add to list" onClick={() => updateList(ele._id)} />
              }
            </div>
          ))
          }
        </div>
      </div>
    </SidebarLayout>
  )
}
