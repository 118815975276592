import { Layout, Button } from "antd";
import { UpOutlined } from '@ant-design/icons';

const { Footer } = Layout;

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
export default function Foot() {
  return (
    <Footer className="footer-container">
      <p> ©{new Date().getFullYear()} The Clean Creator Community by Amyris. All Rights Reserved</p>

      <Button type="text" onClick={goToTop}>
        <UpOutlined />
        BACK TO TOP
      </Button>

    </Footer>
  )
}
