import { useState, useRef, useEffect } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Table, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Field } from 'components/forms';
import { getEventsAll } from '../../_redux/Actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Empty } from 'components/shared';


const menuItems2: any = [
  {
    label: 'All',
    key: '1',
    title: 'All'
  },
  {
    label: 'PENDING',
    key: '2',
    title: 'PENDING'
  },
  {
    label: 'COMPLETED',
    key: '3',
    title: 'COMPLETED'
  },
];


export default function Disputes(props: any) {
  console.log('props: ', props);
  const dispatch = useDispatch<any>();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [selectedCategory, setCategory] = useState('All')
  const [q, setQ] = useState('');
  const [revDisputes, setRevDisputes] = useState<any>([]);
  const handleMenuClick = (el: any) => {
    setCategory(el?.item?.props?.title)
    var val = el?.item?.props?.title;
    if (val === 'All') {
      dispatch(getEventsAll('disputes'));
    } else {
      dispatch(getEventsAll('disputes', `?q=${val.toLowerCase()}`));
    }

  }

  const { disputes } = useSelector(
    (state: any) => ({
      disputes: state.community.disputes,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEventsAll('disputes'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (disputes && disputes.length > 0) {
      const revv: any = [...disputes];
      if (revv && revv.length > 0) {
        revv.reverse();
      }
      setRevDisputes(revv);
    }
  }, [disputes])

  const initSearch = () => {
    if (q !== '') {
      dispatch(getEventsAll('disputes', `?q=${q}`));
    } else {
      dispatch(getEventsAll('disputes'));
    }
  }


  const menuProps2 = {
    items: menuItems2,
    onClick: handleMenuClick,
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Full name',
      dataIndex: 'name',
      render: (text: string, full: any) => {
        return (<Link className="table-namey" to={`/community/dispute/${full._id}`}>
          {full.first_name} {full.last_name}
        </Link>
        )
      },
    },
    {
      title: 'TOPIC & ASSIGNEE',
      dataIndex: 'dispute' || 'assignee',
      render: (text: string, full: any) => {
        return <div className="tr-main-sub">
          <span className="main">{full.topic}</span>
          {/* <span className='sub'>{full.assignee}</span> */}
        </div>
      },
    },
    {
      title: 'PRIORITY LEVEL',
      dataIndex: 'priority',
      render: (text: string, full: any) => {
        return `${full.priority}`
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (text: string, full: any) => {
        return (
          <div className={`vrf-status ${full.status === 'completed' ? 'approved' : full.status}`}>
            <span>&#8226;{full.status}</span>
          </div>
        )
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      <div className="header-section">
        <h2 className="section-title">Disputes</h2>
        {/* <button className="btn btn-img" onClick={() => navigate('/community/booking/new')}>
          <img src={require('assets/images/upload-plus.png')} alt="" />
          <span>Edit Form</span>
        </button> */}
      </div>
      <div className="search-content">
        <Field placeholder="e.g. First Session" type="text" label="Search" onChange={(e) => setQ(e.target.value)} onPressEnter={initSearch} />
        <div className="dorpdown">
          <span>FILTER: Dispute</span>
          <Dropdown menu={menuProps2} trigger={['click']}>
            <div className="user">
              <span className="labele">{selectedCategory}</span>
              <DownOutlined color="#370E78" />
            </div>
          </Dropdown>
        </div>
      </div>
      {disputes && disputes.length > 0
        ? <>
          {windowSize?.current[0] >= 768 &&
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              dataSource={revDisputes}
            // rowKey="_id"
            />
          }
          {windowSize?.current[0] < 768 &&
            <div className="mobile-list-view">
              {disputes && disputes.length > 0 &&
                disputes.map((booking: any, index: number) => (
                  <Link to={`/community/booking/${booking._id}`} className="mobile-l-item" key={index}>
                    <div className="label-value">
                      <span className="label">FULL name</span>
                      <span className="value name">{`${booking.first_name} ${booking.last_name}`}</span>
                    </div>
                    <div className="label-value">
                      <span className="label">Topic</span>
                      <span className="value">{booking.topic}</span>
                    </div>
                    <div className="label-value">
                      <span className="label">Advisor</span>
                      <span className="value">{booking.advisor}</span>
                    </div>
                    <div className="label-value">
                      <span className="label">Date & time</span>
                      <span className="value">{booking.datetime}</span>
                    </div>
                    <div className="label-value">
                      <span className="label">Link</span>
                      <span className="value">{booking.link}</span>
                    </div>
                  </Link>
                ))
              }
            </div>
          }
        </>
        : <Empty />
      }
    </>
  )
}
