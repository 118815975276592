/* eslint-disable */

export function number_format(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}



export function abvNum(n) {

    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";


}


export function isValidFirstname(name) {
    if (
        typeof name !== "string" ||
        /[0-9]+/g.test(name)
    ) {
        return false;
    }
    return true;
}

export function validateEmail(text) {
    if (!text) {
        return false;
    }
    if (text.length < 3) {
        return false;
    }
    
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
        return false;
    } else {
        return true;
    }
}

export function validatePassword(text) {
    if (!text) {
        return false;
    }
    // let reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    // let reg = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/;
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.?@(+_)~#$%\^&\*])(?=.{8,})/;
    if (reg.test(text) === false) {
        return false;
    } else {
        return true;
    }

    // return /[A-Z]/.test(text) && /[0-9]/.test(text) && !/[aeiou]/.test(text) && /^[@#][A-Za-z0-9]{7,13}$/.test(text);

}

export function isUpperCase(string) {
    var re = /(?=.*[A-Z])/;
    return re.test(string)
}

export function isNumeric(string) {
    var re = /(?=.*\d)/;
    return re.test(string)
}

export function isSpecial(string) {
    var re = /(?=.*[!#$%&?@^*() "])/;
    return re.test(string)
}

export function validatePhone(text) {
    return text.match(/\d/g).length == 10 || text.match(/\d/g).length == 11;
}

export function randomString(length = 10, chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ") {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function roundNumber(num, scale = 2) {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}


