import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'creators') {
    return axios.get(`${URL}auth/user/all${queryParams}`);
  } else if (type === 'search_creators') {
    return axios.get(`${URL}auth/user/search${queryParams}`);
  } else if (type === 'filter') {
    return axios.get(`${URL}api/campaign/filter${queryParams}`);
  } else if (type === 'starter') {
    return axios.get(`${URL}api/product/users/starter_pack${queryParams}`);
  } else if (type === 'welcome_pack') {
    return axios.get(`${URL}api/product/user/welcome_pack${queryParams}`);
  }
}


export function getCreatorsCount(q) {
  return axios.get(`${URL}auth/user/all?count=1&${q && 'status='+q}`);
}

export function editDetails(body, id, type) {
  if (type === 'toggle_activation') {
    return axios.put(`${URL}auth/user/toggle_activate`, body);
  } else if (type === 'links') {
    return axios.post(`${URL}api/campaign/links`, body);
  } else if (type === 'welcome_pack') {
    return axios.put(`${URL}auth/user/create_order`, body);
  } else {
    return axios.put(`${URL}api/campaign/default?campaign_id=${id}`, body);
  }
}

export function fetchCreatorDetails(id) {
  return axios.get(`${URL}auth/user/single?user_id=${id}`);
}

export function fetchSocialsAll(id) {
  return axios.get(`${URL}api/Socials/all?creator_id=${id}`);
}
