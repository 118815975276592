import { useState, useEffect } from 'react'
import { Radio } from 'antd';
import { useDispatch } from "react-redux";
import { PartnerAnalytics as Pnp } from 'components/Icons';
import { useParams } from 'react-router-dom';
import { SidebarLayout } from 'components/layout'
import { PayoutHistory, CreatorAnalytics, PasswordManagement, PersonalInformation, PaymentInformation } from './meta';
import { getSingleCreator } from "../../verifications/_redux/verifications/Actions";
import './styles.css'
import './styles.scss';

export default function CreatorManagementOverview() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [tab, setTab] = useState('personalInformation');
  const [creator, setCreator] = useState<any>(null);
  const [crumbs, setCrumbs] = useState([{ label: '/ Partner\'s Ranking', to: '/partner-analytics' }])

  useEffect(() => {
    dispatch(getSingleCreator(creatorId)).then((res: any) => {
      setCreator(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId])

  useEffect(() => {
    if (tab === 'personalInformation') {
      setCrumbs([{ label: `/ Partner's Ranking / PARTNER: ${creator && creator?._id && `${creator.first_name} ${creator.last_name}`}: PERSONAL INFORMATION`, to: '/partner-analytics' }])
    }
    if (tab === 'paymentInformation') {
      setCrumbs([{ label: `/ Partner's Ranking / PARTNER: ${creator && creator?._id && `${creator.first_name} ${creator.last_name}`}: PAYMENT INFORMATION`, to: '/partner-analytics' }])
    }
    if (tab === 'creatorAnalytics') {
      setCrumbs([{ label: `/ Partner's Ranking / PARTNER: ${creator && creator?._id && `${creator.first_name} ${creator.last_name}`}: CREATOR ANALYTICS`, to: '/partner-analytics' }])
    }
    if (tab === 'payoutHistory') {
      setCrumbs([{ label: `/ Partner's Ranking / PARTNER: ${creator && creator?._id && `${creator.first_name} ${creator.last_name}`}: PAYOUT HISTORY`, to: '/partner-analytics' }])
    }
  }, [tab, creator]);


  return (
    <SidebarLayout
      headerTitle="Partner Analytics"
      headerIcon={Pnp}
      crumbs={crumbs}
    >
      <div className="analytics-menu">
        <div className="menu-group lg-5">
          <Radio.Group onChange={(e: any) => setTab(e.target.value)} defaultValue={tab}>
            <Radio.Button value="personalInformation">PERSONAL INFORMATION</Radio.Button>
            {/* <Radio.Button value="accountSettings">ACCOUNT SETTINGS</Radio.Button> */}
            <Radio.Button value="paymentInformation">PAYMENT INFORMATION</Radio.Button>
            <Radio.Button value="passwordManagement">PASSWORD MANAGEMENT</Radio.Button>
            <Radio.Button value="creatorAnalytics">CREATOR ANALYTICS</Radio.Button>
            <Radio.Button value="payoutHistory">Payout History</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <section>
        {tab === 'personalInformation' && <PersonalInformation />}
        {/* {tab === 'accountSettings' && <AccountSetting />} */}
        {tab === 'paymentInformation' && <PaymentInformation />}
        {tab === 'passwordManagement' && <PasswordManagement />}
        {tab === 'creatorAnalytics' && <CreatorAnalytics />}
        {tab === 'payoutHistory' && <PayoutHistory />}
        {/* {tab === 'help' && <Disputes menuItems={menuItems} disputes={disputes} />} */}
      </section>

    </SidebarLayout>
  )
}
