import { useState, useEffect } from 'react'
import { Skeleton } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import '../styles.scss';
import { getSingleCreator } from "../../../verifications/_redux/verifications/Actions";
import { fetchAllData } from "../../../partneranalytics/_redux/Actions";
import { TrendStat } from 'components/shared';
import { Chart } from "react-google-charts";
import { Button } from 'components/forms';

// var earningSummary: any = [];

// const trends = [
//   { label: 'Total # Orders', value: '5.839', rate: '1.2%', pve: true },
//   { label: 'Total Revenue', value: '$4.060,893', rate: '2.3%', pve: true },
//   { label: 'Total Commissions', value: '$789.093', rate: '4.1%', pve: true },
//   { label: 'AOV', value: '$37.80', rate: '1.6%', pve: true },
//   { label: 'Avg. return rate', value: '4.6%', rate: '5.3%', nve: true },
//   { label: 'Avg. CVR', value: '5.6%', rate: '1.6%', pve: true },
//   { label: 'Total Commissions', value: '$789.093', rate: '4.1%', pve: true },
//   { label: 'Total Revenue', value: '$4.060,893', rate: '2.3%', pve: true },
// ]

// export const audienceData = [
//   ["Products", "Brand Sales", { role: 'style' }],
//   ["Biossance", 9900, "color: #6863AB"],
//   ["JVN", 6950, "color: #62A9E3"],
//   ["Rose ", 5260, "color: #7D9DCA"],
// ];
export const audienceOptions = {
  title: "",
  chartArea: { width: "75%" },
  legend: "none",
  bar: { groupWidth: "70%" },
  hAxis: {
    title: "",
    minValue: 0,
    textPosition: 'none',
    gridlines: { color: 'transparent' },
  },
  vAxis: {
    title: "",
    textStyle: {
      fontSize: 48
    }
  },
};

export default function CreatorAnalytics() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const navigate = useNavigate();
  const creatorId: any = params?.id;
  const [loading] = useState<boolean>(false);
  const [data, set_data] = useState<any>(null);
  const [data_brands, set_data_brands] = useState<any>(null);
  const [audienceData, set_audienceData] = useState<any>([]);
  const [, setCrumbs] = useState([{ label: '', to: '/' }])
  const [earningSummary, set_earningSummary] = useState<any>(
    [
      { label: 'Lifetime earnings', value: '-', rate: '1.2%', pve: true },
      { label: 'Cash out balance', value: '-', rate: '2.3%', pve: true },
    ]
  );
  const [trends, set_trends] = useState<any>([
    { label: 'Total # Orders', value: '-', rate: '1.2%', pve: true },
    { label: 'Total Revenue', value: '-', rate: '2.3%', pve: true },
    { label: 'Total Commissions', value: '-', rate: '4.1%', pve: true },
    { label: 'AOV', value: '-', rate: '-', pve: true },
    { label: 'Avg. return rate', value: '-', rate: '5.3%', nve: true },
    { label: 'Avg. CVR', value: '-', rate: '-', pve: true },
    { label: 'Total Commissions', value: '-', rate: '4.1%', pve: true },
    { label: 'Total Revenue', value: '-', rate: '2.3%', pve: true },
  ]);

  const { creatorDetails } = useSelector(
    (state: any) => ({
      creatorDetails: state.verifications.creatorDetails,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators

    }),
    shallowEqual
  );
  const [user, set_user] = useState<any>(null);


  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([{ label: ' / Profile: ' + creatorDetails.first_name + ' ' + creatorDetails.last_name, to: `/creator/${creatorDetails._id}` }])
    }
  }, [creatorDetails])


  useEffect(() => {
    dispatch(getSingleCreator(creatorId)).then((res: any) => {
      // console.log(res)
      set_user(res);
    });


    var q = `creator_id=${creatorId}`;

    dispatch(fetchAllData('sum_analytics', q)).then((res: any) => {
      if (res.success) {
        set_data(res.success)
      }
    });

    dispatch(fetchAllData('data_by_brands', q)).then((res: any) => {
      if (res.success) {
        console.log(res.success);
        set_data_brands(res.success)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId])


  useEffect(() => {
    if (data) {
      var earningSummary = [
        { label: 'Lifetime earnings', value: data ? `GBP ${data?.lifetime_earning.toFixed(2)}` : "-" },

        //   { label: 'Lifetime earnings', value: 

        //   loading
        //     ? "-"
        //     : data && data?.lifetime_earning.length > 0
        //     ? data?.lifetime_earning
        //         .map(
        //           (data: any) =>
        //             `${data.currency} ${data.Total.toFixed(2)}`
        //         )
        //         .join(", ")
        //     : "N/A"
        // },
        {
          label: 'Cash out balance', value:
            loading
              ? "-"
              : data && data?.balance.length > 0
                ? data?.balance
                  .map(
                    (data: any) =>
                      `${data.currency} ${data.Total.toFixed(2)}`
                  )
                  .join(", ")
                : "N/A"
        }
      ]

      var t = [
        { label: 'Total # Orders', value: data ? data?.total_orders : "-" },
        {
          label: 'Total Revenue', value:
            loading
              ? "-"
              : data && data?.total_sales.length > 0
                ? data?.total_sales
                  .map(
                    (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                  )
                  .join(", ")
                : "N/A"
        },
        {
          label: 'Total Commissions', value:
            loading
              ? "-"
              : data && data?.total_commissions.length > 0
                ? data?.total_commissions
                  .map(
                    (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                  )
                  .join(", ")
                : "N/A"
        },
        { label: 'AOV', value: "-" },
        { label: 'Avg. return rate', value: data ? data?.avg_return : "-" },
        { label: 'Avg. CVR', value: data ? data?.cvr : "-" },
        { label: 'Total Clicks', value: data ? data?.clicks : "-" },
        { label: 'Returns', value: data ? data?.returns : "-" },

      ]

      set_earningSummary(earningSummary)
      set_trends(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])


  useEffect(() => {
    if (data_brands) {
      var arr = [["Products", "Brand Sales", { role: 'style' }]];

      data_brands.forEach((element: any) => {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);
        var r: any = [element.brand_name, element.totalSales, `color: #${randomColor}`];
        // r.push([z]);
        arr.push(r);
      });

      // console.log("Arr", arr);
      set_audienceData(arr);

    }
  }, [data_brands])

  return (
    <div className="creator-mgt-container">
      {loading || !user
        ? <Skeleton />
        :
        <>
          <div className="creator-details">
            <div className="mask-data">
              <img src={user?.picture || require('assets/images/avatar-bg.png')} alt="*" className="avatar" />
              <span className="creator-name">{user?.first_name}{" "}{user?.last_name}</span>
              <div className="location">
                <span>{`${user?.city}, ${user?.country}`}</span>
              </div>
              <Button variant="plain" label="view analytics dashboard" onClick={() => navigate(`/partner-analytics/creator/${creatorId}/dashboard`)} />
            </div>
          </div>
          <section className="metadata">
            <h2 className="section-title">Earning summary</h2>
            <div className="around">
              {earningSummary.map((suma: any, index: number) => (
                <TrendStat {...suma} key={index} />
              ))}
            </div>
          </section>
          <section className="metadata">
            <h2 className="section-title">Sales Funnel Performance</h2>
            <div className="lg-four">
              {trends.map((suma: any, index: number) => (
                <TrendStat {...suma} key={index} />
              ))}
            </div>
          </section>

          <section className="metadata">
            <h2 className="section-title">Sales by Brand</h2>
            <div className="charts-grid">
              {audienceData && audienceData.length > 1 &&
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height={480}
                  data={audienceData}
                  options={audienceOptions}
                />}
            </div>
          </section>
        </>
      }
    </div>
  )
}
