
function isJson(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default function About({ data }: any) {
  if (!data) {
    return null;
  }
  return (
    <div>
      <div className="data-pairs about">
        <div className="label-value">
          <span className="label">Have you been a part of any affiliate programs before?</span>
          <span className="value">{data?.affiliate?.toUpperCase()}</span>
        </div>
        <div className="label-value">
          <span className="label">Which programmes are you partnering with?</span>
          <span className="value">{data.programs.join(", ")}</span>
        </div>
      </div>
      <div className="data-pairs">
        <div className="label-value">
          <span className="label">What is the average commission you earned?</span>
          <span className="value">{`${data.currency?.toUpperCase()} ${data?.average_commission || 0}`}</span>
        </div>
        <div className="label-value">
          <span className="label">What is your content type?</span>
          <span className="value">{(isJson(data.content_type) ? JSON.parse(data.content_type) : []).join(", ")}</span>
        </div>
      </div>
      <div className="data-pairs">
        <div className="label-value">
          <span className="label">What type of products would you like to promote?</span>
          {/* <span className="value">{JSON.parse(data.products_to_promote || '[]').join(", ")}</span> */}
          <span className="value">{(isJson(data.products_to_promote) ? JSON.parse(data.products_to_promote) : []).join(", ")}</span>
        </div>
        <div className="label-value">
          <span className="label">Audience main locations</span>
          <span className="value">{(isJson(data.audience) ? JSON.parse(data.audience) : []).join(", ")}</span>
          {/* <span className="value">{data.audience || 'N/A'}</span> */}
        </div>
      </div>
    </div>
  )
}
