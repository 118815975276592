import { useState, useEffect } from 'react'
import { Rate } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SidebarLayout } from 'components/layout';
import { ProductCatalogue } from 'components/Icons';
import { getSingleProduct } from '../_redux/products/Actions';
import { useParams } from 'react-router-dom';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './styles.scss';


export default function ProductReviews() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const productId: any = params?.id;

  const [crumbs, setCrumbs] = useState<any>([
    { label: 'brands', to: '/products' },
  ])

  const { brandDetails, productDetails } = useSelector(
    (state: any) => ({
      actionsLoading: state.products.actionsLoading,
      categories: state.products.categories,
      brandDetails: state.products.brandDetails,
      productDetails: state.products.productDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (productId) {
      dispatch(getSingleProduct(productId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (brandDetails && productDetails) {
      setCrumbs([
        { label: 'brands', to: '/products' },
        { label: brandDetails?.name, to: `/brand/${brandDetails._id}` },
        { label: productDetails.name, to: `/product/${productDetails._id}` },
        { label: "Reviews", to: `/product/${productDetails._id}` },
      ])
    }
  }, [brandDetails, productDetails])

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      <div className="reviews-container">
        {Array(12).fill('dummy').map((el, idx) => (
          <div className="review">
            <Rate
              disabled
              allowHalf
              defaultValue={5}
            />
            <span className="username">Anonymous</span>
            <p className="content">A hydrating skin tint that delivers powerful skincare and sheer, radiant coverage</p>
            <span className="datetime">01/01/2023 03;15PM</span>
          </div>
        ))}
      </div>
    </SidebarLayout>
  )
}
