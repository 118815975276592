import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  actionsLoading: false,
  listLoading: false,
  error: false,
  creators: null,
  creatorDetails: null,
  creatorSocialsAll: null,
  creatorsCount: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const VerificationSlice = createSlice({
  name: "verifications",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reset: (state, action) => {
      state = initialdatasState;
    },
    clearCreatorsList: (state, action) => {
      state.creators = null;
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    creatorsList: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.creators = action.payload;
    },
    DatasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { id, verification_status } = action.payload;
      state.creators = state.creators && state.creators.map(entity => {
        if (id === entity._id) {
          entity.verification_status = verification_status;
        }
        return entity;
      });
    },
    creatorDetails: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.creatorDetails = action.payload;
    },
    creatorSocialsAll: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.creatorSocialsAll = action.payload;
    },
    creatorsCount: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.creatorsCount = action.payload;
    },
  }
}); 
