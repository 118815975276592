/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { useState, useEffect, ChangeEvent } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';
import { Skeleton, Dropdown, DatePickerProps, DatePicker, TimePicker, Checkbox, Button as Btn } from 'antd';
import { Field, Button } from 'components/forms';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SidebarLayout } from 'components/layout';
import { ContentHub } from 'components/Icons';
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import formatISO from 'date-fns/formatISO'
import { getSingleEvent, editSingleEvent, reset, createEvent, uploadFile } from '../_redux/Actions';
import { fetchAllData as fetchData } from '../../products/_redux/products/Actions';;
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './styles.scss';


const timezones: any = [
  {
    label: 'GMT +1',
    key: '0',
    title: 'GMT +1'
  },
  {
    label: 'GMT +2',
    key: '2',
    title: 'GMT +2'
  },
  {
    label: 'GMT +3',
    key: '3',
    title: 'GMT +3'
  },
  {
    label: 'GMT +4',
    key: '4',
    title: 'GMT +4'
  },
  {
    label: 'GMT +5',
    key: '5',
    title: 'GMT +5'
  },
];

export default function EventEdit() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const eventId: any = params?.id;
  const navigate = useNavigate();
  const [title, setTitle] = useState('')
  const [date, updateDate] = useState<any>('')
  const [time, setTime] = useState<any>('')
  const [timeString, setTimeString] = useState('');
  const [dateStr, updateDateStr] = useState('');
  const [timezone, setTimezone] = useState('')
  const [eventcta, setEventcta] = useState('')
  const [location, setLocation] = useState('')
  const [capacity, setCapacity] = useState('');
  const [spotlight, setSpotlight] = useState<boolean>(false);
  const [logo, setLogo] = useState<any>(null);
  const [brand, setBrand] = useState('Select brand');
  const [brandId, setBrandId] = useState(null);
  const [loading, setLoading] = useState(false)
  const [imgUrl, setImgUrl] = useState('');
  const [keyedBrands, setKeyedBrands] = useState<any>(null);
  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());


  const { eventDetails, brands } = useSelector(
    (state: any) => ({
      eventDetails: state.community.eventDetails,
      brands: state.products.brands,
    }),
    shallowEqual
  );

  const crumbs = [
    { label: 'getting you started', to: '/community' },
    { label: `EDIT Event`, to: `/event/` },
  ];

  useEffect(() => {
    dispatch(fetchData('brands'));
    if (eventId) {
      dispatch(getSingleEvent(eventId));
    } else {
      dispatch(reset());
    }
  }, []);

  useEffect(() => {
    const items = [];
    if (brands && brands.length > 0) {
      brands.map((brand: any) => {
        items.push({
          label: brand?.name,
          key: brand?._id,
          title: brand?.name,
        })
      })
      setKeyedBrands(items);
    }
  }, [brands])

  useEffect(() => {
    if (eventId) {
      if (eventDetails?.title) {
        setLoading(false)
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }

    if (eventDetails) {
      const dbText: any = convertFromHTML(eventDetails?.description || '');
      const editorText = EditorState.createWithContent(dbText);
      setEditorState(editorText);
      setTitle(eventDetails.title);
      setCapacity(eventDetails.capacity);
      setLocation(eventDetails.location);
      updateDate(eventDetails.date);
      setTime(eventDetails.time);
      setSpotlight(eventDetails?.spotlight || false);
      setImgUrl(eventDetails.banner);
    } else {
      setEditorState(EditorState.createEmpty());
      setTitle('');
    }
  }, [eventDetails])

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    if (e.target.files) {
      setLogo(e.target.files);
      const url = URL.createObjectURL(e.target.files[0]);
      setImgUrl(url);
    }
  };

  const handleMenuClick = (el: any) => {
    setBrand(el?.item?.props?.title)
    setBrandId(el?.key)
  }

  const handletimezoneClick = (el: any) => {
    setTimezone(el?.item?.props?.title)
  }

  const onDateChange: DatePickerProps['onChange'] = (date: any, dateString) => {
    updateDate(date.$d)
    updateDateStr(dateString)
  };

  const onTimeChange: DatePickerProps['onChange'] = (time: any, timeString) => {
    setTimeString(timeString);
    setTime(time.$d);
  };

  const menuProps = {
    items: keyedBrands,
    onClick: handleMenuClick,
  };

  const timezoneProps = {
    items: timezones,
    onClick: handletimezoneClick,
  };

  const uploadImageHandler = (image: any, id: any, description?: string) => {
    const payload = new FormData();
    payload.append('file', image, image.name);
    payload.append('description', description || '');
    payload.append('type_id', id);
    payload.append('file_type', image?.type.split('/')[0]);
    dispatch(uploadFile(payload)).then((data: any) => {
    }).catch((error: any) => {
      console.log('error: ', error);
      // toast.error('Error occured while saving screenshot');
    })
  }

  // pass brandId to get event for a particulat brand
  const addNewEvent = () => {
    if (!title || !editorState.getCurrentContent() || !location || !logo || !timeString || !dateStr || !capacity) return toast.error('All fields are compulsory');
    let rawText: any = convertToHTML(editorState.getCurrentContent());
    const payload = new FormData();
    payload.append('brand_id', brandId);
    payload.append('title', title);
    payload.append('description', rawText);
    payload.append('date', formatISO(new Date([dateStr, timeString])));
    payload.append('capacity', capacity);
    if (spotlight) payload.append('spotlight', spotlight);
    payload.append('cta', eventcta);
    payload.append('location', location);
    payload.append('banner', logo[0], logo[0].name);
    setLoading(true);
    dispatch(createEvent(payload)).then((data: any) => {
      const eventData = data.success;
      toast.success('Success');
      navigate('/community');
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('Error occured while creating event');
    });
  }

  const updateEvent = () => {
    let rawText: any = convertToHTML(editorState.getCurrentContent()) || '';
    const payload = new FormData();
    payload.append('event_id', eventDetails._id);
    payload.append('brand_id', brandId);
    if (title) payload.append('title', title);
    payload.append('description', rawText);
    if (dateStr && timeString) payload.append('date', dateStr && timeString ? formatISO(new Date([dateStr, timeString])) : dateStr || new Date());
    payload.append('capacity', capacity);
    if (spotlight) payload.append('spotlight', spotlight);
    payload.append('location', location);
    if (logo && logo[0] && logo[0].name) payload.append('banner', logo[0], logo[0]?.name)
    setLoading(true);
    dispatch(editSingleEvent(payload)).then((data: any) => {
      if (data.success) {
        toast.success('Success');
        navigate(-1);
      } else {
        toast.warn('Please retry your request');
      }
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('Error updating event');
    })
  }

  const checkedBox = (e: CheckboxChangeEvent) => {
    setSpotlight(e.target.checked);
  }

  return (
    <SidebarLayout
      headerTitle="Content hub"
      headerIcon={ContentHub}
      crumbs={crumbs}
    >
      <div className="content-hub-container">
        {loading
          ? <Skeleton />
          : <div className="product-form">
            <div className="title-section">
              <h2 className="ttle">{eventId ? 'Edit Event' : 'Add Event'}</h2>
              <div className="dorpdown preview">
                SEE PREVIEW
              </div>
            </div>
            <div className="flex items-center justify-between spotlight">
              <Checkbox onChange={checkedBox}>spotlight event</Checkbox>
            </div>
            <div className="dorpdown">
              <span>Brand</span>
              <Dropdown menu={menuProps} trigger={['click']}>
                <div className="user">
                  <span className="labele">{brand}</span>
                  <DownOutlined color="#370E78" />
                </div>
              </Dropdown>
            </div>
            <Field type="text" required={true}
              onChange={(e: any) => setTitle(e.target.value)}
              label="Event title"
              value={title}
            />
            <div className="item">
              <label htmlFor="" className="copy-me">Event Description</label>
              <Editor
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbarClassName="toolbarClassName"
                editorStyle={{ minHeight: 200, padding: '10px' }}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>
            <Field type="number" required={true}
              onChange={(e: any) => setCapacity(e.target.value)}
              label="Event Capacity"
              value={capacity}
            />
            <Field type="text" required={true}
              onChange={(e: any) => setLocation(e.target.value)}
              label="Event Location"
              value={location}
            />
            <div className="pick-me-picker">
              <label htmlFor="date">Event date</label>
              <DatePicker
                onChange={onDateChange}
                suffixIcon={<DownOutlined />}
              />
            </div>
            <div className="pick-me-picker">
              <label htmlFor="time">Event time</label>
              <TimePicker
                placeholder="Select Time"
                format={'HH:mm'}
                onChange={onTimeChange}
                suffixIcon={<DownOutlined />}
              />
            </div>
            <Field type="text" required={true}
              onChange={(e: any) => setEventcta(e.target.value)}
              label="Event call-to-action"
              value={eventcta}
            />
            <div className="item upload mb-6">
              <label htmlFor="" className="copy-me">Upload image</label>
              <label htmlFor="brandLogo" className="upload-file">
                {logo && logo.length > 0
                  ? <>
                    <span style={{ color: '#000', fontSize: '16px' }}>{logo[0].name}</span>
                  </>
                  : <>
                    <img src={require('assets/images/upload-plus.png')} alt="" />
                    <span>UPLOAD FILE</span>
                  </>
                }
              </label>
              <input id="brandLogo" type="file" accept="image/*" onChange={handleLogoChange} />
            </div>
            {imgUrl &&
              <div className="ext-images">
                <div className="ext-img">
                  <Btn onClick={() => setImgUrl('')}>
                    <DeleteOutlined style={{ color: '#FFF' }} />
                  </Btn>
                  <img src={imgUrl} alt={'file'} />
                </div>
              </div>
            }
            <div className="actions">
              <Button onClick={eventId ? updateEvent : addNewEvent} variant="primary"
                label={eventId ? "Save Changes" : "Add Event"}
              />
            </div>
          </div>
        }
      </div>
    </SidebarLayout>
  )
}
