import { Button as AntButton } from 'antd';

// Clicking a button will trigger corresponding business logic
interface ButtonProps {
  label: any; // required
  // !required
  Icon?: any;
  // Click Event handler - !required
  onClick?: any;
  //  Handles activities in progress - !required
  loading?: boolean;
  // 3 non-required properties allowed for button component - It default to an ordinary button
  variant?: 'primary' | 'secondary' | 'plain';
}

export default function Button({ loading, variant,onClick, label, Icon }: ButtonProps) {

  return (
    <AntButton
      loading = {loading}
      onClick = {onClick}
      icon={Icon && <Icon />}
      className={`cta ${variant}`}>
      {label}
    </AntButton>
  )
}
