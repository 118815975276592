import { useState, useEffect } from 'react'
import { Skeleton, Radio } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { SidebarLayout } from 'components/layout'
import { Check, Cross } from 'components/Icons';
import { CreatorVerification } from 'components/Icons'
import { getSingleCreator, editDetails, fetchAllData } from "../_redux/verifications/Actions";
import { Info, Connects, About, WelcomePack } from './meta';
import './styles.scss';
import { toast } from 'react-toastify';

export default function CreatorDetails() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [loading, setLoading] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [tab, setTab] = useState('info');
  const [crumbs, setCrumbs] = useState([{ label: '', to: '/' }])
  const [products, setProducts] = useState<any>(null);
  const [welcome_orders, setOrders] = useState<any>(null);

  const { creatorDetails, creators } = useSelector(
    (state: any) => ({
      creatorDetails: state.verifications.creatorDetails,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators

    }),
    shallowEqual
  );


  // const onChange = (e: RadioChangeEvent) => {
  //   console.log(`radio checked:${e.target.value}`);
  // };

  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([{ label: ' / Profile: ' + creatorDetails.first_name + ' ' + creatorDetails.last_name, to: `/creator/${creatorDetails._id}` }])
    }
  }, [creatorDetails])

  useEffect(() => {
    if (creatorId) {
      dispatch(getSingleCreator(creatorId));
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId, creators]);


  useEffect(() => {
    dispatch(fetchAllData('starter', `?user_id=${creatorId}`)).then((data: any) => {
      if (data.success) {
        setProducts(data.success);
      }
    });

    dispatch(fetchAllData('welcome_pack', `?user_id=${creatorId}`)).then((data: any) => {
      console.log(data)
      if (data.orders) {
        setOrders(data.orders);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // console.log('creatorDetails: ', creatorDetails);


  const send_status = (st: any, act: string) => {
    var stats: any = {};
    stats['user_id'] = st;
    stats['status'] = act;
    dispatch(editDetails(stats, '', 'toggle_activation')).then(() => {
      dispatch(getSingleCreator(creatorId))
    })
  }

  useEffect(() => {
    if (creatorDetails && creatorDetails._id) {
      setLoading(false);
    }
  }, [creatorDetails])



  const triggerWelcome = (st: any) => {
    var stats: any = {};
    setLoading2(true);
    stats['user'] = st;
    dispatch(editDetails(stats, '', 'welcome_pack')).then((res: any) => {
      setLoading2(false);
      dispatch(getSingleCreator(creatorId))
      if (res.success) {
        toast.success(`${res.success}!`);
      } else {
        toast.error(`${res?.error || 'Something went wrong'}!`);
      }
    }).catch((error: any) => {
      console.log(error);
      setLoading2(false);
    })
  }

  return (
    <SidebarLayout
      headerTitle="creator verification"
      headerIcon={CreatorVerification}
      crumbs={crumbs}
    >
      {loading || !creatorDetails?._id
        ? <Skeleton />
        :
        <>
          <div className="creator-details">
            <div className="mask-data">
              <img src={creatorDetails?.picture || require('assets/images/avatar-bg.png')} alt="*" className="avatar" />
              <span className="creator-name">{creatorDetails.first_name}{" "}{creatorDetails.last_name}</span>
              <div className="flex">
                <div className={`vrf-status ${creatorDetails.verification_status}`}>
                  <span>&#8226; {creatorDetails.verification_status}</span>
                </div>
                {
                  creatorDetails.order_placed ?
                  <div className={`vrf-status approved`} style = {{marginLeft: 20, maxWidth: 200}}>
                    <span style = {{width: 1000}}>&#10003; WP Order Placed</span>
                  </div>
                  :
                  <button onClick = {() => loading2 ? console.log('fff') : triggerWelcome(creatorDetails._id)} className={`vrf-status rejected`} style = {{marginLeft: 20, maxWidth: 500, borderWidth: 0}}>
                    <span>{!loading2 ? 'Click to trigger welcome pack' : 'Loading, please wait...'}</span>
                  </button>
                }
              </div>

              
              {/* <span>&#8226;{'Order Placed'}</span> */}
             
              <div className="flex">
                <button onClick={() => send_status(creatorDetails._id, 'approve')} className="action-btn check">
                  <Check />
                </button>
                <button onClick={() => send_status(creatorDetails._id, 'reject')} className="action-btn cross">
                  <Cross />
                </button>
              </div>
              <div className="menu-group">
                <Radio.Group onChange={(e: any) => setTab(e.target.value)} defaultValue={tab}>
                  <Radio.Button value="info">Personal Information</Radio.Button>
                  <Radio.Button value="connect">CONNECT CHANNELS</Radio.Button>
                  <Radio.Button value="about">ABOUT YOU</Radio.Button>
                  <Radio.Button value="pack">WELCOME PACK</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="info">
            {tab === 'info' && <Info creatorDetails={creatorDetails} />}
            {tab === 'connect' && <Connects />}
            {tab === 'about' && <About data={creatorDetails} />}
            {tab === 'pack' && <WelcomePack products={products} welcome_orders = {welcome_orders} data={creatorDetails} />}
          </div>
        </>
      }
    </SidebarLayout>
  )
}
