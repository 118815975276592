import { useState, useEffect } from 'react'
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import '../styles.scss';
// import { getSingleCreator } from "../../_redux/Actions";
import { fetchAllData } from "../../_redux/Actions";
import * as product_actions from "../../../products/_redux/products/Actions";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";


const discoverer = {
  first_name: 'SOPZN',
  last_name: 'COKER',
  location: 'New York City, USA',
  EngagementRate: 22.4,
  nofPosts: 140,
  avgViews: 234,
};

const filterer: any = {
  'Brand': [
    { label: 'JVN', title: 'JVN', key: "brand0" },
    { label: 'Bissonance', title: 'Bissonance', key: "brand1" },
    { label: 'Rose Inc', title: 'Rose Inc', key: "brand2" },
  ],
  "Country": [
    { label: 'All', title: 'all', key: "all" },
    { label: 'United Kingdom', title: 'United Kingdom', key: "United Kingdom" },
    // { label: 'United States', title: 'United States', key: "usa" },
    // { label: 'Nigeria', title: 'Nigeria', key: "nigeria" },
  ],
  "City": [
    { label: 'All', title: 'all', key: "all" },
    {
      "label": "London",
      "title": "London",
      "key": "london"
    },
    {
      "label": "Birmingham",
      "title": "Birmingham",
      "key": "birmingham"
    },
    {
      "label": "Glasgow",
      "title": "Glasgow",
      "key": "glasgow"
    },
    {
      "label": "Liverpool",
      "title": "Liverpool",
      "key": "liverpool"
    },
    {
      "label": "Leeds",
      "title": "Leeds",
      "key": "leeds"
    },
    {
      "label": "Sheffield",
      "title": "Sheffield",
      "key": "sheffield"
    },
    {
      "label": "Edinburgh",
      "title": "Edinburgh",
      "key": "edinburgh"
    },
    {
      "label": "Bristol",
      "title": "Bristol",
      "key": "bristol"
    },
    {
      "label": "Manchester",
      "title": "Manchester",
      "key": "manchester"
    },
    {
      "label": "Cardiff",
      "title": "Cardiff",
      "key": "cardiff"
    }
  ],
  "Device": [
    { label: 'Mobile', title: 'Mobile', key: "device0" },
    { label: 'Tablet', title: 'Tablet', key: "device2" },
    { label: 'Desktop', title: 'Desktop', key: "device1" },
  ],
}

export default function ProgrammePerformance() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const [, setCrumbs] = useState([{ label: '', to: '/' }])
  const [selectedCategory,] = useState('All')
  const [date, set_date] = useState<any>(null);
  const [brand, set_brand] = useState<any>(null);
  const [country, set_country] = useState<any>(null);
  const [city, set_city] = useState<any>(null);
  const [brands_filter, set_brand_filters] = useState<any>([]);
  const [, setTrends] = useState<any>([
    { label: 'Total # Orders', value: '5.839', rate: '1.2%', pve: true },
    { label: 'Total Revenue', value: '$4.060,893', rate: '2.3%', pve: true },
    { label: 'Total Commissions', value: '$789.093', rate: '4.1%', pve: true },
    { label: 'AOV', value: '$37.80', rate: '1.6%', pve: true },
    { label: 'Avg. return rate', value: '4.6%', rate: '5.3%', nve: true },
    { label: 'Avg. CVR', value: '5.6%', rate: '1.6%', pve: true },
  ])

  const filtererMenu: any = {
    'Brand': { items: brands_filter, onClick: (el: any) => set_brand(el.key) },
    "Country": { items: filterer['Country'], onClick: (el: any) => set_country(el.key) },
    "City": { items: filterer['City'], onClick: (el: any) => set_city(el.key) },
    "Device": { items: filterer['Device'], onClick: (el: any) => console.log("ele clcik", el) },
  }


  const { creatorDetails, brands, summary } = useSelector(
    (state: any) => ({
      summary: state.analytics.summary,
      brands: state.products.brands,
      creatorDetails: state.verifications.creatorDetails,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators
    }),
    shallowEqual
  );

  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([{ label: ' / Profile: ' + creatorDetails.first_name + ' ' + creatorDetails.last_name, to: `/creator/${creatorDetails._id}` }])
    }
  }, [creatorDetails])

  useEffect(() => {
    // if (creatorId && !creatorDetails) {
    //   dispatch(getSingleCreator(creatorId));
    // } else {
    //   // setLoading(true);
    // }
  }, [creatorId, creatorDetails]);

  useEffect(() => {
    if (creatorDetails && creatorDetails._id) {
      setLoading(false);
    }
  }, [creatorDetails])


  useEffect(() => {
    if (brands && brands_filter.length === 0) {
      var brands_f = [...brands_filter];
      var bdd: any = {};
      bdd['label'] = 'All';
      bdd['title'] = 'All';
      bdd['key'] = '';
      brands_f.push(bdd)

      brands.forEach((ddd: any) => {
        var bdd: any = {};
        bdd['label'] = ddd.name;
        bdd['title'] = ddd.name;
        bdd['key'] = ddd._id;
        brands_f.push(bdd)
      })
      set_brand_filters(brands_f);
    }
  }, [brands, brands_filter])


  useEffect(() => {
    dispatch(product_actions.fetchAllData('brands'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var q = '?';
    if (date) {
      q += `start=${date.startDate}&end=${date.endDate}&`;
    }
    if (brand) {
      q += `brand_id=${brand}&`;
    }
    if (country) {
      if (country === 'all') {
        q += ``;
      } else {
        q += `country=${country}&`;
      }
    }

    if (city) {
      if (city === 'all') {
        q += ``;
      } else {
        q += `city=${city}&`;
      }
    }

    dispatch(fetchAllData('summary', `${q}`))
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, brand, country, city])


  useEffect(() => {
    if (summary) {
      var t = [];
      var total_orders: any = {};
      total_orders['label'] = "Total # Orders";
      total_orders['value'] = summary?.total_sales.length > 0
        ? summary?.total_sales
          .map(
            (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
          )
          .join(", ")
        : "N/A";
      total_orders['rate'] = "";
      total_orders['pve'] = true;
      t.push(total_orders)
      setTrends(t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary])


  function getByLabelText(filterer: any) {
    if (filterer === 'brand') {
      if (!brand) {
        return 'All';
      } else {
        var brrr = brands.filter((obj: any) => {
          return obj._id === brand;
        })
        return brrr[0].name
      }
    } else if (filterer === 'country') {
      return country || 'All';
    } else if (filterer === 'city') {
      return city || 'All';
    }
    return selectedCategory;
  }

  return (
    <div className="analytics-container">
      {loading || !discoverer
        ? <Skeleton paragraph={{ rows: 10 }} />
        :
        <>
          <div className="search-picker">
            <div className="search">
              {/* <Field placeholder="e.g. John" type="text" label="Search" onChange={() => console.log('change')} /> */}
            </div>
            {/* <div className="picker">
              <RangePicker placement="bottomLeft" />
            </div> */}
            <div className="" style={{ width: 250 }}>
              <p className="hclabel">Date range</p>
              <div className="analyselection__cnt">
                <div className="analysection_cnt">
                  <DateRangePicker
                    onApply={(a, b) => {
                      set_date({
                        startDate: b.startDate.format("YYYY-MM-DD"),
                        endDate: b.endDate.format("YYYY-MM-DD"),
                      });
                      // handleSubmit();
                    }}
                    initialSettings={{
                      startDate: "1/1/2023",
                      endDate: new Date(),
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        backgroundColor: "transparent",
                        width: "100%", borderWidth: 0
                      }}
                    />
                  </DateRangePicker>
                  {/* <img
                      className="vblsc_right"
                      style={{ paddingLeft: 20 }}
                      src={dropdown}
                      alt=""
                    /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="filterer">
            {Object.keys(filterer).map((el, index) => (
              <div className="dorpdown" key={index}>
                <span>{el}</span>
                <Dropdown menu={filtererMenu[el]} trigger={['click']}>
                  <div className="user">
                    <span className="labele">{getByLabelText(el.toLowerCase())}</span>
                    <DownOutlined color="#370E78" />
                  </div>
                </Dropdown>
              </div>
            ))}
          </div>
          {/* <div className="engagements-trends"> */}
          {/* {trends.map((trend, index) => (
              <TrendStat {...trend} key={index} />
            ))} */}
          {
            summary &&
            <div className='engagements-trends'>
              <div className="trend-data">
                <div className="label">{'Total # Orders'}</div>
                <div className="value">{loading
                  ? "-"
                  : summary
                    ? summary?.total_orders
                    : "N/A"}
                </div>
                <div className="trend">
                  {/* {pve && <img src={require('assets/images/trend-up.png')} />}
                  {nve && <img src={require('assets/images/trend-down.png')} />} */}
                  {/* <div className={`${nve && 'nve'} ${pve && 'pve'} rate`}>{rate}</div> */}
                </div>
              </div>

              <div className="trend-data">
                <div className="label">{'Total Revenue'}</div>
                <div className="value">{loading
                  ? "-"
                  : summary && summary?.total_sales.length > 0
                    ? summary?.total_sales
                      .map(
                        (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                      )
                      .join(", ")
                    : "N/A"}
                </div>
                <div className="trend">
                </div>
              </div>

              <div className="trend-data">
                <div className="label">{'Return/Cancellation'}</div>
                <div className="value">{loading
                  ? "-"
                  : summary && typeof summary?.cancellation == 'object' && summary?.cancellation.length > 0
                    ? summary?.cancellation
                      .map(
                        (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                      )
                      .join(", ")
                    : summary && typeof summary?.cancellation == 'string'
                      ? `${summary?.cancellation}`
                      : "N/A"
                }
                </div>
                <div className="trend">
                </div>
              </div>

              <div className="trend-data">
                <div className="label">{'Total Commissions'}</div>
                <div className="value">{loading
                  ? "-"
                  : summary && summary?.total_commissions.length > 0
                    ? summary?.total_commissions
                      .map(
                        (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                      )
                      .join(", ")
                    : "N/A"}
                </div>
                <div className="trend">
                </div>
              </div>

              {/* <div className="trend-data">
                <div className="label">{'Total Commissions'}</div>
                <div className="value">    {summary ? summary?.total_orders : "-"}
                </div>
                <div className="trend">
                </div>
              </div> */}

              <div className="trend-data">
                <div className="label">{'Avg. return rate'}</div>
                <div className="value">{summary ? summary?.avg_return : "-"}
                </div>
                <div className="trend">
                </div>
              </div>

              <div className="trend-data">
                <div className="label">{'Clicks'}</div>
                <div className="value">    {summary ? summary?.clicks : "-"}
                </div>
                <div className="trend">
                </div>
              </div>


            </div>



          }
          {/* </div> */}
        </>
      }
    </div>
  )
}
