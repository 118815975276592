import { useState, useEffect, ChangeEvent } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton, Button as Btn } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { EditorState } from 'draft-js';
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { Field, Button } from 'components/forms';
import { SidebarLayout } from 'components/layout';
import { ProductCatalogue } from 'components/Icons';
import { getSingleBrand, editBrandDetails, reset, createBrand } from '../_redux/products/Actions';
import './styles.scss';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { object } from 'prop-types';


export default function BrandEdit() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const brandId: any = params?.id;
  const navigate = useNavigate();
  const [descriptionHeader, setDescriptionHeader] = useState('')
  const [name, setName] = useState('')
  const [logo, setLogo] = useState<any>(null);
  const [headerMedia, setHeaderMedia] = useState<any>(null);
  const [loading, setLoading] = useState(false)
  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());
  const [crumbs, setCrumbs] = useState([{ label: 'brands', to: '/products' }])
  const [blob, setBlob] = useState<any>('');

  const { brandDetails } = useSelector(
    (state: any) => ({
      brandDetails: state.products.brandDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (brandId) {
      dispatch(getSingleBrand(brandId));
    } else {
      dispatch(reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (brandId) {
      if (brandDetails?.name) {
        setLoading(false)
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }

    if (brandDetails) {
      setCrumbs([
        { label: 'brands', to: '/products' },
        { label: `brands: ${brandDetails?.name}`, to: `/brand/${brandId}` },
        { label: `Edit Brand`, to: `/brand/${brandId}/edit/` },
      ])
      const dbText: any = convertFromHTML(JSON.parse(brandDetails?.description || ''));
      const editorText = EditorState.createWithContent(dbText);
      setEditorState(editorText);
      setName(brandDetails.name);
      setLogo(brandDetails.logo);
      setHeaderMedia(brandDetails.banner);
      setDescriptionHeader(brandDetails.header_description);

    } else {
      setCrumbs([
        { label: 'brands', to: '/products' },
        { label: `Add brand`, to: `/brand/new` },
      ])
      setEditorState(EditorState.createEmpty());
      setName('');
      setDescriptionHeader('');
      setLogo(null);
      setHeaderMedia(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDetails])

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    if (e.target.files) {
      setLogo(e.target.files);
    }
  };
  const parseVideo = (vid : any) => {
    let contents = vid;
    var uint8Array  = new Uint8Array(contents);
   
    var arrayBuffer = uint8Array.buffer;
    var blob        = new Blob([arrayBuffer]);
    setBlob(blob) ;
  }
  const handleHeaderMediaChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    if (e && e?.target?.files[0].size > 10240000) {
      alert("File is too big!");
      return;
    };

    const file = e.target.files[0];
    setHeaderMedia(file);
    
    // if (e.target.files) {
    //   setHeaderMedia(e.target.files);
    //   console.log( "flight" + {headerMedia})
    // }
    // if(typeof e.target.flies === 'object') {
    //   console.log(e.target.flies)
    //   // parseVideo(e.target.files)
    // }
  };
  
  const addNewBrand = () => {
    if (!name || !editorState.getCurrentContent() || !logo) return toast.error('All fields are compulsory');
    let rawText: any = JSON.stringify(convertToHTML(editorState.getCurrentContent()));
    const payload = new FormData();
    payload.append('name', name)
    payload.append('header_description', descriptionHeader)
    payload.append('description', rawText)
    payload.append('logo', logo[0], logo[0]?.name)
    payload.append(headerMedia.type.startsWith('video/') ? 'video' : 'banner', headerMedia, headerMedia?.name)
    setLoading(true);
    dispatch(createBrand(payload)).then((data: any) => {
      toast.success('Success! Successfully created new brand.');
      navigate('/products');
      window.location.reload();
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('Error occured editted brand');
    });
  }

  const updateBrand = () => {
    // console.log(typeof headerMedia === 'object' && headerMedia[0].type === ("video/*"));
    // return;
    if (!name || !editorState.getCurrentContent()) return toast.error('All fields are compulsory');
    let rawText: any = JSON.stringify(convertToHTML(editorState.getCurrentContent()));
    const payload = new FormData();
    payload.append('name', name)
    payload.append('header_description', descriptionHeader)
    payload.append('description', rawText)
    if (logo && logo[0] && logo[0].name) payload.append('logo', logo[0], logo[0]?.name)
    if (headerMedia && headerMedia && headerMedia.name) payload.append(headerMedia.type.startsWith('video/') ? 'video' : 'banner', headerMedia, headerMedia?.name)
    // console.log(rawText)
    setLoading(true);
    dispatch(editBrandDetails(payload, brandId)).then((data: any) => {
      if (data.success) {
        toast.success('Success! Succesfully editted brand details.');
        navigate(-1);
      } else {
        toast.warn('Please retry your request');
      }
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('Error occured editted brand');
    })
  }

  const preparePreview = () => {
    let rawText: any = convertToHTML(editorState.getCurrentContent());
    if (!name || !rawText) {
      return toast.error('All fields are compulsory')
    };
    if (!headerMedia || headerMedia.length === 0) return toast.error('Add a brand header image')
    const payload: any = {}
    if (name) payload['name'] = name;
    if (rawText) payload['description'] = rawText;
    if (descriptionHeader) payload['header_description'] = descriptionHeader;
    if (logo && logo?.name) payload['logo'] = URL.createObjectURL(logo);
    if (headerMedia && headerMedia.length > 0 && headerMedia[0]?.name) payload['banner'] = URL.createObjectURL(headerMedia[0]);

    navigate('/brand/preview', { state: payload })
  }

  const removeLogo = () => setLogo(null);
  const removeBanner = () => setHeaderMedia(null);

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      <div className="products-container">
        {loading
          ? <Skeleton />
          : <div className="product-form">
            <div className="title-section">
              <h2 className="ttle">{brandId ? 'Edit brand' : 'Add brand'}</h2>
              <div className="dorpdown" onClick={preparePreview}>
                <img src={require('assets/images/eye-see.png')} alt="" className="preview-eye" />
                SEE PREVIEW
              </div>
            </div>
            <Field type="text" required={true}
              onChange={(e: any) => setName(e.target.value)}
              label="Brand name"
              value={name}
            />
            <Field type="text" required={true}
              onChange={(e: any) => setDescriptionHeader(e.target.value)}
              label="brand description - header"
              value={descriptionHeader}
            />
            <div className="item">
              <label htmlFor="" className="copy-me">brand description - body</label>
              <Editor
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbarClassName="toolbarClassName"
                editorStyle={{ minHeight: 200, padding: '10px' }}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>
            <div className="item upload mb-6">
              <label htmlFor="" className="copy-me">brand logo (520p x 520p)</label>
              <label htmlFor="brandLogo" className="upload-file">
                {logo && logo.length > 0
                  ? <>
                    <span style={{ color: '#000', fontSize: '16px' }}>{logo[0].name}</span>
                  </>
                  : <>
                    <img src={require('assets/images/upload-plus.png')} alt="" />
                    <span>UPLOAD FILE</span>
                  </>
                }
              </label>
              <input id="brandLogo" type="file" accept="image/*" onChange={handleLogoChange} />
              <div className="ext-images">
                {logo &&
                  <div className="ext-img">
                    <Btn onClick={removeLogo}>
                      <DeleteOutlined style={{ color: '#FFF' }} />
                    </Btn>
                    <img src={typeof logo == 'string' ? logo : URL.createObjectURL(logo[0])} alt={logo?.alt || ''} />
                  </div>}
              </div>
            </div>
            <div className="item upload mb-6">
              <label htmlFor="" className="copy-me">brand header image/video (IMAGE: 520p x 520p, video: 10mb MAX)</label>
              <label htmlFor="headerImage" className="upload-file">
                {headerMedia && headerMedia.length > 0
                  ? <>
                    <span style={{ color: '#000', fontSize: '16px' }}>{headerMedia[0].name}</span>
                  </>
                  : <>
                    <img src={require('assets/images/upload-plus.png')} alt="" />
                    <span>UPLOAD FILE</span>
                  </>
                }
              </label>
              <input id="headerImage" type="file" accept="video/*,image/*" onChange={handleHeaderMediaChange} />
              <div className="ext-images">
                {headerMedia &&
                  <div className="ext-img">
                    <Btn onClick={removeBanner}>
                      <DeleteOutlined style={{ color: '#FFF' }} />
                    </Btn>
                    {typeof headerMedia === 'object' && headerMedia.type.startsWith('video/') ? <video width="320" height="240" controls>
                        <source src={URL.createObjectURL(headerMedia)} type="video/mp4"/>
                      </video>
                      : 
                      <img src={typeof headerMedia == 'string' ? headerMedia : URL.createObjectURL(headerMedia)} alt={headerMedia?.alt || ''} />
                   }
                  </div>}
              </div>
            </div>
            <div className="actions">
              <Button onClick={brandId ? updateBrand : addNewBrand} variant="primary" label="SAVE CHANGES" />
            </div>
          </div>
        }
      </div>
    </SidebarLayout>
  )
}
