import { useState, useEffect, useRef } from 'react'
import { message, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SidebarLayout } from 'components/layout'
import { ProductCatalogue } from 'components/Icons'
import Slider from "react-slick";
import { toast } from 'react-toastify';
import * as actions from "../_redux/products/Actions";
import { useNavigate } from 'react-router-dom';
import { deleteBrand } from '../_redux/products/Actions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';
import { Empty } from 'components/shared';


const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={require('assets/images/next-icon.png')} alt="" />
    </div>
  );
}

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={require('assets/images/prev-icon.png')} alt="" />
    </div>
  );
}

const settings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};


export default function ProductsCatalogue() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const { listLoading, brands, cats, actionsLoading } = useSelector(
    (state: any) => ({
      actionsLoading: state.products.actionsLoading,
      listLoading: state.products.listLoading,
      brands: state.products.brands,
      cats: state.products.categories
    }),
    shallowEqual
  );

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    dispatch(actions.fetchAllData('brands'));
    dispatch(actions.fetchAllData('categories'));
  }, []);

  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';

  const crumbs = [
    { label: '/ Brands', to: '/products' },
  ]

  const confirm = (el: React.MouseEvent<HTMLElement> | any) => {
    console.log(el);
    setLoading(true);
    dispatch(deleteBrand(el._id)).then((data: any) => {
      toast.success('Deleted successfully');
      dispatch(actions.fetchAllData('brands'));
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('An error occured. Please retry later.');
    })
  };

  const cancel = (e: React.MouseEvent<HTMLElement> | any) => {
    console.log(e);
  };

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      {contextHolder}
      <div className="products-container">
        <section className="section">
          <div className="header-section">
            <h2 className="section-title">Brands</h2>
            <button className="btn btn-img" onClick={() => navigate('/brand/new')}>
              <img src={require('assets/images/upload-plus.png')} alt="" />
              <span>ADD BRAND</span>
            </button>
          </div>
          {brands && brands.length > 0 ?
            <Slider {...settings}>
              {
                brands.map((brand: any, index: number) => (
                  <div className="single-slide" key={index}>
                    <img src={require('assets/images/edit-icon.png')} className="action edit" alt=""
                      onClick={() => navigate(`/brand/edit/${brand._id}`)} />
                    <Link to={`/brand/${brand._id}`} key={index}>
                      <img src={brand.logo} alt="*" className="brand-img" />
                    </Link>
                    <Popconfirm
                      title="Delete the brand"
                      description="Are you sure to delete this brand?"
                      onConfirm={(b) => confirm(brand)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <img src={require('assets/images/delete-icon.png')} className="action delete" alt="" />
                    </Popconfirm>
                  </div>
                ))}
              {windowSize?.current[0] >= 768 &&
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </>
              }
            </Slider>
            : <Empty />
          }
        </section>
      </div>
    </SidebarLayout>
  )
}
