import React, { useState, useEffect } from 'react';
import { Menu, Layout } from 'antd';
import Logo from 'components/Logo';
import { menuItems } from './data';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
const { Sider } = Layout;




export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const items = () => (
    menuItems.map((menu, index) => ({
      key: String(index),
      icon: React.createElement(menu.icon),
      label: menu.label,
      onClick: () => navigate(menu.to)
    }))
  )
  //'/discovery',
  const routes = ['/', '/partner-analytics', '/products', '/community', '/payouts', '/content', '/onboard-products'];
  const [index, set_index] = useState<any>(0);

  useEffect(() => {
    console.log('location.pathname: ', location.pathname);
    if (routes.includes(location.pathname)) {
      // console.log(routes.indexOf(location.pathname));
      set_index(routes.indexOf(location.pathname))
    } else {
      // set_index(index)
      set_index(routes.length + 1)
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Sider
      className="sidebar-container"
      breakpoint="lg"
      collapsedWidth="80"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <Link to='/' className="logo">
        <Logo />
      </Link>
      <Menu
        mode="inline"
        // defaultSelectedKeys={['0']}
        selectedKeys={[index.toString()]}
        items={items()}
      />
    </Sider>
  )
}
