import { useRef, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { shallowEqual, useSelector } from "react-redux";
import { HamburgerMenu } from 'components/Icons';
import { useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Dropdown, Layout, Drawer } from 'antd';
import { menuItems } from './data';


interface HeaderProps {
  title?: string;
  Icon?: any;
}


const { Header } = Layout;


export default function TopHeader(props: HeaderProps) {
  const navigate = useNavigate();
  const [drawerState, toggleDrawerState] = useState<boolean>(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const { title, Icon } = props;

  const items: MenuProps['items'] = [
    {
      label: <span>User Profile</span>,
      key: '0',
      onClick: () => navigate('/profile'),
    },
    {
      type: 'divider',
    },
    {
      label: <span> LOGOUT</span>,
      key: '3',
      onClick: () => navigate('/logout'),
      danger: true,
    },
  ];

  const navigateTo = (item: any) => {
    toggleDrawerState(false);
    navigate(item.to);
  }

  const { user } = useSelector(
    (state: any) => ({
      user: state.auth.user.user
    }),
    shallowEqual
  );

  return (
    <>
      {windowSize?.current[0] < 992 &&
        <>
          <div className="mobile-top-bar">
            <div className="logo">
              {/* <Logo /> */}
              <img src={require('assets/images/logo.png')} alt="" width="72%" />
            </div>
            <button className="mmenu" onClick={() => toggleDrawerState(true)}>
              <HamburgerMenu />
            </button>
          </div>
          <Drawer
            placement="right"
            onClose={() => toggleDrawerState(false)} open={drawerState}
            title={<img src={require('assets/images/logo-white.png')} alt="" className="drawer-logo" />}
            closeIcon={<img src={require('assets/images/close-white.png')} alt="X" className="close-icon" />}
            className="drawer-container"
          >
            {menuItems.map((item, index) => (
              <span className="mobile-link-item" key={index} onClick={() => navigateTo(item)}>{item.label}</span>
            ))}
          </Drawer>
        </>
      }
      <Header className="header-container">
        <div className="title-icon">
          <div className="icon">
            {Icon && <Icon />}
          </div>
          <span className='title'>{title || ''}</span>
        </div>
        <div className="menu-section">
          <button className="notification">
            <img src={require('assets/images/notification.png')} width={22} height={24} alt="#" />
          </button>
          <Dropdown menu={{ items }} trigger={['click']}>
            <div className="user">
              <img src={require('assets/images/user.png')} width={36} height={36} alt="#" />
              <div className="content">
                <h3 style={{ textTransform: 'capitalize' }}>{`${user.first_name} ${user.last_name}`}</h3>
                <span style={{ textTransform: 'capitalize' }}>{user.type}</span>
              </div>
              <DownOutlined color="#370E78" />
            </div>
          </Dropdown>
        </div>
      </Header>
    </>
  )
}
