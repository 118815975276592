import { useState, useEffect } from 'react'
import { Skeleton } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PartnerAnalytics as Pnp } from 'components/Icons';
import { useParams } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { getSingleCreator } from "../_redux/Actions";
import arrowred from "assets/svg/arrowred.svg";
import arrowgreen from "assets/svg/arrowgreen.svg";
import arrowpurple from "assets/svg/arrowpurple.svg";
import arrowgray from "assets/svg/arrowgray.svg";
import checkbox from "assets/svg/checkgray.svg";
import './styles.scss';
import { SidebarLayout } from 'components/layout';
import { Dropdown } from "antd";
import {
  fetchAllData
} from "../../partneranalytics/_redux/Actions";
import * as product_actions from "../../products/_redux/products/Actions";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let items: any = [];

export default function CreatorAnalyticsDashboard() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const [compare, set_compare] = useState<any>(null);
  const [crumbs, setCrumbs] = useState([{ label: '', to: '/' }])
  const [date, set_date] = useState<any>(null);
  const [brand, set_brand] = useState<any>(null);
  const [, setBranddrop] = useState(false);
  const [brandselect, setBrandselect] = useState("Select Brand");
  const [updatee, setUpdate] = useState(2)

  const { sum_analytics, brands, content_performance, channel_performance, creatorDetails, creators } = useSelector(
    (state: any) => ({
      sum_analytics: state.analytics.sum_analytics,
      loading: state.analytics.listLoading,
      channel_performance: state.analytics.channel_performance,
      creatorDetails: state.verifications.creatorDetails,
      content_performance: state.analytics.content_performance,
      actionsLoading: state.verifications.actionsLoading,
      brands: state.products.brands,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators

    }),
    shallowEqual
  );

  useEffect(() => {
    // dispatch(fetchAllData('sum_analytics', ''))
    dispatch(product_actions.fetchAllData("brands"));
    // dispatch(fetchAllData('content_performance', ''))
    // dispatch(fetchAllData('channel_performance', ''))
  }, []);

  useEffect(() => {
    var d: any = []

    var dd: any = {};
    dd['label'] = 'All';
    dd['title'] = 'All';
    dd['key'] = '';
    d.push(dd);
    brands && brands.forEach((element: any, index: number) => {
      var dd: any = {};
      dd['label'] = element.name;
      dd['title'] = element.name;
      dd['key'] = element._id;
      d.push(dd);
    });
    items = d;
    setUpdate(updatee + 1)
  }, [brands])


  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([{ label: ' / Profile: ' + creatorDetails.first_name + ' ' + creatorDetails.last_name, to: `/creator/${creatorDetails._id}` }])
    }
  }, [creatorDetails])



  useEffect(() => {
    var q = `creator_id=${creatorId}`;
    if (date && !brand) {
      q += `&start=${date.startDate}&end=${date.endDate}`;
    } else if (!date && brand) {
      q += `&brand_id=${brand}`;
    } else if (date && brand) {
      q += `&start=${date.startDate}&end=${date.endDate}&brand_id=${brand}`;
    } else {
      q += "";
    }

    dispatch(fetchAllData("sum_analytics", `${q}`));
    dispatch(fetchAllData("content_performance", `${q}`));
    dispatch(fetchAllData("channel_performance", `${q}`));

    if (date) {
      var qu = null;
      const date1: any = new Date(date.startDate);
      const date2: any = new Date(date.endDate);
      const diffTime = Math.abs(date1 - date2);
      const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      var date3 = new Date(date.endDate); // Now
      date3.setDate(date2.getDate() - days); // Set now + 30 days as the new date
      if (brand) {
        qu = `start=${date2}&end=${date3}&brand_id=${brand}`;
      } else {
        qu = `start=${date2}&end=${date3}`;
      }
      // console.log(qu);

      dispatch(fetchAllData("sum_analytics_compare", `${qu}`)).then(
        (res: any) => {
          if (res.success) {
            set_compare(res.success);
          }
        }
      );
    }
  }, [date, brand]);


  useEffect(() => {
    if (creatorId) {
      dispatch(getSingleCreator(creatorId));
    } else {
      // setLoading(true);
    }
  }, [creatorId, creators]);

  useEffect(() => {
    if (creatorDetails && creatorDetails._id) {
      setLoading(false);
    }
  }, [creatorDetails])


  const handleMenuClick = (el: any) => {
    Object.keys(items).map((_, index: number) => {
      if (items[index]?.key == el?.key) {
        setBrandselect(items[index]?.label);
      }
    })
    set_brand(el?.key);
    setBranddrop(false);
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };


  return (
    <SidebarLayout
      headerTitle="Partner Analytics"
      headerIcon={Pnp}
      crumbs={crumbs}
    >
      <div className="analytics-container">
        {loading
          ? <Skeleton />
          :
          <>
            <div className="ppbodytop">

              <div className="analyselection">
                <div className="dorpdown">
                  <p className="hclabel">Filter: Brands</p>
                  <Dropdown menu={menuProps} trigger={['click']}>
                    <div className="user">
                      <span className="labele">{brandselect}</span>
                      <DownOutlined color="#370E78" />
                    </div>
                  </Dropdown>
                </div>
              </div>

              <div
                className="analyselection"
                style={{ marginRight: 20, width: 250 }}
              >
                <div className="">
                  <p className="hclabel">Date range</p>
                  <div className="analyselection__cnt">
                    <div className="analysection_cnt">
                      <DateRangePicker
                        onApply={(a, b) => {
                          set_date({
                            startDate: b.startDate.format("YYYY-MM-DD"),
                            endDate: b.endDate.format("YYYY-MM-DD"),
                          });
                          // handleSubmit();
                        }}
                        initialSettings={{
                          startDate: "1/1/2023",
                          endDate: new Date(),
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            backgroundColor: "transparent",
                            width: "100%",
                            borderWidth: 0
                          }}
                        />
                      </DateRangePicker>

                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className="analytics-topone">
              <div className="analytics-toponeitem">
                <p className="tponetitle">Earning summary</p>
                <div className="tponebuttom">
                  <div className="tponebuttom-item">
                    <p className="tponebuttomtitle">Lifetime earnings</p>
                    <p className="tponebuttomsen">
                      {/* {loading
                  ? "-"
                  : sum_analytics && sum_analytics?.lifetime_earning.length > 0
                  ? sum_analytics?.lifetime_earning
                      .map(
                        (data: any) =>
                          `${data.currency} ${data.Total.toFixed(2)}`
                      )
                      .join(", ")
                  : "N/A"} */}
                      {sum_analytics ? `GBP ${sum_analytics?.lifetime_earning.toFixed(2)}` : "-"}

                    </p>
                  </div>
                  <div className="tponebuttom-item">
                    <p className="tponebuttomtitle">Cash out balance</p>
                    <p className="tponebuttomsen">
                      {loading
                        ? "-"
                        : sum_analytics && sum_analytics?.lifetime_earning.length > 0
                          ? sum_analytics?.lifetime_earning
                            .map(
                              (data: any) =>
                                `${data.currency} ${data.Total.toFixed(2)}`
                            )
                            .join(", ")
                          : "0"}
                    </p>
                  </div>
                </div>


              </div>
              <div className="analytics-toponeitem">
                <p className="tponetitle">Partnership growth</p>
                <div className="tponebuttom">
                  <div className="tponebuttom-item">
                    <p className="tponebuttomtitle">Partnership Level</p>
                    <p className="attiamount">
                      {sum_analytics ? sum_analytics?.partnership : "-"}
                    </p>
                  </div>
                  <div className="tponebuttom-item">
                    <p className="tponebuttomtitle">{sum_analytics && sum_analytics.advice ? sum_analytics?.advice?.text : 'Choose brand to view insight'} </p>
                    <p className="tponebuttomsen">{sum_analytics && sum_analytics.advice ? sum_analytics?.advice?.figure : 'N/A'} </p>
                    {/* <p className="tponebuttomsen">{sum_analytics ? sum_analytics?.total_orders : '-'}</p> */}
                  </div>
                </div>

              </div>
            </div>
            <div className="analytics-toptotals">
              <div className="analytics-toptotalsitem">
                <p className="attititle">Total sales</p>
                <p className="tponebuttomsen">
                  {loading
                    ? "-"
                    : sum_analytics && sum_analytics?.total_sales.length > 0
                      ? sum_analytics?.total_sales
                        .map(
                          (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                        )
                        .join(", ")
                      : "0"}
                </p>
                {compare && (
                  <div className="attirating">
                    <img
                      src={
                        sum_analytics?.total_sales[0]?.Total ||
                          0 >= compare?.total_sales[0]?.Total ||
                          0
                          ? arrowgreen
                          : arrowred
                      }
                      alt=""
                    />
                    <p className="attirate atgrn">{`${Math.abs(
                      ((sum_analytics?.total_sales[0]?.Total ||
                        0 - compare?.total_sales[0]?.Total ||
                        0) / sum_analytics?.total_sales[0]?.Total || 0) * 100
                    ).toFixed(2)} %`}</p>
                  </div>
                )}
              </div>

              <div className="analytics-toptotalsitem">
                <p className="attititle">Return/Cancellation</p>
                <p className="tponebuttomsen">
                  {loading
                ? "-"
                : sum_analytics ? sum_analytics.cancellation
                  : "GBP 0"}
                </p>
                {compare && (
                  <div className="attirating">
                    <img
                      src={
                        sum_analytics?.cancellation ||
                          0 >= compare?.cancellation ||
                          0
                          ? arrowgreen
                          : arrowred
                      }
                      alt=""
                    />
                    <p className="attirate atgrn">{`${Math.abs(
                      ((sum_analytics?.cancellation ||
                        0 - compare?.cancellation ||
                        0) / sum_analytics?.cancellation || 0) * 100
                    ).toFixed(2)} %`}</p>
                  </div>
                )}
                {/* <div className="attirating">
            <img src={arrowgreen} alt="" />
            <p className="attirate atgrn">4.1%</p>
          </div> */}
              </div>

              <div className="analytics-toptotalsitem">
                <p className="attititle">Pending Commission</p>
                <p className="tponebuttomsen">
                  {loading
                    ? "-"
                    : sum_analytics && sum_analytics?.pending_commissionable.length > 0
                      ? sum_analytics?.pending_commissionable
                        .map(
                          (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                        )
                        .join(", ")
                      : "GBP 0"}
                </p>
                {compare && (
                  <div className="attirating">
                    <img
                      src={
                        sum_analytics?.pending_commissionable[0]?.Total ||
                          0 >= compare?.pending_commissionable[0]?.Total ||
                          0
                          ? arrowgreen
                          : arrowred
                      }
                      alt=""
                    />
                    <p className="attirate atgrn">{`${Math.abs(
                      ((sum_analytics?.pending_commissionable[0]?.Total ||
                        0 - compare?.pending_commissionable[0]?.Total ||
                        0) / sum_analytics?.pending_commissionable[0]?.Total || 0) * 100
                    ).toFixed(2)} %`}</p>
                  </div>
                )}
                {/* <div className="attirating">
            <img src={arrowgreen} alt="" />
            <p className="attirate atgrn">4.1%</p>
          </div> */}
              </div>

              <div className="analytics-toptotalsitem">
                <p className="attititle">Earned commissions</p>
                <p className="tponebuttomsen">
                  {loading
                    ? "-"
                    : sum_analytics && sum_analytics?.commissionable.length > 0
                      ? sum_analytics?.commissionable
                        .map(
                          (data: any) => `${data.currency} ${data.Total.toFixed(2)}`
                        )
                        .join(", ")
                      : "0"}
                </p>
                {compare && (
                  <div className="attirating">
                    <img
                      src={
                        sum_analytics?.commissionable[0]?.Total ||
                          0 >= compare?.commissionable[0]?.Total ||
                          0
                          ? arrowgreen
                          : arrowred
                      }
                      alt=""
                    />
                    <p className="attirate atgrn">{`${Math.abs(
                      ((sum_analytics?.commissionable[0]?.Total ||
                        0 - compare?.commissionable[0]?.Total ||
                        0) / sum_analytics?.commissionable[0]?.Total || 0) * 100
                    ).toFixed(2)} %`}</p>
                  </div>
                )}
                {/* <div className="attirating">
            <img src={arrowgreen} alt="" />
            <p className="attirate atgrn">4.1%</p>
          </div> */}
              </div>
              <div className="analytics-toptotalsitem">
                <p className="attititle">Total # orders</p>
                <p className="attiamount">
                  {sum_analytics ? sum_analytics?.total_orders : "-"}
                </p>
                {compare && (
                  <div className="attirating">
                    <img
                      src={
                        sum_analytics?.total_orders ||
                          0 >= compare?.total_orders ||
                          0
                          ? arrowgreen
                          : arrowred
                      }
                      alt=""
                    />
                    <p className="attirate atgrn">{`${Math.abs(
                      ((sum_analytics?.total_orders ||
                        0 - compare?.total_orders ||
                        0) / sum_analytics?.total_orders || 0) * 100
                    ).toFixed(2)} %`}</p>
                  </div>
                )}
              </div>
              <div className="analytics-toptotalsitem">
                <p className="attititle">Number of clicks</p>
                <p className="attiamount">
                  {sum_analytics ? sum_analytics?.clicks : "-"}
                </p>
                {compare && (
                  <div className="attirating">
                    <img
                      src={
                        sum_analytics?.clicks || 0 >= compare?.clicks || 0
                          ? arrowgreen
                          : arrowred
                      }
                      alt=""
                    />
                    <p className="attirate atgrn">{`${Math.abs(
                      ((sum_analytics?.clicks || 0 - compare?.clicks || 0) /
                        sum_analytics?.clicks || 0) * 100
                    ).toFixed(2)} %`}</p>
                  </div>
                )}
                {/* <div className="attirating">
            <img src={arrowgreen} alt="" />
            <p className="attirate atgrn">1.6%</p>
          </div> */}
              </div>
              <div className="analytics-toptotalsitem">
                <p className="attititle">Avg. return rate</p>
                <p className="attiamount">
                  {sum_analytics ? sum_analytics?.avg_return : "-"}
                </p>
                {compare && (
                  <div className="attirating">
                    <img
                      src={
                        sum_analytics?.avg_return || 0 >= compare?.avg_return || 0
                          ? arrowgreen
                          : arrowred
                      }
                      alt=""
                    />
                    <p className="attirate atgrn">{`${Math.abs(
                      ((sum_analytics?.avg_return || 0 - compare?.avg_return || 0) /
                        sum_analytics?.avg_return || 0) * 100
                    ).toFixed(2)} %`}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="analyticscp">
              <p className="analyticscptitle">Content Performance</p>
              <div className="ctpover">
                <div className="ctpeformance_header cpheader">
                  <div className="ctp">
                    <div className="ctpp">
                      <img src={checkbox} alt="" />
                      <p className="ctppname">Brand</p>
                    </div>
                    <div className="ctpfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ctp">
                    <p className="ctppname">PRODUCT REFERENCE</p>
                    <div className="ctpfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ctp">
                    <p className="ctppname">AFFILIATE LINK/CODE</p>
                    <div className="ctpfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ctp">
                    <p className="ctppname">NO OF ORDERS</p>
                    <div className="ctpfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ctp">
                    <p className="ctppname">TOTAL SOLD($)</p>
                    <div className="ctpfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  {/* <div className="ctp">
              <p className="ctppname"># RETURNS</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div> */}
                  <div className="ctp">
                    <p className="ctppname">TOTAL COMMISSION($)</p>
                    <div className="ctpfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ctp">
                    <p className="ctppname"># CLICKS</p>
                    <div className="ctpfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  {/* <div className="ctp">
              <p className="ctppname">CTR</p>
              <div className="ctpfilter">
                <img src={arrowpurple} alt="" />
                <img src={arrowgray} alt="" />
              </div>
            </div> */}
                  <div className="ctp">
                    <p className="ctppname">CVR</p>
                    <div className="ctpfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                </div>
                {content_performance && content_performance.map((cplist: any, key: number) => (
                  <div className="ctpeformance_header" key={key}>
                    <div className="ctp">
                      <div className="ctpp">
                        <img src={checkbox} alt="" />
                        <p className="ctppname">{cplist.brand.name}</p>
                      </div>
                    </div>
                     <div className="ctp">
                      <p className="ctppname">{cplist?.name
                    }</p>
                    </div>
                    {/* <div className="ctp">
                      <p className="ctppname">{cplist?.discount?.code}</p>
                    </div> */}
                    <div className="ctp">
                      <p className="ctppname aflink">{cplist?.discount?.code}</p>
                    </div>
                    <div className="ctp">
                      <p className="ctppname">{cplist.stats.total_orders}</p>
                    </div>
                    <div className="ctp">
                      {/* <p className="ctppname">
                        {
                          cplist.stats && cplist.stats?.total_sales.length > 0
                            ? cplist.stats?.total_sales
                              .map(
                                (data: any) =>
                                  `${data.currency} ${data.Total.toFixed(2)}`
                              )
                              .join(", ")
                            : "N/A"}
                      </p> */}
                      <p className="ctppname">{`GBP ${cplist.stats.total_sales.toFixed(2)}`}</p>
              
                    </div>
                    {/* <div className="ctp">
                <p className="ctppname">{cplist.stats.total_orders}</p>
              </div> */} 
                    <div className="ctp">
                    <p className="ctppname">{`GBP ${cplist.stats.total_commissions.toFixed(2)}`}</p>
              
                      {/* <p className="ctppname">
                        {
                          cplist.stats && cplist.stats?.total_commissions.length > 0
                            ? cplist.stats?.total_commissions
                              .map(
                                (data: any) =>
                                  `${data.currency} ${data.Total.toFixed(2)}`
                              )
                              .join(", ")
                            : "N/A"}
                      </p> */}
                    </div>
                    <div className="ctp">
                      <p className="ctppname">{cplist.stats.clicks}</p>
                    </div>
                    {/* <div className="ctp">
                <p className="ctppname">{cplist.stats.avg_return}%</p>
              </div> */}
                    <div className="ctp">
                      <p className="ctppname">
                        {cplist.stats.cvr}

                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <button className="analytic_btn">LOAD MORE</button>
            </div>
            <div className="analyticscp">
              <p className="analyticscptitle">Channel Performance</p>
              <div className="ctpover">
                <div className="analyticscpheader">
                  <div className="ansphref">
                    <div className="ansphbrands">
                      <img src={checkbox} alt="" />
                      <p className="ansphbrandsname">CHANNEL</p>
                    </div>
                    <div className="ansphfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ansphref">
                    <p className="ansphbrandsname">TOTAL Clicks</p>
                    <div className="ansphfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ansphref">
                    <p className="ansphbrandsname">ORDERS</p>
                    <div className="ansphfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ansphref">
                    <p className="ansphbrandsname">REACH</p>
                    <div className="ansphfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ansphref">
                    <p className="ansphbrandsname">CVR</p>
                    <div className="ansphfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                  <div className="ansphref">
                    <p className="ansphbrandsname">#RETURNs</p>
                    <div className="ansphfilter">
                      <img src={arrowpurple} alt="" />
                      <img src={arrowgray} alt="" />
                    </div>
                  </div>
                </div>
                {channel_performance && channel_performance.map((cplist: any, key: number) => (
                  <div
                    className={
                      key === channel_performance.length - 0
                        ? "analyticitemchange"
                        : "analyticitem"
                    }
                    key={key}
                  >
                    <div className="ansphref">
                      <div className="ansphbrands">
                        <img src={checkbox} alt="" className="naasa" />
                        <p className="ansphbrandsname">{cplist._id || 'NULL'}</p>
                      </div>
                    </div>
                    <div className="analyticbrands">
                      <div className="cptotalprog">
                        <div
                          className="cptotalprogna"
                          style={{ width: cplist.percentage * 2 }}
                        >
                          <label>{cplist.total}</label>
                        </div>
                      </div>
                    </div>
                    <div className="analyticproduct">
                      <div className="">
                        <p className="productretitle" key={key}>
                          {cplist.order}
                        </p>
                      </div>
                    </div>
                    <div className="analyticproduct">
                      <div className="">
                        <p className="productretitle" key={key}>
                          {cplist.reach}
                        </p>
                      </div>
                    </div>
                    <div className="analyticproduct">
                      <div className="">
                        <p className="productretitle" key={key}>
                          {cplist.cvr}%
                        </p>
                      </div>
                    </div>
                    <div className="analyticproduct">
                      <div className="">
                        <p className="productretitle" key={key}>
                          {cplist.returns}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className="analytic_btn">LOAD MORE</button>
            </div>
          </>
        }
      </div>
    </SidebarLayout>
  )
}
