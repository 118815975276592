import { useState, useEffect } from 'react'
import { message, Rate, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { ProductCatalogue } from 'components/Icons'
import { SidebarLayout } from 'components/layout'
import { getSingleBrand, getSingleProduct, clearProductDetails, fetchProductMediaAll } from '../_redux/products/Actions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Vimeo from '@u-wave/react-vimeo';
import './styles.scss';


const NextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={require('assets/images/next-icon.png')} alt="" />
    </div>
  );
}

const PrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={require('assets/images/prev-icon.png')} alt="" />
    </div>
  );
}

const vidSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function ProductDetails() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const [crumbs, setCrumbs] = useState<any>([{ label: 'brands', to: '/products' }])
  const productId: any = params?.id;
  const [, contextHolder] = message.useMessage();
  const [previewImage, setPreviewImage] = useState<number>(0);
  const [pumpFake, setPumpfake] = useState(1);
  const [productImages, setProductImages] = useState<any>([]);
  const [productVideos, setProductVideos] = useState<any>([]);

  const { brandDetails, productDetails } = useSelector(
    (state: any) => ({
      actionsLoading: state.products.actionsLoading,
      listLoading: state.products.listLoading,
      productDetails: state.products.productDetails,
      brandDetails: state.products.brandDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(clearProductDetails());
    if (productId) {
      dispatch(getSingleProduct(productId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productDetails && !brandDetails) {
      dispatch(getSingleBrand(productDetails.brand_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails, brandDetails])

  useEffect(() => {
    if (productDetails && brandDetails) {
      setCrumbs([
        { label: 'brands', to: '/products' },
        { label: brandDetails?.name, to: `/brand/${brandDetails._id}` },
        { label: productDetails.name, to: `/product/${productDetails._id}` },
      ])
    }
  }, [productDetails, brandDetails])

  useEffect(() => {
    if (productDetails && productDetails._id) {
      dispatch(fetchProductMediaAll(`?type_id=${productDetails._id}&file_type=image`)).then((res: any) => {
        let pIm: any = productImages;
        pIm = res.success;
        console.log(pIm)
        setPumpfake(pumpFake + 1);
        setProductImages(pIm);
      })

      dispatch(fetchProductMediaAll(`?type_id=${productDetails._id}&file_type=video`)).then((res: any) => {
        let pIm: any = productVideos;
        pIm = res.success;
        // console.log(pIm)
        setPumpfake(pumpFake + 1);
        setProductVideos(pIm);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  const selectThumbIndex = (index: number) => {
    setPreviewImage(index);

  }

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      {contextHolder}
      {productDetails && productDetails._id
        ? <>
          <div className="products-details-container">
            {productImages
              ? <div className="preview">
                <div className="sliderr">
                  {productImages &&
                    <>
                      {productImages.map((image: any, index: number) => (
                        <div className={`product-image ${previewImage === index ? 'anymate' : 'hide'}`} key={index}>
                          <img src={productImages[index].url} alt="" style={{ width: '100%', height: '100%' }} />
                        </div>
                      ))}
                    </>
                  }
                  <div className="control">
                    {productImages.map((image: any, index: number) => (
                      <div className={`tnail ${previewImage === index && 'active'}`} onClick={() => selectThumbIndex(index)}>
                        <img src={image.url} alt="*" />
                      </div>
                    ))}
                  </div>
                </div>
                {/* <Field
                  type="text" showCount
                  LabelIcon={EditField}
                  onChange={(e: any) => setProductUrl(e.target.value)}
                  value={productUrl}
                  label="Order URL"
                /> */}
              </div>
              : ""
            }
            <div className="details">
              <div className="brand-logo">
                {/* {
                  <img src={productDetails?.thumbnail} alt="*" />
                } */}
                {brandDetails &&
                  <h2 className="product-head">{brandDetails.name.toUpperCase()}</h2>
                }
              </div>
              <h2 className="product-title">{productDetails.name}</h2>
              <div className="desc" dangerouslySetInnerHTML={{ __html: productDetails.description }} />
              <span className="price">Price to Consumer: <span>£{productDetails.price}</span></span>
              {productDetails.commission && <span className="earn">Earn: £{productDetails.commission.toFixed(2)}</span>}
              <div className="ratings">
                <Rate
                  disabled
                  allowHalf
                  defaultValue={5}
                />
                {/* <Link to={`/product/${productDetails._id}/reviews`} className="reviews">see {productDetails.reviews || 0} reviews</Link> */}
                {
                  productDetails.exploreUrl && <a href={`${productDetails.exploreUrl}#ratings-reviews`} target="_blank" rel="noreferrer" className="reviews">see reviews</a>
                }
              </div>
              {/* <div className="links-urls">
                <Field
                  type="text" showCount
                  onChange={(e: any) => setCodeUrl(e.target.value)}
                  value={codeUrl}
                  LabelIcon={EditField}
                  label="Generate code URL"
                />
                <Field
                  type="text" showCount
                  onChange={(e: any) => setTrackingLink(e.target.value)}
                  value={trackingLink}
                  label="Get tracking link URL"
                />
                <Field
                  type="text" showCount
                  LabelIcon={EditField}
                  onChange={(e: any) => setOrderUrl(e.target.value)}
                  value={orderUrl}
                  label="Order URL"
                />
              </div> */}
            </div>
          </div>
          <div className="video-container">
            <h2 className="section-title">Video usage & tutorials</h2>
            <Slider {...vidSettings} className="video-slider">
              {productDetails && JSON.parse(productDetails?.product_videos || '[]').map((d: any, index: number) => (
                <div className="video" key={index} style={{
                  height: 'auto',
                  width: '100%',
                  maxHeight: 450,
                }}>
                  <Vimeo
                    responsive
                    video={d}
                    width={800}
                    height={480}
                    autoplay={false}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </>
        : <Skeleton />
      }
    </SidebarLayout >
  )
}
