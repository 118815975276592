import { useState, useRef, useEffect } from 'react'
import { Skeleton, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { SidebarLayout } from 'components/layout'
import { Check, CommunityHub } from 'components/Icons';
import './styles.scss';
import { getSingleDispute, editDispute } from "../_redux/Actions";
import { toast } from 'react-toastify';
import { Button } from 'components/forms';

const crumbs = [
  { label: 'help centre', to: '/community' },
  { label: 'DISPUTE: COMMISSION DISPUTE', to: '/community/dispute/12345' },
]
const disputeDetails: any = {
  _id: '123456',
  first_name: 'Jane',
  last_name: 'Does',
  dispute: 'Commission Dispute',
  status: 'pending',
  email: 'johndoe@email.com',
  phone: '+44 1234 5678 90',
  priority: 'mid priority: response within 1-2 working days',
  uploadfile: 'Screenshot.png',
  assignee: 'James Doe',
  message: 'Lorem ipsum dolor sit amet, sit amet dolor ipsum lorem. Lorem ipsum dolor sit amet, sit amet dolor ipsum lorem',
}

export default function DisputeDetails() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, set_data] = useState<any>(true);

  const mailto = useRef<any>(null);

  const { ddd } = useSelector(
    (state: any) => ({
      ddd: state.verifications.disputeDetails,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading

    }),
    shallowEqual
  );

  console.log(ddd);

  useEffect(() => {
    if (creatorId) {
      dispatch(getSingleDispute(`?q=${creatorId}`)).then((res: any) => {
        set_data(res.success[0]);
      });
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function complete() {
    var body: any = {};
    body['status'] = "completed";
    dispatch(editDispute(body, `?help_id=${creatorId}`)).then((res: any) => {
      if (res.success) {
        toast.info('Successfully updated status')
        dispatch(getSingleDispute(`?q=${creatorId}`)).then((res: any) => {
          set_data(res.success[0]);
        });
      } else {
        toast.error('Error while updating status')
      }
    });
  }

  useEffect(() => {
    if (disputeDetails && disputeDetails._id) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [disputeDetails])


  return (
    <SidebarLayout
      headerTitle="community hub"
      headerIcon={CommunityHub}
      crumbs={crumbs}
    >
      {contextHolder}
      {loading || !disputeDetails?._id
        ? <Skeleton />
        :
        <>
          <div className="creator-details">
            <div className="mask-data dispute">
              <img src={require('assets/images/avatar-bg.png')} alt="*" className="avatar" />
              <span className="creator-name">{data?.first_name || ''}{" "}{data?.last_name || ''}</span>
              <span className="creator-dispute">{data?.title}</span>
              <div className={`vrf-status ${data?.status === 'completed' ? 'approved' : 'pending'}`}>
                <span>&#8226;{data?.status}</span>
              </div>
              <div className="flex">
                {data?.status !== 'completed' &&
                  <button onClick={() => complete()} className="action-btn check">
                    <Check />
                  </button>}
                {/* <button onClick={() => console.log('okayyyy')} className="action-btn cross">
                  <Cross />
                </button> */}
              </div>
            </div>
          </div>
          <div className="info dispute">
            <div>
              <div className="data-pairs">
                <div className="label-value">
                  <span className="label">Full name</span>
                  <span className="value">{data?.first_name}{" "}{data?.last_name}</span>
                </div>
                <div className="label-value">
                  <span className="label">Email address</span>
                  <span className="value">{data?.email}</span>
                </div>
              </div>
              <div className="data-pairs">
                <div className="label-value">
                  <span className="label">Phone number</span>
                  <span className="value">{data?.phone}</span>
                </div>
                <div className="label-value">
                  <span className="label">Topic</span>
                  <span className="value">{data?.topic}</span>
                </div>
              </div>
              <div className="data-pairs">
                <div className="label-value">
                  <span className="label">Priority</span>
                  <span className="value">{data?.priority}</span>
                </div>
                <div className="label-value">
                  <span className="label">uploadfile</span>
                  {
                    data?.image ?
                      <a href={data.image} target="_blank" rel="noreferrer" className="value">Click to view image</a>
                      :
                      <span className="value">No image added</span>
                  }
                </div>
              </div>
              <div className="data-pairs">
                <div className="label-value">
                  <span className="label">Assignee</span>
                  <span className="value">{data?.assignee || 'N/A'}</span>
                </div>
              </div>
              <div className="data-pairs">
                <div className="label-value">
                  <span className="label">Message</span>
                  <span className="value">{data?.message}</span>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <a
                ref={mailto}
                href={`mailto:${data?.email}`}
                style={{ display: "none" }}
              >

                <Button
                  onClick={() => mailto.current.click()}
                  variant="primary" label="RESPOND VIA EMAIL" />
              </a>
              {/* <Button onClick={() => console.log('hey!')} variant="primary" label="RESPOND VIA SMS" /> */}
            </div>
          </div>
        </>
      }
    </SidebarLayout>
  )
}
