import { useState, useEffect, useRef } from 'react'
import { Skeleton, Table, message, Dropdown, Select, Button } from 'antd';
import { DownOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { Check, Cross } from 'components/Icons';
import { SidebarLayout } from 'components/layout'
import * as actions from "../../partneranalytics/_redux/Actions";
import { CreatorVerification } from 'components/Icons'
import { Field } from 'components/forms';
import './styles.scss';
import { CSVLink } from "react-csv";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";


interface DataType {
  key: React.Key;
  name: string;
  email: string;
  status: 'paid' | 'pending' | 'notPaid';
}

const crumbs = [
  { label: '', to: '/' },
]

const menuItems: any = [
  {
    label: 'All',
    key: '1',
    title: 'All'
  },
  {
    label: 'Approved',
    key: '1',
    title: 'Approved'
  },
  {
    label: 'Pending',
    key: '2',
    title: 'Pending'
  },
  {
    label: 'Declined',
    key: '1',
    title: 'Declined'
  },
];

export default function VerificationList() {
  const dispatch = useDispatch<any>();
  const [selectedCategory, setCategory] = useState('All')
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [, contextHolder] = message.useMessage();
  const [skip, setSkip] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [q, setQ] = useState('')
  const [, set_headers] = useState<any>([]);
  const [data, set_data] = useState<any>([]);
  const [date, set_date] = useState<any>(null);
 

  const [headers2, set_headers2] = useState<any>([]);

  const { listLoading, transactions, creatorsCount } = useSelector(
    (state: any) => ({
      creators: state.verifications.creators,
      transactions: state.analytics.transactions,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creatorsCount: state.verifications.creatorsCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchAllData('transactions', ``));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (date) {
      dispatch(
        actions.fetchAllData("transactions", `start=${date.startDate}&end=${date.endDate}`)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);


  useEffect(() => {
    if (transactions && transactions.length > 0) {
      var heade = [
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Currency", key: "currency" },
        { label: "Amount", key: "amount" },
        { label: "Request date", key: "createdAt" },
        { label: "Status", key: "status" }
      ];

      var heade2 = [
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Currency", key: "currency" },
        { label: "Amount", key: "amount" },
        { label: "Account No", key: "account_number" },
        { label: "IBAN", key: "iban" },
        { label: "Post Code", key: "postal_code" },
        { label: "Request date", key: "createdAt" },
        { label: "Status", key: "status" }
      ];



      set_headers(heade)
      set_headers2(heade2)
      set_data(transactions)
      // console.log(headers, data)
    }
  }, [transactions])

  const columns: ColumnsType<DataType> = [
    {
      title: 'Full name',
      dataIndex: 'name',
      sorter: true,
      render: (text: string, full: any) => {
        return (<span className="table-namey">
          {full.first_name.trim() || 'N/A'} {full.last_name.trim() || 'N/A'}
        </span>
        )
      },
    },
    {
      title: 'Request date',
      dataIndex: 'requestDate',
      sorter: true,
      render: (text: Date, full: any) => {
        return `${new Date(full.createdAt).toLocaleDateString()}`
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: true,
      render: (text: string, full: any) => {
        return (<span>{`GBP ${full.amount.toFixed(2)}`}</span>)
      },
    },
    {
      title: 'Payout date',
      dataIndex: 'payoutDate',
      sorter: true,
      render: (text: Date, full: any) => {
        return `${full.date_approved ? new Date(full.date_approved).toLocaleDateString() : 'N/A'}`
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      render: (text: string, full: any) => {
        return (
          <div className={`vrf-status ${full.status}`}>
            <span> <span className="status-dot">&#8226;</span>{full.status === 'notPaid' ? 'Not Paid' : full.status || ''}</span>
          </div>
        )
      },
    },
    {
      title: '',
      key: 'action',
      render: (_, record: any) => (
        <>
          {
            record.status === 'pending'
              ?
              <div className="flex">
                <button onClick={() => takeAction(record.transaction_id, 'approve')} className="action-btn check">
                  <Check />
                </button>
                <button onClick={() => takeAction(record.transaction_id, 'decline')} className="action-btn cross">
                  <Cross />
                </button>
              </div>
              : ''
          }
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleMenuClick = async (el: any) => {
    console.log(el?.item?.props?.title);
    setCategory(el?.item?.props?.title);
    await dispatch(actions.fetchAllData('transactions', `status=${el?.item?.props?.title === 'All' ? '' : el?.item?.props?.title?.toLowerCase()}`));
  }

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const initSearch = () => {
    if (q !== '') {
      dispatch(actions.fetchAllData('transactions', `q=${q}`));
    } else {
      dispatch(actions.fetchAllData('transactions', ``));
    }
  }

  const pageChangeManual = async (lim: any) => {
    setPageLimit(lim);
    setSkip(0)
    setPageIndex(0);
    await dispatch(actions.fetchAllData('transactions', `limit=${lim}&skip=${skip}`));
  }

  const paginateManual = async (type: string) => {
    if (type === 'prev' && pageIndex > 0) {
      setSkip(skip - 1);
      var new_skip = skip - 1;
      await dispatch(actions.fetchAllData('transactions', `limit=${pageLimit}&skip=${new_skip}`));
      setPageIndex(pageIndex - 1)
    }
 
    if (type === 'next' && (pageIndex + 1) * pageLimit <= creatorsCount) {
      setSkip(skip + 1);
      await dispatch(actions.fetchAllData('transactions', `limit=${pageLimit}&skip=${skip + 1}`));
      setPageIndex(pageIndex + 1)
    }
  }

  const takeAction = (id: string, action: string) => {
    // console.log(id, action);
    var b: any = {}; b['status'] = action;
    dispatch(actions.editDetails(b, `?transaction_id=${id}`, 'trans'))
  }

  return (
    <SidebarLayout
      headerTitle="payouts requests"
      headerIcon={CreatorVerification}
      crumbs={crumbs}
    >
      {contextHolder}
      <div className="verification-container">
        <div className="search-content">
          <Field placeholder="Search " type="text" label="Search" onChange={(e) => setQ(e.target.value)} onPressEnter={initSearch} />
          <div className="dorpdown flexed">
            <div>
              <span>Filter: Status</span>
              <Dropdown menu={menuProps} trigger={['click']}>
                <div className="user">
                  <span className="labele">{selectedCategory}</span>
                  <DownOutlined color="#370E78" />
                </div>
              </Dropdown>
            </div>

            <div className="" style={{width: 250}}>
                <p className="hclabel">Date range</p>
                <div className="analyselection__cnt">
                  <div className="analysection_cnt">
                    <DateRangePicker
                      onApply={(a, b) => {
                        set_date({
                          startDate: b.startDate.format("YYYY-MM-DD"),
                          endDate: b.endDate.format("YYYY-MM-DD"),
                        });
                        // handleSubmit();
                      }}
                      initialSettings={{
                        startDate: "1/1/2023",
                        endDate: new Date(),
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          backgroundColor: "transparent",
                          width: "100%", borderWidth: 0
                        }}
                      />
                    </DateRangePicker>
                    {/* <img
                      className="vblsc_right"
                      style={{ paddingLeft: 20 }}
                      src={dropdown}
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>
              
            {/* <div>
              <span>Filter: Date Range</span>
              <div className="pickered">
                <RangePicker
                  onChange={(val: any) => {
                    const valueOfInput1 = val[0].format();
                    const valueOfInput2 = val[1].format();
                    set_start_date(valueOfInput1)
                    set_end_date(valueOfInput2)
                  }}
                  placement="bottomLeft"
                />
              </div>
            </div> */}
            {/* <Button type="text">EXPORT CSV</Button> */}
            {data && data.length > 0 &&
              <CSVLink
                filename={`transaction_export.csv`}
                data={data} headers={headers2}>
                <Button type="text">EXPORT CSV</Button>
                {/* Download me */}
              </CSVLink>}
          </div>
        </div>
        {transactions && transactions.length === 0 &&
          <Skeleton loading={listLoading} active paragraph={{ rows: 10 }} />
        }
        {windowSize?.current[0] >= 768 &&
          <>
            {transactions && transactions.length > 0 &&
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={transactions}
                pagination={false}
              />
            }
          </>
        }
        {windowSize?.current[0] < 768 &&
          <div className="mobile-list-view">
            {transactions && transactions.length > 0 &&
              transactions.map((creator: any, index: number) => (
                <Link to={`/creator/${creator._id}`} className="mobile-l-item" key={index}>
                  <div className="label-value">
                    <span className="label">FULL name</span>
                    <span className="value name">{`${creator.first_name} ${creator.influencerlast_name}`}</span>
                  </div>
                  <div className="label-value">
                    <span className="label">email address</span>
                    <span className="value">{creator.email}</span>
                  </div>
                  <div className="label-value">
                    <span className="label">status</span>
                    <span className="value">
                      <div className={`vrf-status ${creator.status}`}>
                        <span>&#8226;{creator.status}</span>
                      </div>
                    </span>
                  </div>
                  <div className="actione">
                    {creator.status === 'pending'
                      ?
                      <div className="flex flex-end">
                        <button onClick={() => takeAction(creator.transaction_id, 'approve')} className="action-btn check">
                          <Check />
                        </button>
                        <button onClick={() => takeAction(creator.transaction_id, 'decline')} className="action-btn cross">
                          <Cross />
                        </button>
                      </div>
                      : ''}
                  </div>
                </Link>
              ))
            }
          </div>
        }
        <div className="paginate">
          <Button type="primary" onClick={() => paginateManual('prev')} disabled={pageIndex <= 0}>
            <LeftOutlined />
            Prev
          </Button>
          <div className="page">
            <Select
              defaultValue=""
              style={{ width: 120 }}
              onChange={pageChangeManual}
              options={[
                { value: '', label: 'Page size' },
                { value: '20', label: '20/page' },
                { value: '25', label: '25/page' },
                { value: '30', label: '30/page' },
                { value: '40', label: '40/page' },
                { value: '60', label: '60/page' },
              ]}
            />
          </div>
          <Button type='primary' onClick={() => paginateManual('next')} disabled={!((pageIndex + 1) * pageLimit <= creatorsCount)}>
            Next
            <RightOutlined />
          </Button>
        </div>
      </div>
    </SidebarLayout >
  )
}
