import { Button, Dropdown, Select, Skeleton, Table } from 'antd';
import { RightOutlined, DownOutlined, LeftOutlined } from '@ant-design/icons';
import { Field } from 'components/forms';
import { Check, Cross } from 'components/Icons';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import * as actions from "../../_redux/verifications/Actions";
import { Empty } from 'components/shared';
import { CSVLink } from "react-csv";

interface DataType {
  key: React.Key;
  name: string;
  email: string;
  status: 'approved' | 'rejected' | 'pending';
}
const menuItems: any = [
  {
    label: 'All',
    key: '0',
    title: 'All'
  },
  {
    label: 'United Kingdom',
    key: '1',
    title: 'united kingdom'
  },
  {
    label: 'United States',
    key: '2',
    title: 'united states'
  },
];


export default function ApprovedCreators() {

  const dispatch: any = useDispatch()
  const [kCreator, setKCreator] = useState([]);
  const [selectedCategory, setCategory] = useState('All')
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [skip, setSkip] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [q, setQ] = useState('');
  const keyedCreators: any = [];
  const [headers, set_headers] = useState<any>([]);

  const { creators, listLoading, creatorsCount } = useSelector(
    (state: any) => ({
      creators: state.verifications.creators,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creatorsCount: state.verifications.creatorsCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchAllData('creators', `?type=creator&status=approved`));
    dispatch(actions.fetchCreatorsCount(`approved`));
    setCategory('All');
    setQ('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (creators && creators.length > 0) {
     

      var heade2 = [
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Email", key: "email" },
        { label: "Approval Date", key: "approved_at" },
        { label: "Country", key: "country" }
      ];

      set_headers(heade2)
    }
  }, [creators])

  useEffect(() => {
    if (creators && creators.length > 0) {
      creators.map((creator: any) => {
        keyedCreators.push({ ...creator, key: creator._id })
        return true;
      });
      setKCreator(keyedCreators);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creators])

  const send_status = (st: any, act: string) => {
    var stats: any = {};
    stats['user_id'] = st;
    stats['status'] = act;
    dispatch(actions.editDetails(stats, '', 'toggle_activation'))
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Full name',
      dataIndex: 'name',
      sorter: true,
      render: (text: string, full: any) => {
        return (<Link className="table-namey" to={`/partner-analytics/creator/${full._id}`}>
          {full.first_name.trim() || 'N/A'} {full.last_name.trim() || 'N/A'}
        </Link>
        )
      },
    },
    {
      title: 'approval date',
      dataIndex: 'approvalDate',
      sorter: true,
      render: (text: string, full: any) => {
        return `${full?.approved_at ? new Date(full.approved_at).toISOString().split('T')[0] : 'N/A'}`
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      render: (text: string, full: any) => {
        return `${full.email}`
      },
    },
    {
      title: 'Country',
      dataIndex: 'country',
      sorter: true,
      render: (text: string, full: any) => {
        return `${full?.country?.toUpperCase()}`
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleMenuClick = async (el: any) => {
    setCategory(el?.item?.props?.title);
    await dispatch(actions.fetchAllData('creators', `?country=${el?.item?.props?.title === 'All' ? '' : JSON.stringify(el?.item?.props?.title?.toLowerCase())}&status=approved`));
  }

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const initSearch = () => {
    console.log('initialdddized', q);
    if (q !== '') {
      dispatch(actions.fetchAllData('search_creators', `?q=${q}&status=approved`));
    }
  }


  const pageChangeManual = async (lim: any) => {
    setPageLimit(lim);
    setSkip(0)
    setPageIndex(0);
    await dispatch(actions.fetchAllData('creators', `?limit=${lim}&skip=${skip}&status=approved`));
  }

  const paginateManual = async (type: string) => {
    if (type === 'prev' && pageIndex > 0) {
      setSkip(skip - 1);
      var new_skip = skip - 1;
      await dispatch(actions.fetchAllData('creators', `?limit=${pageLimit}&skip=${new_skip}&status=approved`));
      setPageIndex(pageIndex - 1)
    }

    if (type === 'next' && (pageIndex + 1) * pageLimit <= creatorsCount) {
      setSkip(skip + 1);
      await dispatch(actions.fetchAllData('creators', `?limit=${pageLimit}&skip=${skip + 1}&status=approved`));
      setPageIndex(pageIndex + 1)
    }
  }

  return (
    <div className="verification-container">
      <div className="search-content">
        <Field placeholder="Search " type="text" label="Search" onChange={(e) => setQ(e.target.value)} onPressEnter={initSearch} />
        <div className="dorpdown flexed">
        <div>
            <span>Filter: Country</span>
            <Dropdown menu={menuProps} trigger={['click']}>
              <div className="user">
                <span className="labele">{selectedCategory}</span>
                <DownOutlined color="#370E78" />
              </div>
            </Dropdown>
          </div>
      
        {kCreator && kCreator.length > 0 &&
            <CSVLink
              filename={`partners.csv`}
              data={kCreator} headers={headers}>
              <Button type="text">EXPORT CSV</Button>
            </CSVLink>}

        </div>
      </div>
      {kCreator.length === 0 && listLoading &&
        <Skeleton loading={listLoading} active paragraph={{ rows: 10 }} />
      }
      {!listLoading && kCreator && kCreator.length === 0 && <Empty />}

      {windowSize?.current[0] >= 768 &&
        <>
          {kCreator && kCreator.length > 0 &&
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              dataSource={kCreator}
              pagination={false}
            />
          }
        </>
      }
      {windowSize?.current[0] < 768 &&
        <div className="mobile-list-view">
          {!listLoading && kCreator && kCreator.length > 0 &&
            kCreator.map((creator: any, index) => (
              <Link to={`/creator/${creator._id}`} className="mobile-l-item" key={index}>
                <div className="label-value">
                  <span className="label">FULL name</span>
                  <span className="value name">{`${creator.first_name} ${creator.last_name}`}</span>
                </div>
                <div className="label-value">
                  <span className="label">email address</span>
                  <span className="value">{creator.email}</span>
                </div>
                <div className="label-value">
                  <span className="label">status</span>
                  <span className="value">
                    <div className={`vrf-status ${creator.verification_status}`}>
                      <span>&#8226;{creator.verification_status}</span>
                    </div>
                  </span>
                </div>
                <div className="actione">
                  {creator.verification_status === 'pending'
                    ?
                    <div className="flex flex-end">
                      <button onClick={() => send_status(creator._id, 'approve')} className="action-btn check">
                        <Check />
                      </button>
                      <button onClick={() => send_status(creator._id, 'reject')} className="action-btn cross">
                        <Cross />
                      </button>
                    </div>
                    : ''}
                </div>
              </Link>
            ))
          }
        </div>
      }
      <div className="paginate">
        <Button type="primary" onClick={() => paginateManual('prev')} disabled={pageIndex <= 0}>
          <LeftOutlined />
          Prev
        </Button>
        <div className="page">
          <Select
            defaultValue=""
            style={{ width: 120 }}
            onChange={pageChangeManual}
            options={[
              { value: '', label: 'Page size' },
              { value: '20', label: '20/page' },
              { value: '25', label: '25/page' },
              { value: '30', label: '30/page' },
              { value: '40', label: '40/page' },
              { value: '60', label: '60/page' },
            ]}
          />
        </div>
        <Button type='primary' onClick={() => paginateManual('next')} disabled={!((pageIndex + 1) * pageLimit <= creatorsCount)}>
          Next
          <RightOutlined />
        </Button>
      </div>
    </div>
  )
}

