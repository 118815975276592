import * as requestFromServer from "./Crud";
import { callTypes, AnalyticsSlice } from "./Slice";
import { toast } from 'react-toastify';

const { actions } = AnalyticsSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'summary') {
        dispatch(actions.summaryList(res.success));
        return;
      } else if (type === 'partners') {
        dispatch(actions.partnerList(res.success));
        return;
      } else if (type === 'transactions') {
        dispatch(actions.transactionsList(res.success));
        return;
      } else if (type === 'sum_analytics') {
        dispatch(actions.sumAnalytics(res.success));
        return res;
      } else if (type === 'content_performance') {
        dispatch(actions.contentPerformance(res.success));
        return;
      } else if (type === 'channel_performance') {
        dispatch(actions.channelPerformance(res.success));
        return;
      } else if (type === 'posts') {
        dispatch(actions.contentPosts(res.success));
        return;
      } 
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const reset_campaign_info = () => dispatch => {
  dispatch(actions.reset());
};

export const editDetails = (body, queryParams, type = 'toggle_activation') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editDetails(body, queryParams, type)
    .then(response => {
      let res = response.data;
      if (type === 'toggle_activation') {
        toast.success(`Creator's profile ${res.success.verification_status}!`);
        dispatch(actions.DatasStatusUpdated({ id: res.success._id, verification_status: res.success.verification_status }));
        return;
      } else if (type === 'trans') {
        if (res.success) {
          toast.success(`Transaction ${res.success.status}!`);
          dispatch(actions.StatusDatasStatusUpdated({ id: res.success._id, status: res.success.status }));
          return;
        } else {
          toast.error(res.error);
        }
        
      }
      return res;
      // dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSingleCreator = (id, type) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchCreatorDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.creatorDetails(res.user));
      return res?.user || res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



