import { useState, useEffect, useRef } from 'react'
import type { ColumnsType } from 'antd/es/table';
import { DownOutlined } from '@ant-design/icons';
import { Skeleton, Table, Dropdown } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import '../styles.scss';
import { getSingleCreator } from "../../_redux/Actions";
import { Field } from 'components/forms';
import {
  fetchAllData
} from "../../_redux/Actions";
import * as product_actions from "../../../products/_redux/products/Actions";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

const filterer: any = {
  'Brand': [
    { label: 'JVN', title: 'JVN', key: "brand0" },
    { label: 'Bissonance', title: 'Bissonance', key: "brand1" },
    { label: 'Rose Inc', title: 'Rose Inc', key: "brand2" },
  ],
  'Socials': [
    { label: 'Facebook', title: 'Facebook', key: "socials0" },
    { label: 'Instagram', title: 'Instagram', key: "socials1" },
    { label: 'TikTok', title: 'TikTok', key: "socials2" },
  ],
  "Country": [
    { label: 'All', title: 'All', key: "" },
    { label: 'United Kingdom', title: 'United Kingdom', key: "United Kingdom" },
    { label: 'United States', title: 'United States', key: "United States" },
  ],

}

export default function PartnersRanking() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const [loading, setLoading] = useState<boolean>(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [selectedCategory] = useState('All')
  const [, setCrumbs] = useState([{ label: '', to: '/' }])
  const [q, setQ] = useState('')
  const [date, set_date] = useState<any>(null);
  const [brand, set_brand] = useState<any>(null);
  const [country, set_country] = useState<any>(null);
  const [brands_filter, set_brand_filters] = useState<any>([]);



  const filtererMenu: any = {
    'Brand': { items: brands_filter, onClick: (el: any) => set_brand(el.key) },
    "Country": { items: filterer['Country'], onClick: (el: any) => set_country(el.key) },
    'Socials': { items: filterer['Socials'], onClick: (el: any) => console.log("ele clcik", el) },
  }



  const { creatorDetails, brands, analytics, creators } = useSelector(
    (state: any) => ({
      analytics: state.analytics.partners,
      creatorDetails: state.verifications.creatorDetails,
      brands: state.products.brands,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creators: state.verifications.creators

    }),
    shallowEqual
  );

  useEffect(() => {
    if (creatorDetails) {
      setCrumbs([{ label: ' / Profile: ' + creatorDetails.first_name + ' ' + creatorDetails.last_name, to: `/creator/${creatorDetails._id}` }])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorDetails])

  useEffect(() => {
    if (creatorId) {
      dispatch(getSingleCreator(creatorId));
    } else {
      // setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId, creators]);

  useEffect(() => {
    if (brands && brands_filter.length === 0) {
      var brands_f = [...brands_filter];
      var bdd: any = {};
      bdd['label'] = 'All';
      bdd['title'] = 'All';
      bdd['key'] = '';
      brands_f.push(bdd)

      brands.forEach((ddd: any) => {
        var bdd: any = {};
        bdd['label'] = ddd.name;
        bdd['title'] = ddd.name;
        bdd['key'] = ddd._id;
        brands_f.push(bdd)
      })
      set_brand_filters(brands_f);
    }
    // eslint-disable-next-line
  }, [brands, brands_filter])


  useEffect(() => {
    dispatch(product_actions.fetchAllData('brands'));
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (creatorDetails && creatorDetails._id) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorDetails]);

  useEffect(() => {
    var q = '';
    if (date) {
      q += `start=${date.startDate}&end=${date.endDate}&`;
    }
    if (brand) {
      q += `brand_id=${brand}&`;
    }
    if (country) {
      if (country === 'all') {
        q += ``;
      } else {
        q += `country=${country}&`;
      }
    }

    dispatch(fetchAllData('partners', q))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, brand, country])


  const columns: ColumnsType<any> = [
    {
      title: 'NAME',
      dataIndex: 'name',
      sortDirections: ['ascend','descend'],
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (text: string, full: any) => {
        return <Link to={`/partner-analytics/creator/${full._id}`} className="flex items-center">
          <img src={full?.picture || require('assets/images/user.png')} width={24} height={24} alt="#" className="mr-2" />
          <span className="table-namey"> {full.first_name} {full.last_name} </span>
        </Link>
      },
    },
    {
      title: 'NET SALES',
      dataIndex: 'netSales',
      sorter:  {
        compare: (a , b) => a.total_sales - b.total_sales,
        multiple: 3,
      },
      render: (text: string, full: any) => {
        return <span>{`GBP ${full.total_sales.toFixed(2)}`}</span>
      },
    },
    {
      title: 'TOTAL # OF ORDERS',
      dataIndex: 'totalOrders',
      sorter: {
        compare: (a , b) => a.orderCount - b.orderCount,
        multiple: 3,
      },
      render: (text: string, full: any) => {
        return `${full.orderCount}`
      },
    },
    {
      title: 'EARN COMMISSION',
      dataIndex: 'commission',
      sorter: {
        compare: (a , b) => a.commissions - b.commissions,
        multiple: 3,
      },
      render: (text: string, full: any) => {
        return `GBP ${full.commissions.toFixed(2)}`
      },
    },
    {
      title: 'CVR',
      dataIndex: 'cvr',
      sorter: {
        compare: (a , b) => a.cvr - b.cvr,
        multiple: 3,
      },
      render: (text: string, full: any) => {
        return `${full.cvr.toFixed(2)} %`
      },
    },
    {
      title: 'Clicks',
      dataIndex: 'visits',
      sorter:{
        compare: (a , b) => a.clickCount - b.clickCount,
        multiple: 3,
      },
      render: (text: string, full: any) => {
        return `${full.clickCount}`
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };



  function getByLabelText(filterer: any) {
    if (filterer === 'brand') {
      if (!brand) {
        return 'All';
      } else {
        var brrr = brands.filter((obj: any) => {
          return obj._id === brand;
        })
        return brrr[0].name
      }
    } else if (filterer === 'country') {
      return country || 'All';
    }
    return selectedCategory;
  }


  const initSearch = () => {
    if (q !== '') {
      dispatch(fetchAllData('partners', `q=${q}`));
    } else {
      dispatch(fetchAllData('partners', ``));
    }
  }

  return (
    <div className="analytics-container">
      {loading
        ? <Skeleton paragraph={{ rows: 12 }} />
        :
        <>
          <div className="search-picker">
            <div className="search">
              <Field placeholder="Search " type="text" label="Search" onChange={(e) => setQ(e.target.value)} onPressEnter={initSearch} />
            </div>
            {/* <div className="picker">
              <RangePicker placement="bottomLeft" />
            </div> */}
            <div className="" style={{ width: 250 }}>
              <p className="hclabel">Date range</p>
              <div className="analyselection__cnt">
                <div className="analysection_cnt">
                  <DateRangePicker
                    onApply={(a, b) => {
                      set_date({
                        startDate: b.startDate.format("YYYY-MM-DD"),
                        endDate: b.endDate.format("YYYY-MM-DD"),
                      });
                      // handleSubmit();
                    }}
                    initialSettings={{
                      startDate: "1/1/2023",
                      endDate: new Date(),
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        backgroundColor: "transparent",
                        width: "100%", borderWidth: 0
                      }}
                    />
                  </DateRangePicker>
                  {/* <img
                      className="vblsc_right"
                      style={{ paddingLeft: 20 }}
                      src={dropdown}
                      alt=""
                    /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="filterer analytics">
            {Object.keys(filterer).map((el, index) => (
              <div className="dorpdown" key={index}>
                <span>{el}</span>
                <Dropdown menu={filtererMenu[el]} trigger={['click']}>
                  <div className="user">
                    <span className="labele">{getByLabelText(el.toLowerCase())}</span>
                    <DownOutlined color="#370E78" />
                  </div>
                </Dropdown>
              </div>
            ))}
          </div>
          <div className="analytics-table">
            <div className="section-title">Top Performing Partners</div>
            {windowSize?.current[0] >= 768 &&
              <>
                {analytics && analytics.length > 0 &&
                  <Table
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={analytics}
                  />
                }
              </>
            }
            {windowSize?.current[0] < 768 &&
              <div className="mobile-list-view">
                {analytics && analytics.length > 0 &&
                  analytics.map((tics: any, index: number) => (
                    <div className="mobile-l-item" key={index}>
                      <div className="label-value">
                        <span className="label">FULL name</span>
                        <span className="value name">{`${tics.first_name} ${tics.last_name}`}</span>
                      </div>
                      <div className="label-value">
                        <span className="label">NET SALES</span>
                        <span className="value">GBP {tics?.total_sales?.toFixed(2)}</span>
                      </div>
                      <div className="label-value">
                        <span className="label">TOTAL # OF ORDERS</span>
                        <span className="value">{tics.orderCount}</span>
                      </div>
                      <div className="label-value">
                        <span className="label">EARN COMMISSION</span>
                        <span className="value">GBP {tics?.commissions?.toFixed(2)}</span>
                      </div>
                      <div className="label-value">
                        <span className="label">CVR</span>
                        <span className="value">{tics?.cvr?.toFixed(2)} %</span>
                      </div>
                      <div className="label-value">
                        <span className="label">website visits</span>
                        <span className="value">{tics.clickCount}</span>
                      </div>
                    </div>
                  ))
                }
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}
