import {
  PartnerAnalytics,
  ProductCatalogue,
  ContentHub,
  CommunityHub,
  CreatorVerification,
  Onboard,
} from "components/Icons";

export const menuItems = [
  { icon: CreatorVerification, label: "Creator Management", to: "/" },
  // { icon: CreatorDiscovery, label: "Creator Discovery", to: "/discovery" },
  {
    icon: PartnerAnalytics,
    label: "Partner Analytics",
    to: "/partner-analytics",
  },
  { icon: ProductCatalogue, label: "Product Catalogue", to: "/products" },
  { icon: CommunityHub, label: "Community Hub", to: "/community" },
  { icon: CommunityHub, label: "Payout Requests", to: "/payouts" },
  { icon: ContentHub, label: "Content Hub", to: "/content" },
  { icon: Onboard, label: "Welcome pack", to: "/onboard-products" },
];
