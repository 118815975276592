import React from 'react'

export default function Empty() {
  return (
    <div className="empty-state">
      <img src={require('assets/images/emptylist.png')} alt="" />
      <h2>Nothing here... yet</h2>
    </div>
  )
}
