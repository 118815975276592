import { Input } from 'antd';
import { EyeOn, EyeOff } from 'components/Icons';

import './styles.scss';

interface FieldProps {
  type?: 'text' | 'email' | 'password' | 'number';
  label?: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  initialValue?: string;
  status?: string;
  WithTopRight?: any;
  prefixSelector?: any;
  onChange?: (e?: any) => void;
  showCount?: boolean;
  maxLength?: number;
  className?: string;
  bottomLabel?: boolean;
  LabelIcon?: any;
  onBlur?: any;
  onPressEnter?: any;
}

export default function Field({
  type, disabled, value, required, label, placeholder, WithTopRight, prefixSelector, maxLength, showCount, LabelIcon, className, onChange, onBlur, onPressEnter }: FieldProps) {
  return (
    <div className={`form-item ${showCount && "plusCount"} ${className}`}>
      {WithTopRight &&
        <div className="top-right">
          <WithTopRight />
        </div>
      }
      {label && <label style={{ textTransform: 'none' }} htmlFor="">
        {label || ''}
        {LabelIcon && <span className="labelIcon"><LabelIcon /></span>}
        {required && <span className='required'>*</span>}
      </label>}

      {type === 'password'
        ?
        <Input.Password
          placeholder={placeholder || ''}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          required={required}
          iconRender={(visible) => (visible ? <EyeOn /> : <EyeOff />)}
        />
        : <Input placeholder={placeholder || ''} type={type || 'text'} addonBefore={prefixSelector} showCount={showCount} maxLength={showCount ? maxLength || 20 : 200}
          disabled={disabled} value={value} required={required} className="input-textfield" onChange={onChange} onPressEnter={onPressEnter} />
      }
    </div>
  )
}
