import './styles.scss'
interface TrendStatProps {
  label: string;
  value: string;
  rate?: string | number;
  pve?: boolean;
  nve?: boolean;
}
export default function TrendStat({ label, value, rate, pve, nve }: TrendStatProps) {
  return (
    <div className="trend-data">
      <div className="label">{label || 'N/A'}</div>
      <div className="value">{value || 'N/A'}</div>
      <div className="trend">
        {pve && <img src={require('assets/images/trend-up.png')} alt="" />}
        {nve && <img src={require('assets/images/trend-down.png')} alt="" />}
        <div className={`${nve && 'nve'} ${pve && 'pve'} rate`}>{rate}</div>
      </div>
    </div>
  )
}
