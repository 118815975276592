import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button } from 'components/forms';
import { SidebarLayout } from 'components/layout';
import { ProductCatalogue } from 'components/Icons';
import * as actions from '../../products/_redux/products/Actions';
import './styles.scss';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";



export function OnboardProducts() {
  const dispatch = useDispatch<any>();
  const [crumbs] = useState([{ label: 'onboard-products', to: '/products' }])
  const [selectedItems, setSelectedItems] = useState<any>([]);


  const { products, creator_starter_pack } = useSelector(
    (state: any) => ({
      products: state.products.products,
      creator_starter_pack: state.products.creator_starter_pack
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchAllData('products', `?all=true`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('') + '...'
    }
    return newStr;
  }

  const updateList = (id: string) => {
    const selectsClone = [...selectedItems];
    if (selectsClone.includes(id)) {
      selectsClone.map((ele: any, index: number) => {
        if (ele === id) {
          selectsClone.splice(index, 1);
          setSelectedItems(selectsClone);
        }
        return true;
      })
    } else {
      // if (selectsClone.length >= 6) {
      //   toast.warn('Cannot select more than six item');
      // } else {
      selectsClone.push(id);
      setSelectedItems(selectsClone);
      // }
    }
  }

  useEffect(() => {
    setSelectedItems(creator_starter_pack)
  }, [creator_starter_pack])


  useEffect(() => {
    if (!creator_starter_pack) {
      dispatch(actions.fetchAllData('starter'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creator_starter_pack])



  function sendData() {
    var b: any = {}; b['products'] = selectedItems;
    dispatch(actions.addData(b, 'selected_products'));
  }

  return (
    <SidebarLayout
      headerTitle="onboard products"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      <div className="onboard-details-container">
        <div className="products-picks">
          {products && products.map((ele: any, index: number) => (
            <div className="check-product">
              {selectedItems && selectedItems.includes(ele._id) ? <img src={require('assets/images/checked.png')} className="checked-img" alt="" /> : ''}
              <label htmlFor={`${ele.name}-${index}`}>
                <img className="icon" src={ele.thumbnail} alt="" />
                <Link to={`/product/${ele._id}`} className="tt">
                  {limitStr(ele.name, 22)}
                  <Popover content={ele?.name} title="Product description" trigger="click">
                    {' '}<InfoCircleOutlined />
                  </Popover>
                </Link>
                <div className="subtt" style={{ marginBottom: 30 }} dangerouslySetInnerHTML={{ __html: limitStr(ele.description, 60) }} />
              </label>
              {selectedItems && selectedItems.includes(ele._id)
                ? <Button variant='plain' label="Remove" onClick={() => updateList(ele._id)} />
                : <Button variant='secondary' label="Add to list" onClick={() => updateList(ele._id)} />
              }
            </div>
          ))
          }
        </div>

        <div className="actions" style={{ marginTop: 20, }}>
          <Button variant="primary"
            onClick={() => sendData()}
            label={"Save Changes"}
          />
        </div>
      </div>
    </SidebarLayout>
  )
}
