import { useState, useEffect, useRef } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { message, Table, Button } from 'antd';
import { CommunityHub } from 'components/Icons';
import { SidebarLayout } from 'components/layout';
import { getEventsAll } from '../_redux/Actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Empty } from 'components/shared';
import { CSVLink } from 'react-csv';
import './styles.scss';

export default function AttendeesList(props: any) {
  const dispatch = useDispatch<any>();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [, contextHolder] = message.useMessage();
  const [crumbs] = useState<any>([{ label: 'EVENTS & EXPERIENCES', to: '/community' }])
  const [headers, setHeaders] = useState([]);
  const params = useParams();
  const event_id: any = params?.id;

  const columns: ColumnsType<any> = [
    {
      title: 'Attendee',
      dataIndex: 'name',
      render: (text: string, full: any) => {
        return <span>{full.first_name} {full.last_name}</span>
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      render: (text: string, full: any) => {
        return <span>{full.email}</span>
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      render: (text: string, full: any) => {
        return `${full?.phone || 'N/A'}`
      },
    },
    {
      title: 'City',
      dataIndex: 'priority',
      render: (text: string, full: any) => {
        return `${full.city}`
      },
    },
  ];

  const { attendees } = useSelector(
    (state: any) => ({
      attendees: state.community.attendees,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEventsAll('attendees', `?event_id=${event_id}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (attendees && attendees.length > 0) {
      var headers: any = [
        { label: "Attendee", key: "first_name" },
        { label: "Email", key: "email" },
        { label: "Phone number", key: "phone" },
        { label: "city", key: "city" },
      ];
      setHeaders(headers)
    }
  }, [attendees])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  return (
    <SidebarLayout
      headerTitle="COMMUNITY HUB"
      headerIcon={CommunityHub}
      crumbs={crumbs}
    >
      {contextHolder}

      <div className="community-hub-container">
        <div className="header-section">
          <h2 className="section-title">Attendees</h2>
        </div>
        <div className="search-content">
          {attendees && attendees.length > 0 &&
            <CSVLink
              filename={`attendees.csv`}
              data={attendees} headers={headers}>
              <Button type="text">EXPORT CSV</Button>
            </CSVLink>}
        </div>
        {attendees && attendees.length > 0
          ? <>
            {windowSize?.current[0] >= 768 &&
              <>
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={attendees}
                />
              </>
            }

            {windowSize?.current[0] < 768 &&
              <div className="mobile-list-view">
                {attendees && attendees.length > 0 &&
                  attendees.map((attendee: any, index: number) => (
                    <div className="mobile-l-item" key={index}>
                      <div className="label-value">
                        <span className="label">FULL name</span>
                        <span className="value name">{`${attendee.first_name} ${attendee.last_name}`}</span>
                      </div>
                      <div className="label-value">
                        <span className="label">Email</span>
                        <span className="value">{attendee.email}</span>
                      </div>
                      <div className="label-value">
                        <span className="label">Phone</span>
                        <span className="value">{attendee?.phone || 'N/A'}</span>
                      </div>
                      <div className="label-value">
                        <span className="label">City</span>
                        <span className="value">{attendee.city}</span>
                      </div>
                    </div>
                  ))
                }
              </div>
            }
          </>
          : <Empty />
        }
      </div>
    </SidebarLayout>
  )
}
