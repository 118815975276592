import { useState, useEffect } from 'react';
import { Dropdown, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Masonry from 'react-masonry-css'
import { getEventsAll } from '../../_redux/Actions';
import { Button, Field } from 'components/forms';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { deleteEvent, clearEventDetails, resetEventsAll } from '../../_redux/Actions';
import { toast } from 'react-toastify';
import { Empty } from 'components/shared';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const breakpointColumnsObj = {
  default: 3,
  1200: 2,
  767: 1
};

export default function Events(props: any) {
  const { menuItems } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [, setLoading] = useState<boolean>(true);
  const [selectedCategory, setCategory] = useState('All');
  const [slEvents, setSlEvents] = useState<any>([]);
  const [q, setQ] = useState('');

  const { events, brands } = useSelector(
    (state: any) => ({
      actionsLoading: state.community.actionsLoading,
      events: state.community.events,
      brands: state.products.brands,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(resetEventsAll());
    dispatch(getEventsAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (events && events.length > 0) {
      const otherEvents: any = [];
      let spotEvent: any = {};
      let eEvents = [...events];
      eEvents.map((event: any) => {
        if (event?.spotlight) {
          spotEvent = event;
        } else {
          otherEvents.push(event);
        }
        return true;
      });
      otherEvents.sort((a: any, b: any) => {
        if (new Date(a.date) > new Date(b.date)) {
          return 1;
        }
        if (new Date(a.date) < new Date(b.date)) {
          return -1
        };
        return 0;
      });
      if (spotEvent && spotEvent?._id) otherEvents.unshift(spotEvent);
      setTimeout(() => {
        setSlEvents([...otherEvents])
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  const handleMenuClick = (el: any) => {
    setCategory(el?.item?.props?.title)
    filterDataByWeek(events, el?.key);
  }

  function filterDataByWeek(data: any, key = '2') {
    if (key === '1') {
      dispatch(getEventsAll());
      return;
    }

    const today = new Date();
    const thisWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const nextWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    var date_to_measure = new Date();
    if (key === '2') {
      date_to_measure = thisWeekStartDate;
    } else {
      date_to_measure = nextWeekStartDate;
    }
    // Filter out data that is not in next week
    const newData = data.filter((item: any) => {
      const itemDate = new Date(item.date);
      return itemDate >= date_to_measure && itemDate < new Date(date_to_measure.getFullYear(), date_to_measure.getMonth(), date_to_measure.getDate() + 7);
    });

    setSlEvents(newData);
    // dispatch(setEvent(newData));

  }

  const limitStr = (str: string, length: number) => {
    if (str) {
      const spl = str.split('');
      let newStr = str;
      if (spl.length > length) {
        spl.length = length;
        newStr = spl.join('') + '...'
      }
      return newStr;
    }
    return str;
  }

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const confirm = (id: React.MouseEvent<HTMLElement> | any) => {
    setLoading(true);
    dispatch(deleteEvent(id)).then((data: any) => {
      if (data && data.success) {
        toast.success('Deleted successfully');
      }
      dispatch(resetEventsAll())
      dispatch(getEventsAll());
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('An error occured. Please retry later.');
    })
  };

  const cancel = (e: React.MouseEvent<HTMLElement> | any) => {
    console.log(e);
  };

  const createEvent = () => {
    dispatch(clearEventDetails());
    return navigate('/community/event/edit');
  }

  // const filterList = (val: string) => {
  //   const cloneList = [...events];
  //   const newList: any = [];
  //   if (cloneList && cloneList.length > 0) {
  //     cloneList.map((ele: any) => {
  //       if (ele.title.toLowerCase().includes(val.toLowerCase())) newList.push(ele);
  //     })
  //   }
  //   setSlEvents(newList)
  // }

  const initSearch = () => {
    if (q !== '') {
      dispatch(getEventsAll('search', `?q=${q}`));
    } else {
      dispatch(getEventsAll());
    }
  }

  const getHost = (brandId?: string) => {
    const cbrands = [brands];
    const foundBrand = 'Admin'
    console.log('brandId: ', brandId);
    if (brands && brands.length > 0 && brandId) {
      cbrands.map((brand: any) => {
        console.log(brandId, brand);
        // if (brand?.brand_id === brandId) {
        // }
        return true;
      })
    }
    return foundBrand;
  }

  return (
    <>
      <div className="header-section">
        <h2 className="section-title">Upcoming Events</h2>
        <button className="btn btn-img" onClick={() => createEvent()}>
          <img src={require('assets/images/upload-plus.png')} alt="" />
          <span>Add Event</span>
        </button>
      </div>
      <div className="search-content">
        <Field placeholder="e.g. First Session" type="text" label="Search" onChange={(e) => setQ(e.target.value)} onPressEnter={initSearch} />
        <div className="dorpdown">
          <span>Filter: Date</span>
          <Dropdown menu={menuProps} trigger={['click']}>
            <div className="user">
              <span className="labele">{selectedCategory}</span>
              <DownOutlined color="#370E78" />
            </div>
          </Dropdown>
        </div>
      </div>
      {slEvents && slEvents.length > 0
        ? <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {slEvents.map((el: any, index: number) => (
            <div className="content-list-item" key={index}>
              <div className="actions">
                <button onClick={() => navigate(`/community/event/edit/${el._id}`)}>
                  <img src={require('assets/images/edit.png')} alt="" />
                </button>
                <Popconfirm
                  title="Delete this event"
                  description={`Are you sure to delete "${el.title}"?`}
                  onConfirm={(b: any) => confirm(el._id)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <button>
                    <img src={require('assets/images/trash.png')} alt="" />
                  </button>
                </Popconfirm>
              </div>
              {el?.spotlight && <span className="spotlight">EVENT SPOTLIGHT</span>}
              <div className="feature-image">
                <img src={el?.banner || require('assets/images/holder.png')} alt="" className={`banner-in ${el?.spotlight && "spotty"}`} />
              </div>
              <h2 className="host">{getHost(el?.brand_id)}</h2>
              <h3 className="title">{limitStr(el?.title, 44)}
              <Popover 
              content={
                <div>
                  {el?.description.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^a-zA-Z0-9 ]/g, "")}
                </div>
              }
              title={el?.title || `Event description`} trigger="click">
                {' '}<InfoCircleOutlined />
              </Popover>
              </h3>
              <div className="description" dangerouslySetInnerHTML={{ __html: limitStr(el?.description, 200) }} />
              <div className="meta">
                <img src={require('assets/images/location.png')} alt="" />
                <span>{el?.location}</span>
              </div>
              <div className="meta">
                <img src={require('assets/images/calendar.png')} alt="" />
                <span>{el?.date ? moment(el?.date).format('Do MMM, YYYY') : 'N/A' || 'not available'}</span>
              </div>
              <div className="meta">
                <img src={require('assets/images/time.png')} alt="" />
                <span>{new Date(el?.date)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) || 'not available'}</span>
                {/* <span>{moment(el?.date).format('Do MMM, YYYY')}</span> */}
              </div>
              <div className="meta">
                <img src={require('assets/images/humans.png')} alt="" />
                <span>{el?.capacity || 'not available'}</span>
                {/* <span>{(el?.capacity - el?.capacity_remaining) || 0}/{el?.capacity || 'not available'}</span> */}
              </div>
              <Button label="View Attendees" variant="primary" onClick={() => navigate(`/community/event/${el._id}/attendees`)} />
            </div>
          ))
          }
        </Masonry>
        : <Empty />
      }
    </>
  )
}
