import React from "react";
import { Route, Routes } from 'react-router-dom'
import VerificationList from "./pages/main";

export function VerificationsPage() {
  return (
    <Routes>
      <Route path="/" element={<VerificationList />} />
    </Routes>
  )
}


