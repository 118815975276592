import { useState, useEffect } from 'react'
import { Rate, Skeleton } from 'antd';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import { ProductCatalogue } from 'components/Icons'
import { SidebarLayout } from 'components/layout'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';



export default function ProductPreview() {
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<any>([{ label: 'brands', to: '/products' }])
  const [previewImage, setPreviewImage] = useState<number>(0);

  let stateData = location.state;
  const { brandDetails, productDetails } = useSelector(
    (state: any) => ({
      productDetails: state.products.productDetails,
      brandDetails: state.products.brandDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (productDetails && brandDetails) {
      setCrumbs([
        { label: 'brands', to: '/products' },
        { label: brandDetails?.name, to: `/brand/${brandDetails._id}` },
        { label: productDetails.name, to: `/product/${productDetails._id}` },
      ])
    }
  }, [productDetails, brandDetails])

  const selectThumbIndex = (index: number) => {
    setPreviewImage(index);
  }

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      {stateData && stateData.name
        ? <>
          <div className="products-details-container">
            {stateData?.extImages?.length > 0
              ? <div className="preview">
                <div className="sliderr">
                  {stateData?.extImages?.length > 0 &&
                    <>
                      {stateData.extImages.map((image: any, index: number) => (
                        <div className={`product-image ${previewImage === index ? 'anymate' : 'hide'}`} key={index}>
                          <img src={stateData.extImages[index]} alt="" style={{ width: '100%', height: '100%' }} />
                        </div>
                      ))}
                    </>
                  }
                  <div className="control">
                    {stateData.extImages.map((image: any, index: number) => (
                      <div className={`tnail ${previewImage === index && 'active'}`} onClick={() => selectThumbIndex(index)}>
                        <img src={image} alt="*" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              : ""
            }
            {stateData.thumbnail && !stateData.extImages
              ? <div className="preview">
                <div className="sliderr">
                  {stateData.thumbnail &&
                    <>
                      <div className={`product-image anymate`}>
                        <img src={stateData.thumbnail} alt="" style={{ width: '100%', height: '100%' }} />
                      </div>
                    </>
                  }
                  <div className="control">
                    <div className={`tnail active`}>
                      <img src={stateData.thumbnail} alt="*" />
                    </div>
                  </div>
                </div>
              </div>
              : ""
            }
            <div className="details">
              <div className="brand-logo">
                {stateData?.brandDetails &&
                  <h2 className="product-head">{brandDetails.name.toUpperCase()}</h2>
                }
              </div>
              <h2 className="product-title">{stateData.name}</h2>
              <div className="description" dangerouslySetInnerHTML={{ __html: stateData.description }} />
              <span className="price">Price to Consumer: <span>${stateData.price}</span></span>
              {stateData?.commission && <span className="earn">Earn: ${stateData.commission.toFixed(2)}</span>}
              <div className="ratings">
                <Rate
                  disabled
                  allowHalf
                  defaultValue={0}
                />
              </div>
            </div>
          </div>
          <div className="video-container">
            <h2 className="section-title">Video usage & tutorials</h2>
            {/* <Slider {...vidSettings} className="video-slider">
              {productDetails && JSON.parse(productDetails?.product_videos || '[]').map((d: any, index: number) => (
                <div className="video" key={index} style={{
                  height: 400,
                  width: 400,
                  marginRight: 20
                }}>
                  <Vimeo
                    video={d}
                    width={640}
                    height={480}
                    autoplay={false}
                  />
                </div>
              ))}
            </Slider> */}
          </div>
        </>
        : <Skeleton />
      }
    </SidebarLayout >
  )
}
