

export default function WelcomePack({ data, products, welcome_orders }: any) {

  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('') + '...'
    }
    return newStr;
  }


  if (!data) {
    return null;
  }
  return (
    <div>
      <div className="pairs">
        <div className="data-pairs">
          <div className="label-value">
            <span className="label">City</span>
            <span className="value">{data.city}</span>
          </div>
          <div className="label-value">
            <span className="label">State / Province / Region</span>
            <span className="value">{data.state}</span>
          </div>
        </div>
        <div className="data-pairs">
          <div className="label-value">
            <span className="label">Address</span>
            <span className="value">{data.address}</span>
          </div>
          <div className="label-value">
            <span className="label">Appartment / Suite</span>
            <span className="value">{data.building}</span>
          </div>
        </div>
        <div className="data-pairs">
          <div className="label-value">
            <span className="label">Country</span>
            <span className="value">{data.country.toUpperCase()}</span>
          </div>
          <div className="label-value">
            <span className="label">Postal code</span>
            <span className="value">{data.postal_code}</span>
          </div>
        </div>


        {
          welcome_orders?.map((ele: any, index: number) => {
            return (
              <div className="data-pairs">
                <div className="label-value">
                  <span className="label">{`Welcome Pack order #${index+ 1}`}</span>
                  <span className="value">Name: {ele?.order_data?.name.toUpperCase()}</span>
                  {/* <span className="value">Draft Order ID: {ele?.draft_order?.id}</span> */}
                  <span className="value">Order ID: {ele?.order_id}</span>
                  <span className="value">Status: {ele?.status?.toUpperCase()}</span>
                  <span className="value">Fulfilment Status: {ele?.fulfillment_status?.toUpperCase() || 'STATUS UNAVAILABLE'}</span>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="welcome-products">
        {products && products.map((ele: any, index: number) => (
          // <Checkbox onChange={(e) => set_pr(e.target.checked, index)} key={index}>
          <div className="check-product" key={index}>
            <img src={ele.thumbnail} alt="" />
            <div className="tt">{limitStr(ele.name, 22)}</div>
            <div className="subtt" dangerouslySetInnerHTML={{ __html: limitStr(ele.description, 60) }} />
          </div>
          // </Checkbox>
        ))}
        {/* <div className="mt-8 w-100">
        <Button variant="primary" label="Track Shipping" />
        </div> */}
      </div>
    </div>
  )
}
