import { useState, useEffect } from 'react'
import { message, Radio, } from 'antd';
import { shallowEqual, useSelector } from "react-redux";
import { CommunityHub as ComHub } from 'components/Icons';
import { SidebarLayout } from 'components/layout'
import { Bookings, Disputes, Events } from './meta';
import './styles.scss';
import { useNavigate, useLocation } from 'react-router-dom';


const menuItems: any = [
  {
    label: 'All',
    key: '1',
    title: 'All'
  },
  {
    label: 'THIS WEEK',
    key: '2',
    title: 'THIS WEEK'
  },
  {
    label: 'NEXT WEEK',
    key: '3',
    title: 'NEXT WEEK'
  },
];

const ubookings = [
  {
    _id: '123456',
    first_name: 'john',
    last_name: 'doe',
    topic: 'First Session',
    advisor: 'James Doe',
    datetime: '01/02/2022 05:30PM (WAT)',
    link: 'https://google.com'
  },
  {
    _id: '12456',
    first_name: 'john',
    last_name: 'doe',
    topic: 'First Session',
    advisor: 'James Doe',
    datetime: '01/02/2022 05:30PM (WAT)',
    link: 'https://google.com'
  },
  {
    _id: '23456',
    first_name: 'john',
    last_name: 'doe',
    topic: 'First Session',
    advisor: 'James Doe',
    datetime: '01/02/2022 05:30PM (WAT)',
    link: 'https://google.com'
  },
  {
    _id: '12345',
    first_name: 'john',
    last_name: 'doe',
    topic: 'First Session',
    advisor: 'James Doe',
    datetime: '01/02/2022 05:30PM (WAT)',
    link: 'https://google.com'
  },
  {
    _id: '12346',
    first_name: 'john',
    last_name: 'doe',
    topic: 'First Session',
    advisor: 'James Doe',
    datetime: '01/02/2022 05:30PM (WAT)',
    link: 'https://google.com'
  },
  {
    _id: '12356',
    first_name: 'john',
    last_name: 'doe',
    topic: 'First Session',
    advisor: 'James Doe',
    datetime: '01/02/2022 05:30PM (WAT)',
    link: 'https://google.com'
  },
  {
    _id: '2345',
    first_name: 'john',
    last_name: 'doe',
    topic: 'First Session',
    advisor: 'James Doe',
    datetime: '01/02/2022 05:30PM (WAT)',
    link: 'https://google.com'
  }
]
const disputes = [
  {
    _id: '123456',
    first_name: 'john',
    last_name: 'doe',
    dispute: 'Commission dispute',
    assignee: 'James Doe',
    priority: 'High priority: response within 24 hours',
    status: 'pending',
  },
  {
    _id: '12456',
    first_name: 'john',
    last_name: 'doe',
    dispute: 'Commission dispute',
    assignee: 'James Doe',
    priority: 'High priority: response within 24 hours',
    status: 'pending',
  },
  {
    _id: '23456',
    first_name: 'john',
    last_name: 'doe',
    dispute: 'Commission dispute',
    assignee: 'James Doe',
    priority: 'High priority: response within 24 hours',
    status: 'pending',
  },
  {
    _id: '12345',
    first_name: 'john',
    last_name: 'doe',
    dispute: 'Commission dispute',
    assignee: 'James Doe',
    priority: 'High priority: response within 24 hours',
    status: 'pending',
  },
  {
    _id: '12346',
    first_name: 'john',
    last_name: 'doe',
    dispute: 'Commission dispute',
    assignee: 'James Doe',
    priority: 'High priority: response within 24 hours',
    status: 'pending',
  },
  {
    _id: '12356',
    first_name: 'john',
    last_name: 'doe',
    dispute: 'Commission dispute',
    assignee: 'James Doe',
    priority: 'High priority: response within 24 hours',
    status: 'pending',
  },
  {
    _id: '2345',
    first_name: 'john',
    last_name: 'doe',
    dispute: 'Commission dispute',
    assignee: 'James Doe',
    priority: 'High priority: response within 24 hours',
    status: 'pending',
  }
]
const contentList = [
  {
    id: "content-1",
    spotlight: true,
    image: require('assets/images/ch1.png'),
    title: "How to discover products",
    host: 'Rose INC',
    location: 'New York, USA ',
    date: '12. 12. 2022',
    time: '12:30 PM (WAT)',
    description:
      "You can access the product catalogue by selecting the tap on the top menu bar. Discover new products per brand, product category..",
  },
  {
    id: "content-2",
    image: require('assets/images/ch2.png'),
    title: "Affiliate links or codes?",
    host: 'Rose INC',
    location: 'New York, USA ',
    date: '12. 12. 2022',
    time: '12:30 PM (WAT)',
    description:
      "Within The Creator Community, you'll be able to choose between creating an affiliate link to include on your social profiles or generate a ...",
  },
  {
    id: "content-3",
    image: require('assets/images/ch3.png'),
    title: "Navigating your dashboard",
    host: 'Rose INC',
    location: 'New York, USA ',
    date: '12. 12. 2022',
    time: '12:30 PM (WAT)',
    description:
      "You can access your your analytics dashboard by selecting the tap on the top menu bar. Here you'll fins your earnings summary, and....",
  },
  {
    id: "content-4",
    image: require('assets/images/ch4.png'),
    title: "Understanding your earnings",
    host: 'Rose INC',
    location: 'New York, USA ',
    date: '12. 12. 2022',
    time: '12:30 PM (WAT)',
    description:
      "You can access the product catalogue by selecting the tap on the top menu bar. Discover new products per brand, product category...",
  },
  {
    id: "content-5",
    image: require('assets/images/ch5.png'),
    title: "How to book 1on1 sessions?",
    host: 'Rose INC',
    location: 'New York, USA ',
    date: '12. 12. 2022',
    time: '12:30 PM (WAT)',
    description:
      "You can access the product catalogue by selecting the tap on the top menu bar. Discover new products per brand, product category...",
  },
  {
    id: "content-6",
    image: require('assets/images/ch6.png'),
    title: "How to request help?",
    host: 'Rose INC',
    location: 'New York, USA ',
    date: '12. 12. 2022',
    time: '12:30 PM (WAT)',
    description:
      "You can access the product catalogue by selecting the tap on the top menu bar. Discover new products per brand, product category...",
  },
];

export default function CommunityHub() {
  const [, setKCreator] = useState([]);
  const [tab, setTab] = useState('sessions');
  const [topCrumb, setTopCrumb] = useState(' / sessions');
  const keyedCreators: any = [];
  const [, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  
  const crumbs = [
    { label: topCrumb, to: '/community-hub' },
  ]

  const { bookings } = useSelector(
    (state: any) => ({
      bookings: state.community.bookings,

    }),
    shallowEqual
  );

  useEffect(() => {
    if (bookings && bookings.length > 0) {
      bookings.map((booking: any) => {
        keyedCreators.push({ ...booking, key: booking._id })
        return true
      });
      setKCreator(keyedCreators);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings])

  useEffect(() => {
    if (tab === 'sessions') setTopCrumb(' / Virtual Sessions')
    if (tab === 'events') setTopCrumb(' / Event & Expeeriences')
    if (tab === 'help') setTopCrumb(' / Help Centre')
  }, [tab])


  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    let path_curr = currentPath.split("/").pop();
    if (path_curr === 'help') {
      setTab("help")
    } else if (path_curr === 'events') {
      setTab("events")
    } else {
      setTab("sessions")
    }
    // console.log(path_curr)

  }, [location.pathname]);


  return (
    <SidebarLayout
      headerTitle="COMMUNITY HUB"
      headerIcon={ComHub}
      crumbs={crumbs}
    >
      {contextHolder}
      <div className="menu-group community">
        <Radio.Group onChange={(e: any) => {
          navigate(`/community/${e.target.value}`);
          // setTab(e.target.value)
        }} defaultValue={tab}>
          <Radio.Button value="sessions">VIRTUAL SESSIONS</Radio.Button>
          <Radio.Button value="events">EVENTS & EXPERIENCES</Radio.Button>
          <Radio.Button value="help">HELP CENTRE</Radio.Button>
        </Radio.Group>
      </div>
      <div className="community-hub-container">
        {tab === 'sessions' && <Bookings menuItems={menuItems} ubookings={ubookings} />}
        {tab === 'events' && <Events menuItems={menuItems} contentList={contentList} />}
        {tab === 'help' && <Disputes menuItems={menuItems} disputes={disputes} />}
      </div>

    </SidebarLayout>
  )
}
