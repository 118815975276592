import React from "react";
import { Route, Routes } from 'react-router-dom'
import { ProductEdit, BrandEdit } from "./pages";

export function ProductsPage() {
  return (
    <Routes>
      <Route path="/product/edit" element={<ProductEdit />} />
      <Route path="/brand/edit" element={<BrandEdit />} />
    </Routes>
  )
}


