import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import { ConfigProvider } from 'antd';
import reportWebVitals from './reportWebVitals';

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import * as _redux from './setup'
import store, { persistor } from './setup/redux/Store'
import axios from 'axios'
import { App } from './app/App'

import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'antd/dist/reset.css';
import 'styles/index.scss';

import { render } from "react-dom"; 

const { PUBLIC_URL } = process.env
_redux.setupAxios(axios, store)



render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#370E78',
        },
      }}
    >
      <PersistGate persistor={persistor} loading={null}>
        <App basename={PUBLIC_URL} />
      </PersistGate>

    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   // <StrictMode>
//   <Provider store={store}>
//     <ConfigProvider
//       theme={{
//         token: {
//           colorPrimary: '#370E78',
//         },
//       }}
//     >
//       <PersistGate persistor={persistor} loading={null}>
//         <App basename={PUBLIC_URL} />
//       </PersistGate>

//     </ConfigProvider>
//   </Provider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

