import { useState, useEffect } from 'react'
import { Skeleton } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import { ProductCatalogue } from 'components/Icons'
import { SidebarLayout } from 'components/layout'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';


export default function BrandPreview() {
  const navigate = useNavigate();
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<any>([{ label: 'brands', to: '/products' }])

  let stateData = location.state;
  const { brandDetails } = useSelector(
    (state: any) => ({
      productDetails: state.products.productDetails,
      brandDetails: state.products.brandDetails,
    }),
    shallowEqual
  );


  useEffect(() => {
    if (stateData.brandDetails) {
      setCrumbs([
        { label: 'brands', to: '/products' },
        { label: brandDetails?.name, to: `/brand/${brandDetails._id}` },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDetails])

  console.log('stateData: ', stateData);

  return (
    <SidebarLayout
      headerTitle="Product Catalogue"
      headerIcon={ProductCatalogue}
      crumbs={crumbs}
    >
      {stateData && stateData.name
        ? <>
          <div className="brand-details-container">
            <div className="banner" style={{ backgroundImage: `url(${stateData.banner})` }}>
              <h1 className="brand-name">{stateData && stateData.name}</h1>
              <button className="btn btn-img banner-editable" onClick={() => navigate(-1)}>
                <img src={require('assets/images/edit-white.png')} alt="" />
                <span>Go back</span>
              </button>
            </div>
            <section className="brand-info">
              <h2 className="section-title">{stateData?.header_description || stateData.name}</h2>
              <div className="description" dangerouslySetInnerHTML={{ __html: stateData.description }} />
            </section>
            {/* <section className="commission">
              <h2 className="section-title">Commission scheme across all portfolio</h2>
            </section> */}
            <section className="brand-listings">
              <div className="title-section">
                <h2 className="ttle">Products</h2>
                <div className="dorpdown">
                </div>
              </div>
              <div className="flex items-center justify-center my-4">
              </div>
            </section>
          </div>
        </>
        : <Skeleton />
      }
    </SidebarLayout >
  )
}
