import { useEffect } from 'react'
import { ForwardArrow } from 'components/Icons';
import { getCreatorSocials } from "../../_redux/verifications/Actions";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// const options = [
//   { icon: <img src={require('assets/images/facebook.png')} alt="" />, label: 'Facebook' },
//   { icon: <img src={require('assets/images/google.png')} alt="" />, label: 'Google Analytics' },
//   { icon: <img src={require('assets/images/instagram.png')} alt="" />, label: 'Instagram' },
//   { icon: <img src={require('assets/images/pinterest.png')} alt="" />, label: 'pinterest' },
//   { icon: <img src={require('assets/images/tiktok.png')} alt="" />, label: 'TikTok' },
//   { icon: <img src={require('assets/images/snapchat.png')} alt="" />, label: 'snapchat' },
//   { icon: <img src={require('assets/images/twitter.png')} alt="" />, label: 'Twitter' },
//   { icon: <img src={require('assets/images/youtube.png')} alt="" />, label: 'YouTube' },
// ]

export default function Connects() {

  const dispatch = useDispatch<any>();
  const params = useParams();
  const creatorId: any = params?.id;
  const { creatorSocialsAll } = useSelector(
    (state: any) => ({
      creatorDetails: state.verifications.creatorDetails,
      actionsLoading: state.verifications.actionsLoading,
      listLoading: state.verifications.listLoading,
      creatorSocialsAll: state.verifications.creatorSocialsAll
    }),
    shallowEqual
  );

  useEffect(() => {
    if (creatorId) {
      dispatch(getCreatorSocials(creatorId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const isValidUrl = (urlString: string) => {
  //   var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
  //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
  //     '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
  //     '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  //   return !!urlPattern.test(urlString);
  // }

  function isValidUrl(string: any) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const navigateNext = (social: any) => {
    if (isValidUrl(social?.username)) {
      window.open(social?.username, '_blank');
    } else {
      window.open(`https://${social.type}.com/${social?.username}`, '_blank');
    }
  }

  return (
    <div>
      {creatorSocialsAll && creatorSocialsAll.length > 0
        ?
        <>
          {creatorSocialsAll.map((social: any, index: number) => (
            <div className="socials-link" key={index} onClick={() => navigateNext(social)}>
              <div className="flex frame">
                <div className="icon">
                  <img src={require(`assets/images/${social.type}.png`)} alt="" />
                  <div className="txt">
                    <span style = {{textTransform: 'capitalize'}}  className='social'>{social.type}</span>
                    <span style = {{textTransform: 'none'}} >{social.username || 'not available'}</span>
                  </div>
                </div>
                <div className="pointt" onClick={() => navigateNext(social)}>
                  <ForwardArrow />
                </div>
              </div>
            </div>
          ))}
        </>
        : <h2 className="mt-2">No social account was found</h2>
      }
    </div>
  )
}
