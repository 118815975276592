import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, Button } from 'components/forms';
import { SidebarLayout } from 'components/layout';
import { ContentHub } from 'components/Icons';
import { getSingleContent, editContentDetails, reset, createPost } from '../_redux/Actions';
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Skeleton, Button as Btn } from 'antd';
import './styles.scss';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
// import 'react-markdown-editor-lite/lib/index.css';
import MDEditor, { selectWord } from "@uiw/react-md-editor";
import TurndownService from 'turndown';




export default function ContentEdit() {
  const dispatch = useDispatch<any>();
  const params = useParams();
  const contentId: any = params?.id;
  const navigate = useNavigate();

  const [value, setValue] = useState<any>('');
  

  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)
  // const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());
  const [editorState, setEditorState] = useState<any>('');
  const [pumpFake, setPumpfake] = useState(1);
  const [fielders] = useState([0]);
  const [videoUrl, setVideoUrl] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [contentImages, setContentImages] = useState<any>([]);
  const { contentDetails } = useSelector(
    (state: any) => ({
      contentDetails: state.contenthub.contentDetails,
    }),
    shallowEqual
  );

  const crumbs = [
    { label: 'getting you started', to: '/content' },
    { label: `${contentId ? 'EDIT content' : 'NEW content'}`, to: `${contentId ? `/content/${contentId}` : '/content/new'}` },
  ];


  function hasHTMLCode(text: string) {
    const htmlRegex = /<[^>]+>/g;
    return htmlRegex.test(text);
  }



  function convertHtmlToMarkdown(htmlContent: string) {
    const turndownService = new TurndownService();
    const markdown = turndownService.turndown(htmlContent);
    return markdown;
  }
  
  

  useEffect(() => {
    if (contentId) {
      dispatch(getSingleContent(contentId));
    } else {
      dispatch(reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contentId) {
      if (contentDetails?.title) {
        setLoading(false)
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }

    if (contentDetails) {
      // const dbText: any = convertFromHTML(contentDetails?.body || '');
      // const editorText = EditorState.createWithContent(dbText);
      setEditorState(hasHTMLCode(contentDetails?.body) ? convertHtmlToMarkdown(contentDetails?.body) : contentDetails?.body);
      setTitle(contentDetails.title);
      setVideoUrl(contentDetails.video_url);
      setImgUrl(contentDetails.thumbnail);
    } else {
      setEditorState('');
      setTitle('');
      setVideoUrl('');
      setImgUrl('');
    }
  }, [contentDetails, contentId])

  const handleContentImageChange = (e: any) => {
    if (e.target.files) {
      const eroes = contentImages;
      eroes.push(e.target.files[0])
      setContentImages(eroes);
      setPumpfake(pumpFake + 1);
      const url = URL.createObjectURL(e.target.files[0]);
      setImgUrl(url);
      setPumpfake(pumpFake + 1);
    }
  }

  const renderFields = () => {
    return (
      fielders.map((_: any, index: number) => (
        <div key={index}>
          <label htmlFor="brandLogo" className="upload-file smaller">
            <div className="flex items-center justify-between">
              {contentImages[index] && contentImages[index]?.name
                ? <>
                  <span style={{ color: '#000', fontSize: '16px' }}>{contentImages[index].name}</span>
                </>
                : <>
                  <img src={require('assets/images/upload-plus.png')} alt="" />
                  <span>UPLOAD FILE</span>
                </>
              }
            </div>
            <div className="empty"></div>
          </label>
          <input id="brandLogo" type="file" accept="image/*" onChange={handleContentImageChange} />
        </div>
      ))
    )
  }

  const addNewContent = () => {
    if (!title || editorState === '' || !contentImages) return toast.error('All fields are compulsory');
    let rawText: any = editorState;
    const payload = new FormData();
    payload.append('title', title)
    payload.append('body', rawText)
    payload.append('video_url', videoUrl)
    payload.append('thumbnail', contentImages[0], contentImages[0]?.name)
    setLoading(true);
    dispatch(createPost(payload)).then((data: any) => {
      toast.success('Success');
      navigate('/content');
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('Error occured while saving post');
    });
  }

  const updateContent = () => {
    if (!title || editorState === '') return toast.error('All fields are compulsory');
    let rawText: any = editorState;
    const payload = new FormData();
    payload.append('title', title);
    payload.append('body', rawText);
    payload.append('content_id', contentId);
    videoUrl && payload.append('video_url', videoUrl)
    if (contentImages && contentImages[0] && contentImages[0].name) payload.append('thumbnail', contentImages[0], contentImages[0]?.name)
    setLoading(true);
    dispatch(editContentDetails(payload, contentId)).then((data: any) => {
      if (data.success) {
        toast.success('Success');
        navigate(-1);
      } else {
        toast.warn('Please retry your request');
      }
    }).catch((error: any) => {
      console.log('error: ', error);
      toast.error('Error occured while updating post');
    })
  }

  const preparePreview = () => {
    if (!title || !editorState.getCurrentContent()) return toast.error('All fields are compulsory');
    let rawText: any = convertToHTML(editorState.getCurrentContent());
    const payload: any = {};
    payload['title'] = title;
    payload['body'] = rawText;
    if (videoUrl) payload['video_url'] = videoUrl;
    if (contentImages) payload['thumbnail'] = URL.createObjectURL(contentImages[0]);
    if (imgUrl) payload['thumbnail'] = imgUrl;
    navigate('/content/preview', { state: payload })
  }


  // const mdParser = new MarkdownIt(/* Markdown-it options */);

  // // Finish!
  // function handleEditorChange(d: any) {
  //   console.log('handleEditorChange', d.html, d.text);
  // }

  return (
    <SidebarLayout
      headerTitle="Content hub"
      headerIcon={ContentHub}
      crumbs={crumbs}
    >
      
      <div className="content-hub-container">
        {loading
          ? <Skeleton />
          : <div className="product-form">
            <div className="title-section">
              <h2 className="ttle">{contentId ? 'Edit Content' : 'Add Content'}</h2>
              <Btn className="dorpdown flex" onClick={preparePreview}>
                <img src={require('assets/images/eye-see.png')} alt="" className="preview-eye" />
                SEE PREVIEW
              </Btn>
            </div>
            <Field type="text" required={true}
              onChange={(e: any) => setTitle(e.target.value)}
              label="Title"
              value={title}
            />
            
            {/* <div data-color-mode="light">
              <MDEditor height={200} value={value} onChange={setValue} />
            </div>   */}
          {/* <MdEditor style={{ height: '500px' }} renderHTML={text => mdParser.render(text)} onChange={handleEditorChange} />
 */}



            <div className="item" style = {{marginBottom: 20}}>
              <label htmlFor="" className="copy-me">Content</label>
              <div data-color-mode="light">
                <MDEditor 
                highlightEnable={false}
                height={200} value={editorState} onChange={setEditorState} />
              </div>  
              
              {/* <Editor
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbarClassName="toolbarClassName"
                editorStyle={{ minHeight: 200, padding: '10px' }}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              /> */}

            </div>


            <Field type="text" required={true}
              onChange={(e: any) => setVideoUrl(e.target.value)}
              label="Video url"
              value={videoUrl}
            />
            <div className="adding-up">
              <div className="item upload">
                <label htmlFor="" className="copy-me">Upload photo</label>
                {renderFields()}
              </div>
            </div>
            {imgUrl &&
              <div className="ext-images">
                <div className="ext-img">
                  <Btn onClick={() => setImgUrl('')}>
                    <DeleteOutlined style={{ color: '#FFF' }} />
                  </Btn>
                  <img src={imgUrl} alt={'file'} />
                </div>
              </div>
            }
            <div className="actions">
              <Button onClick={contentId ? updateContent : addNewContent} variant="primary" label="SAVE CHANGES" />
            </div>
          </div>
        }
      </div>
    </SidebarLayout>
  )
}
