import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'


export function findDatas(type, queryParams) {
  if (type === 'bookings') {
    return axios.get(`${URL}api/booking/all`);
  }
}
export function fetchEventAll(type, q) {
  if (type === 'event') {
    return axios.get(`${URL}api/event/all`);
  } else if (type === 'attendees') {
    return axios.get(`${URL}api/event/attendees${q}`);
  } else if (type === 'disputes') {
    return axios.get(`${URL}api/booking/reports${q}`);
  } else if (type === 'search_disputes') {
    return axios.get(`${URL}api/booking/search_disputes${q}`);
  } else {
    return axios.get(`${URL}api/event/search${q}`);
  }
}
export function fetchEventDetails(id) {
  return axios.get(`${URL}api/event/${id}`);
  // return axios.get(`${URL}api/booking/report/single${id}`); 
  //
}

export function fetchEventDetails2(id) {
  // return axios.get(`${URL}api/event/${id}`);
  return axios.get(`${URL}api/booking/report/single${id}`); 
  //
}

export function fetchDispute(id) {
  return axios.get(`${URL}api/event/${id}`);
}

export function addEvent(payload) {
  return axios.post(`${URL}api/event`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function editEvent(payload) {
  return axios.put(`${URL}api/event`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function addFile(payload) {
  return axios.post(`${URL}api/file`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function editDetails(payload, query) {
  return axios.put(`${URL}api/event${query}`, payload);
}

export function editDispute(payload, query) {
  return axios.put(`${URL}api/booking/reports${query}`, payload);
}

export function deleteSingleEvent(id) {
  return axios.delete(`${URL}api/event/${id}`);
}
