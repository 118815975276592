import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  actionsLoading: false,
  listLoading: false,
  error: false,
  bookings: null,
  events: null,
  eventDetails: null,
  attendees: null,
  disputes: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const CommunitytHubSlice = createSlice({
  name: "community",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reset: (state, action) => {
      state.posts = null;
      state.postDetails = null;
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    booksList: (state, action) => {
      state.listLoading = false;
      state.bookings = action.payload;
      state.error = null;
    },
    attend: (state, action) => {
      state.listLoading = false;
      state.attendees = action.payload;
      state.error = null;
    },
    disputes: (state, action) => {
      state.listLoading = false;
      state.disputes = action.payload;
      state.error = null;
    },
    eventsAll: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.events = action.payload;
    },
    eventDetails: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.eventDetails = action.payload;
    },
    clearEventData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.eventDetails = null;
    },
    resetEvents: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.events = null;
    },
  }
}); 
