import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getCreatorPayments } from "../../../auth/redux/AuthCRUD";
// import * as auth from "../../../auth/redux/AuthRedux";
import markIcon from "assets/svg/markicon.svg";
import { useParams } from 'react-router-dom';
// import { getSingleCreator, fetchAllData } from "../../../verifications/_redux/verifications/Actions";

// import "./styles.scss";

function PaymentInformation() {
  const params = useParams();
  const creatorId: any = params?.id;


  const [bank, set_bank] = useState<any>(null);


  useEffect(() => {
    getPay();
    // eslint-disable-next-line
  }, []);



  function getPay() {
    getCreatorPayments(`?creator_id=${creatorId}`)
      .then((response: any) => {
        var data = response.data;
        if (data.success) {
          set_bank(data.success)
        }
        // dispatch(auth.actions.set_bank(data.success));
      })
      .catch((error: any) => {
        var message =
          error?.response?.data?.message || "Network error occured!";
        toast.error(message ? message : "Network error occured!");
      });
  }


  return (
    <div className="pay_body">


      {bank &&
        bank.map((ele: any, i: number) => {
          return (
            <div key={i} className="pybody-info">
              <div className="pybody-infotop">
                <div className="pybitleft">
                  <div className="pybitleftcheck">
                    <img src={markIcon} alt="" />
                  </div>
                  <label>Payment option {i + 1}</label>
                </div>
              </div>
              <div className="pybody-pi">
                <p>Personal information</p>
                <div className="pybody-pibody">
                  <div className="pip_body-bottomitem">
                    <label>
                      First name{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.first_name}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Last name{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.last_name}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Phone number{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.phone_number}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Email address{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.email_address}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Sort code/IBAN{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.iban}</label>
                    </div>

                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Account number{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.account_number}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pybody-pi pybody-pib">
                <p>Bank address information</p>
                <div className="pybody-pibody">
                  <div className="pip_body-bottomitem">
                    <label>
                      Street name{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.street}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      House number{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.number}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Building Name{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.building}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Floor unit{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.floor}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Postal code{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.postal_code}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      City{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.city}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      State / Province / Region{" "}
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.state}</label>
                    </div>
                  </div>
                  <div className="pip_body-bottomitem">
                    <label>
                      Country
                    </label>
                    <div className="pip_body-bottomitemndit">
                      <label>{ele.country}</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          );
        })}

    </div>
  );
}

export default PaymentInformation;
