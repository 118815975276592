import * as requestFromServer from "./Crud";
import { ContentHubSlice, callTypes } from "./Slice";

const { actions } = ContentHubSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'posts') {
        dispatch(actions.posts(res.success));
        return;
      } else if (type === 'categories') {
        dispatch(actions.categories(res.success));
        return;
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const uploadFile = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addFile(payload)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createPost = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addPost(payload)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Cannot create a blog post at the moment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const editContentDetails = (payload, id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editContent(payload, id)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getContentsAll = (type = 'content', q = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchContentAll(type, q)
    .then(response => {
      let res = response.data;
      // console.log('REESE', res);
      dispatch(actions.contentList(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch content list";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSingleContent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchContentDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.contentDetails(res.success));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch post details";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const reset = () => dispatch => {
  dispatch(actions.reset());
};

export const deleteContent = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSingleContent(id)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};