import { CreatorVerification } from "components/Icons";
import { SidebarLayout } from 'components/layout'
import "./styles.scss";
import PersonalInformation from "./PersonalInformation";


const crumbs = [
  { label: 'ADMIN: Personal Information', to: '/profile' },
]

function Profile() {

  return (
    <SidebarLayout headerTitle="PROFILE" headerIcon={CreatorVerification} crumbs={crumbs}>
      <div className="comhubcnt">
        <div className="profilepagebody">
          <PersonalInformation />
        </div>
      </div>
    </SidebarLayout>
  );
}

export default Profile;
