import * as requestFromServer from "./Crud";
import { VerificationSlice, callTypes } from "./Slice";
import { toast } from 'react-toastify';

const { actions } = VerificationSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'creators' || type === 'search_creators') {
        dispatch(actions.clearCreatorsList());
        dispatch(actions.creatorsList(res.success));
        return;
      }
      // search_creators_by_country
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCreatorsCount = (query) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCreatorsCount(query)
    .then(response => {
      const res = response.data;
      dispatch(actions.creatorsCount(res.count));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const reset_campaign_info = () => dispatch => {
  dispatch(actions.reset());
};

export const editDetails = (body, queryParams, type = 'toggle_activation') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editDetails(body, queryParams, type)
    .then(response => {
      let res = response.data;
      if (type === 'toggle_activation') {
        toast.success(`Creator's profile ${res.success.verification_status}!`);
        dispatch(actions.DatasStatusUpdated({ id: res.success._id, verification_status: res.success.verification_status }));
        return;
      } 
      return res;
    })
    .catch(error => {
      error.clientMessage = error?.response?.data?.error || "Can't fetch stats";
      // console.log(error);
      toast.error(error.clientMessage)
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getSingleCreator = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchCreatorDetails(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.creatorDetails(res.user));
      return res?.user || res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getCreatorSocials = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchSocialsAll(id)
    .then(response => {
      console.log('LSLSLresponse: ', response);
      let res = response.data;
      dispatch(actions.creatorSocialsAll(res.success));
      return res.success;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


