import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { VerificationsPage } from 'app/modules/verifications'
import { ProductsPage } from 'app/modules/products';

export function PrivateRoutes() {

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/products' element={<ProductsPage />} />
        <Route path='/' element={<VerificationsPage />} />
      </Routes>
    </Suspense>
  )
}
