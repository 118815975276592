import { useState } from 'react'
import { message, Radio } from 'antd';
import { SidebarLayout } from 'components/layout'
import { CreatorVerification } from 'components/Icons'
import './styles.scss';
import { PendingCreators, ApprovedCreators } from './meta';


const crumbs = [
  { label: '', to: '/' },
]


export default function VerificationList() {
  const [, contextHolder] = message.useMessage();
  const [tab, setTab] = useState('applicationManagement');
  // const [status, selectedStatus] = useState('All')

  // const { creators, listLoading, creatorsCount } = useSelector(
  //   (state: any) => ({
  //     creators: state.verifications.creators,
  //     actionsLoading: state.verifications.actionsLoading,
  //     listLoading: state.verifications.listLoading,
  //     creatorsCount: state.verifications.creatorsCount,
  //   }),
  //   shallowEqual
  // );

  // useEffect(() => {
  //   dispatch(actions.fetchAllData('creators', `?type=creator`));
  //   dispatch(actions.fetchCreatorsCount());
  //   setCategory('All');
  //   setQ('');
  // }, []);

  // useEffect(() => {
  //   if (creators && creators.length > 0) {
  //     creators.map((creator: any) => {
  //       keyedCreators.push({ ...creator, key: creator._id })
  //     });
  //     setKCreator(keyedCreators);
  //   }
  // }, [creators])


  return (
    <SidebarLayout
      headerTitle="creator management"
      headerIcon={CreatorVerification}
      crumbs={crumbs}
    >
      {contextHolder}
      <div className="menu-group lg-2">
        <Radio.Group onChange={(e: any) => setTab(e.target.value)} defaultValue={tab}>
          <Radio.Button value="applicationManagement">APPLICATIONS MANAGEMENT</Radio.Button>
          <Radio.Button value="partnersCrm">PARTNER'S CRM</Radio.Button>
        </Radio.Group>
      </div>
      <section>
        {tab === 'applicationManagement' && <PendingCreators />}
        {tab === 'partnersCrm' && <ApprovedCreators />}
      </section>
    </SidebarLayout >
  )
}
